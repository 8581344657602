import { useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { useUserState } from '../providers/UserProvider'
import { TeamspaceMember } from './useListTeamspaceMembers'
import { useSafeMutation } from './useSafeMutation'
import { teamKeys } from '../utils/queryKeyFactory'

// eslint-disable-next-line no-unused-vars
export default function useRemoveMember() {
  const sb = useSupabaseClient()
  const user = useUserState()

  const queryClient = useQueryClient()

  return useSafeMutation({
    mutationFn: ({ teamspaceID, email }: { teamspaceID: string; email: string }) => {
      if (!teamspaceID || !email) {
        return Promise.resolve(undefined)
      }

      // eslint-disable-next-line no-console
      console.debug('[useRemoveMember] removing invitation or member from teamspace', email, teamspaceID)

      if (user.supabaseUserId) {
        return sb.removeTeamspaceMember(teamspaceID, email)
      }

      throw new Error('Not signed in')
    },
    onSuccess: (data, { teamspaceID, email }) => {
      // eslint-disable-next-line no-console
      console.log('[useRemoveMember] onSuccess', teamspaceID, email)
      if (data && teamspaceID && email) {
        const teamspaceMembers = queryClient.getQueryData<[TeamspaceMember]>(teamKeys.members(teamspaceID))
        queryClient.setQueryData(
          teamKeys.members(teamspaceID),
          teamspaceMembers.filter((m) => m.email !== email)
        )
      }
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('[useRemoveMember] onError ' + error)
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}
