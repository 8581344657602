import { CalendarEvent } from '../../CalendarEvent'

export default function getGroupedEvents(events: CalendarEvent[]) {
  const temporaryEvent = events.find((e) => e.id == 'temporary')
  const oldSort = [...events.filter((e) => e.id != 'temporary')]
  events.sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf())

  const result = []
  let currentGroup: CalendarEvent[] = []
  let currentGroupIDs: string[] = []

  if (temporaryEvent) {
    result.push([temporaryEvent])
  }

  for (let i = 0; i < events.length; i++) {
    const currentEvent = events[i]

    let overlapFound = false
    for (let j = 0; j < currentGroup.length; j++) {
      const previousEvent = currentGroup[j]
      if (currentEvent.startDate.isBefore(previousEvent.endDate) && currentEvent.endDate.isAfter(previousEvent.startDate)) {
        overlapFound = true
        break
      }
    }

    if (overlapFound) {
      currentGroup.push(currentEvent)
      currentGroupIDs.push(currentEvent.id)
    } else {
      result.push(oldSort.filter((e) => currentGroupIDs.includes(e.id)))
      currentGroup = [currentEvent]
      currentGroupIDs = [currentEvent.id]
    }
  }

  if (currentGroup.length > 0) {
    result.push(oldSort.filter((e) => currentGroupIDs.includes(e.id)))
  }

  return result
}
