import dayjs from 'dayjs'
import { range } from 'lodash'
import { Fragment, useMemo } from 'react'
import { getHourWidth } from '../utils/getHourWidth'

export function HourList() {
  const hours = useMemo(() => range(24), [])
  const day = useMemo(() => dayjs().startOf('day'), [])
  const width = getHourWidth()

  const elements = hours.map((hour: number) => (
    <Fragment key={`hour-view-${hour}`}>
      <div>
        <div style={{ width: `${width}px`, marginLeft: `-${width}px` }} className="sticky left-0 z-20 -mt-2.5 px-2 text-right text-xs leading-tight text-gray-400">
          {day.add(hour, 'hour').format(dayjs().localeData().longDateFormat('LT').includes('A') ? 'h A' : 'HH:mm')}
        </div>
      </div>
      <div />
      <div />
      <div />
    </Fragment>
  ))
  return <>{elements}</>
}
