import * as React from 'react'
import { SupabaseClient } from '../api/SupabaseClient'

export const supabaseDefaultContext = React.createContext<SupabaseClient | undefined>(undefined)
const supabaseClient = new SupabaseClient()

export function useSupabaseClient() {
  const supabaseClient = React.useContext(supabaseDefaultContext)

  if (!supabaseClient) {
    // Reload the page here to fix this
    window.location.reload()
    // throw new Error('No QueryClient set, use SupabaseClientProvider to set one');
  }

  return supabaseClient
}

export default function SupabaseClientProvider({ children }) {
  const Context = supabaseDefaultContext

  return <Context.Provider value={supabaseClient}>{children}</Context.Provider>
}
