import { useMemo, useRef, useReducer, useEffect } from 'react'
import classnames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { useSwipeable } from 'react-swipeable' // Import react-swipeable library
import ShowButton from './ShowButton.tsx'
import { WeekDays, eachDayOfMonth, eachWeekOfMonth } from './CalendarWrapper.tsx'
import { SelectedDate, isSameDay, isSameWeek, selectedDateToDay, selectedDateToWeek, weekToDate } from '../../providers/SelectedDateProvider.tsx'

type DateRange = { type: 'week'; week: number; year: number } | { type: 'month'; month: number; year: number }
type DateRangeAction = { type: 'toggle'; selectedDate: SelectedDate } | { type: 'shift'; direction: number } | { type: 'today' } | { type: 'set'; date: Dayjs }

function monthToDate(month: number, year: number): Dayjs {
  return dayjs().year(year).month(month)
}

function dateRangeToMonth(dateRange: DateRange): number {
  if (dateRange.type === 'week') {
    return weekToDate(dateRange).month()
  }
  return dateRange.month
}

function dateRangeToDate(dateRange: DateRange): Dayjs {
  if (dateRange.type === 'week') {
    return weekToDate(dateRange)
  }
  return dayjs().year(dateRange.year).month(dateRange.month)
}

function isSameMonth(dateRange: DateRange, date: Dayjs): boolean {
  if (dateRange.type === 'week') {
    const weekDate = weekToDate(dateRange)
    return dateRange.year === date.year() && weekDate.month() === date.month()
  }
  return dateRange.year === date.year() && dateRange.month === date.month()
}

function dateRangeReducer(state: DateRange, action: DateRangeAction): DateRange {
  switch (action.type) {
    case 'toggle': {
      if (state.type === 'week') {
        const date = weekToDate(state)
        return { type: 'month', month: date.month(), year: state.year }
      }
      // set week to the week of the selected date or the start of the month
      const date = isSameMonth(state, selectedDateToDay(action.selectedDate)) ? selectedDateToDay(action.selectedDate) : dateRangeToDate(state).startOf('month')
      return { type: 'week', week: date.week(), year: state.year }
    }
    case 'shift': {
      if (state.type === 'week') {
        const newDate = weekToDate(state).add(action.direction, 'week')
        return { ...state, week: newDate.week(), year: newDate.year() }
      }
      const newDate = monthToDate(state.month, state.year).add(action.direction, 'month')
      return { ...state, month: newDate.month(), year: newDate.year() }
    }
    case 'today': {
      if (state.type === 'week') {
        return { type: 'week', week: dayjs().week(), year: dayjs().year() }
      }
      return { type: 'month', month: dayjs().month(), year: dayjs().year() }
    }
    case 'set': {
      if (state.type === 'week') {
        return { type: 'week', week: action.date.week(), year: action.date.year() }
      }
      return { type: 'month', month: action.date.month(), year: action.date.year() }
    }
  }
}

export type CalendarMobileProps = {
  selectedDate: SelectedDate
  onToday: () => void
  onChangeWeek: (_week: { week: number; year: number }) => void
  onChangeDay: (_day: Dayjs) => void
}

export default function CalendarMobile({ selectedDate, onToday, onChangeWeek, onChangeDay }: CalendarMobileProps) {
  const [currentDateRange, currentDateRangeDispatch] = useReducer(dateRangeReducer, { type: 'week', ...selectedDateToWeek(selectedDate) })
  const days = useMemo(() => {
    const days = eachDayOfMonth(dateRangeToMonth(currentDateRange), currentDateRange.year)
    if (currentDateRange.type === 'week') {
      return days.filter((day) => currentDateRange.week === day.week() && currentDateRange.year === day.year())
    }
    return days
  }, [currentDateRange])

  const weeks = useMemo(() => {
    const weeks = eachWeekOfMonth(dateRangeToMonth(currentDateRange), currentDateRange.year)
    if (currentDateRange.type === 'week') {
      return weeks.filter((week) => currentDateRange.week === week.week && currentDateRange.year === week.year)
    }
    return weeks
  }, [currentDateRange])

  // set currentDateRange to the selectedDate when it changes
  useEffect(() => {
    currentDateRangeDispatch({ type: 'set', date: selectedDateToDay(selectedDate) })
  }, [selectedDate])

  function handleToday() {
    currentDateRangeDispatch({ type: 'today' })
    onToday()
  }

  const blockRef = useRef(null)
  const isDragging = useRef(false)

  function handleMouseDown() {
    if (blockRef.current) {
      isDragging.current = true
    }
  }

  function handleMouseUp() {
    blockRef.current.style.marginLeft = '0px'
    blockRef.current.style.marginRight = '0px'
    isDragging.current = false
  }

  function onSwipedLeft() {
    currentDateRangeDispatch({ type: 'shift', direction: 1 })
  }
  function onSwipedRight() {
    currentDateRangeDispatch({ type: 'shift', direction: -1 })
  }
  // Define swipe handlers
  const handlers = useSwipeable({
    onTouchStartOrOnMouseDown: handleMouseDown,
    onTouchEndOrOnMouseUp: handleMouseUp,
    onSwiping: (e) => {
      if (isDragging.current) {
        if (e.dir === 'Right') {
          blockRef.current.style.marginLeft = '30px'
          blockRef.current.style.marginRight = '-30px'
        }
        if (e.dir === 'Left') {
          blockRef.current.style.marginRight = '30px'
          blockRef.current.style.marginLeft = '-30px'
        }
      }
    },
    onSwipedLeft,
    onSwipedRight,
    trackMouse: true,
  })

  return (
    <div id="calendar-view" className="text-center select-none">
      <div className="flex h-12 justify-between items-center text-gray-900 pl-2 space-x-2 pt-2.5">
        <div className="flex flex-auto justify-center">
          <button type="button" className="flex flex-none items-center justify-center p-1 hover:bg-gray-500/25" onClick={() => onSwipedRight()}>
            <span className="sr-only">Previous month</span>
            <i className="h-5 w-5 fa-solid fa-arrow-left text-sm" aria-hidden="true" />
          </button>
          <button
            type="button"
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Open today (⌃T)"
            className="flex flex-none mb- text-left text-lg text-black dark:text-white hover:bg-gray-500/25"
            onClick={handleToday}
          >
            {dateRangeToDate(currentDateRange).format('MMMM YYYY')}
          </button>
          <button type="button" className="flex flex-none items-center justify-center p-1 hover:bg-gray-500/25" onClick={() => onSwipedLeft()}>
            <span className="sr-only">Next month</span>
            <i className="h-5 w-5 fa-solid fa-arrow-right text-sm" aria-hidden="true" />
          </button>
        </div>

        <ShowButton onClick={() => currentDateRangeDispatch({ type: 'toggle', selectedDate: selectedDate })} showOne={currentDateRange.type === 'week'} />
      </div>
      <div className="flex mt-2 ml-2 transition-all border-b-2" ref={blockRef}>
        <div className="calendar-weeks flex flex-col">
          <div className="text-xs text-orange-500 mb-1 ">CW</div>

          <div className="flex flex-col">
            {weeks.map((week) => (
              <button
                key={`calendar-week-number-${week.week}`}
                type="button"
                onClick={() => onChangeWeek(week)}
                className={classnames(
                  ' h-16  border-t-2 flex border-gray-200 dark:border-zinc-700 leading-tight text-orange-500 dark:text-orange-200 text-xs bg-transparent dark:bg-zinc-800 focus:z-10 rounded-none m-0 p-[0.585em]',
                  {
                    'font-semibold text-gray-600 !bg-blue-300/60': selectedDate.active === 'week' && isSameWeek(selectedDate, week),
                  }
                )}
              >
                {week.week}
              </button>
            ))}
          </div>
        </div>

        <div className="calendar-days flex-auto" {...handlers}>
          <WeekDays />

          <div className="isolate mt-1 grid grid-cols-7 bg-transparent text-sm w-full">
            {days.map((day, dayIdx) => (
              <button
                id={`${dayIdx}: ${isSameDay(selectedDate, day)}`}
                key={`calendar-day-view-${day}-${dayIdx}`}
                type="button"
                onClick={() => onChangeDay(day)}
                className={classnames(
                  'justify-center h-16 flex leading-tight border-t-2  border-gray-200  bg-transparent  dark:border-zinc-800  focus:z-10 rounded-none m-0 p-[0.375rem]',
                  {
                    'font-semibold': selectedDate.active === 'day' && (isSameDay(selectedDate, day) || day.isSame(dayjs(), 'day')),
                    'text-gray-900 dark:text-gray-50': !isSameDay(selectedDate, day) && isSameMonth(currentDateRange, day) && !day.isSame(dayjs(), 'day'),
                    'text-gray-400': !isSameDay(selectedDate, day) && !isSameMonth(currentDateRange, day) && !day.isSame(dayjs(), 'day'),
                    'text-blue-400': day.isSame(dayjs(), 'day') && !isSameDay(selectedDate, day),
                    '!bg-blue-300/60 :border-blue-500': selectedDate.active === 'day' && isSameDay(selectedDate, day),
                  },
                  dayIdx === 0 && day.weekday() > 0 && `col-start-${day.weekday()}`
                )}
              >
                <time dateTime={day.format('yyyy-MM-DD')} className={classnames('h-7 w-7 text-center')}>
                  {day.date()}
                </time>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
