import { CalendarEvent } from '../../CalendarEvent'
import { useGoogleCalendarEvents } from '../../../../hooks/useGoogleCalendarEvents'

export default function AllDayEvents({ selectedDay, labelWidth }) {
  const { data: googleEvents } = useGoogleCalendarEvents(selectedDay)
  const allDayEvents: CalendarEvent[] = googleEvents ? googleEvents?.filter(({ allDay }) => allDay) : []
  return (
    <div className="flex border-b-2">
      <div className="flex text-xs leading-tight text-gray-400 flex-shrink-0" style={{ width: `${labelWidth}px` }}>
        <div className="self-end w-full text-right pb-0.5 pr-2">all-day</div>
      </div>
      <div className="pt-0.5 grid grid-cols-1 grid-rows-1 flex-auto">
        {allDayEvents.map((event) => {
          return (
            <div key={event.id} className="truncate event-content-background-blue rounded-md text-xs font-semibold px-2 text-blue-900 dark:text-blue-400 mb-0.5">
              {event.content}
            </div>
          )
        })}
      </div>
    </div>
  )
}
