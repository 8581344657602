import { SubscriptionManager } from './supabase/SubscriptionsManager'
/* eslint-disable no-console */
import { AuthChangeEvent, RealtimePostgresChangesPayload, Session, createClient, SupabaseClient as BaseSupabaseClient } from '@supabase/supabase-js'
import { Attachment, Note, NoteType } from '../utils/syncUtils'
import { QueryClient } from '@tanstack/react-query'
import { updateNote } from '../providers/CachedNotesProvider'
import { UserMetaData } from '../providers/UserProvider'
import { InvitationManager } from './supabase/InvitationManager'
import { TeamspaceMember } from '../hooks/useListTeamspaceMembers'
import { FetchManager } from './supabase/FetchManager'
import { UserManager } from './supabase/UserManager'
import { NoteOperations } from './supabase/NoteOperations'
import { AuthManager } from './supabase/AuthManager'
import { NoteUtils } from './supabase/NoteUtils'
import { cacheKeys, noteQueryKey, privateKeys, teamKeys } from '../utils/queryKeyFactory'

const SUPABASE_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxvZnZvbWFnaW1wdGhnZ21vcmZwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODU4OTg2NzUsImV4cCI6MjAwMTQ3NDY3NX0.KnOabJDqi8hyPqerbIoKBvGGu0V8ZHpMuz6aC8BFzas'
const SUPABASE_URL = 'https://lofvomagimpthggmorfp.supabase.co'

export type SupabaseNote = {
  id: string
  user_id: string
  filename: string
  is_dir: boolean
  parent: string
  note_type?: NoteType
  content?: string
  content_encrypted?: string
  decrypted_content_encrypted?: string
  decrypted_title_encrypted?: string
  title?: string
  attachments?: Attachment[]
  change_tag?: string
  modified_at?: string
  title_encrypted?: string
  has_shared_with_admin_role?: string[]
}

export class SupabaseClient {
  private supabase: BaseSupabaseClient
  private skipNotificationRecordNames: string[] = []
  private invitationManager: InvitationManager
  private fetchManager: FetchManager
  private userManager: UserManager
  private noteOperations: NoteOperations
  private authManager: AuthManager
  private noteUtils: NoteUtils
  private subscriptionManager: SubscriptionManager

  constructor() {
    this.supabase = createClient(SUPABASE_URL, SUPABASE_KEY)
    this.invitationManager = new InvitationManager(this.supabase)
    this.fetchManager = new FetchManager(this.supabase)
    this.userManager = new UserManager(this.supabase)
    this.noteOperations = new NoteOperations(this.supabase, this.addSkipNotificationRecordName.bind(this), this.removeNotificationRecordName.bind(this))
    this.authManager = new AuthManager(this.supabase)
    this.noteUtils = new NoteUtils(this.supabase)
    this.subscriptionManager = new SubscriptionManager(this.supabase)
  }

  // MARK: - auth functions

  public setUpAuth(onEvent: (_event: AuthChangeEvent, _session: Session) => void | Promise<void>) {
    this.supabase.auth.onAuthStateChange(onEvent)
  }

  public async verifyOTP(email: string, token: string): Promise<boolean> {
    return this.authManager.verifyOTP(email, token)
  }

  public async signInWithEmailOTP(email: string): Promise<boolean> {
    return this.authManager.signInWithEmailOTP(email)
  }

  // Use this to set the password after a magic link / token login
  public async updatePassword(password: string): Promise<boolean> {
    return this.authManager.updatePassword(password)
  }

  public async signOut() {
    return this.supabase.auth.signOut()
  }

  private isSupabaseNote(data: object | Partial<SupabaseNote>): data is SupabaseNote {
    return (data as Partial<SupabaseNote>).id !== undefined
  }

  public async registerForNotifications(currentUserId: string, queryClient: QueryClient, cachedNotesQueryClient: QueryClient) {
    const handleChange = async (payload: RealtimePostgresChangesPayload<SupabaseNote>) => {
      // Skip notifications if we just saved the note for example when updating the titles
      if (
        (this.isSupabaseNote(payload.old) && this.skipNotificationRecordNames.includes(payload.old.id)) ||
        (this.isSupabaseNote(payload.new) && this.skipNotificationRecordNames.includes(payload.new.id))
      ) {
        // Remove this recordName from the skip list, so we don't ignore it again
        this.skipNotificationRecordNames = this.skipNotificationRecordNames
          .filter((recordName) => this.isSupabaseNote(payload.old) && recordName !== payload.old.id)
          .filter((recordName) => this.isSupabaseNote(payload.new) && recordName !== payload.new.id)
        return
      }

      // eslint-disable-next-line no-console
      console.log('note changed', payload)
      // update notes
      const updatedNote = (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE') && (await this.fetchNoteById(currentUserId, payload.new.id))
      switch (payload.eventType) {
        case 'INSERT':
          if (updatedNote) {
            updateNote(cachedNotesQueryClient, currentUserId, currentUserId, updatedNote)
            queryClient.setQueryData(noteQueryKey(updatedNote), () => updatedNote)
          }
          break
        case 'UPDATE':
          if (updatedNote) {
            updateNote(cachedNotesQueryClient, currentUserId, currentUserId, await this.fetchNoteById(currentUserId, payload.new.id))
            queryClient.setQueryData(noteQueryKey(updatedNote), () => updatedNote)
          }
          break
        case 'DELETE': {
          // Because of delete cascade, we don't need to remove the children
          // Try to remove the note from the team notes first to prevent removing the wrong note when moving a note from team to private
          const teamNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.team(currentUserId))
          if (teamNotes.has(payload.old.id)) {
            cachedNotesQueryClient.setQueriesData<Map<string, Note>>(cacheKeys.team(currentUserId), (oldData: Map<string, Note>) => {
              oldData.delete(payload.old.id)
              return oldData
            })
            queryClient.getQueriesData(teamKeys.all).forEach(([queryKey, note]) => {
              if (payload.old.id === note?.['recordName']) {
                queryClient.removeQueries(queryKey)
                return false
              }
            })
          } else {
            cachedNotesQueryClient.setQueriesData<Map<string, Note>>(cacheKeys.private(currentUserId), (oldData: Map<string, Note>) => {
              oldData.delete(payload.old.id)
              return oldData
            })
            queryClient.getQueriesData<Note>(privateKeys.notes).forEach(([queryKey, note]) => {
              if (payload.old.id === note?.recordName) {
                queryClient.removeQueries(queryKey)
                return false
              }
            })
          }
          break
        }
      }
    }

    if (!this.supabase.realtime.isConnected() || this.supabase.realtime.channels.length === 0 || this.supabase.realtime.channels[0].state !== 'joined') {
      this.supabase.channel('any').on<SupabaseNote>('postgres_changes', { event: '*', schema: 'public', table: 'notes' }, handleChange).subscribe()
    } else {
      console.log("Didn't connect to realtime updates because we are already connected", this.supabase.realtime.channels)
    }
  }

  public async setUserMetaData(meta: UserMetaData) {
    return this.userManager.setUserMetaData(meta)
  }

  public async getUserMetaData(): Promise<UserMetaData> {
    return this.userManager.getUserMetaData()
  }

  public async session(): Promise<Session | null> {
    const { data, error } = await this.supabase.auth.getSession()

    if (error) {
      throw error
    }

    return data?.session ?? null
  }

  // So we don't fetch a locally changed note again through the notificaiton
  public addSkipNotificationRecordName(recordName: string): void {
    this.skipNotificationRecordNames.push(recordName)
  }

  // I ncase there was an error, we need to remove the recordName from the skip list
  public removeNotificationRecordName(recordName: string): void {
    this.skipNotificationRecordNames = this.skipNotificationRecordNames.filter((name) => name !== recordName)
  }

  // MARK: - Note Operations

  // Uploads a new note or updates an existing one, depending on if we have the ID or not
  public async createNote(currentUserId: string, draft: Note) {
    return this.noteOperations.createNote(currentUserId, draft)
  }

  public async saveNote(currentUserId: string, note: Note | undefined, content: string, attachments: string[], modifiedAt: Date = new Date()) {
    return this.noteOperations.saveNote(currentUserId, note, content, attachments, modifiedAt)
  }

  public async saveNoteTitle(currentUserId: string, id: string, title: string) {
    return this.noteOperations.saveNoteTitle(currentUserId, id, title)
  }

  public async moveNote(currentUserId: string, id: string, parentId: string | null, noteType: NoteType, parentNoteType: NoteType | null) {
    return this.noteOperations.moveNote(currentUserId, id, parentId, noteType, parentNoteType)
  }

  // Delete a note by id
  public async deleteNote(id: string) {
    return this.noteOperations.deleteNote(id)
  }

  // MARK: - fetching functions

  public async fetchNoteById(currentUserId: string, id: string /*, fetchEncryptedContent = false*/) {
    return this.fetchManager.fetchNoteById(currentUserId, id)
  }

  public async fetchNoteByFilename(
    currentUserId: string,
    filename: string,
    noteType: NoteType = NoteType.CALENDAR_NOTE,
    parent: string | null = null
    /*fetchEncryptedContent = false*/
  ) {
    return this.fetchManager.fetchNoteByFilename(currentUserId, filename, noteType, parent)
  }

  public async fetchPrivateNotes(currentUserId: string): Promise<Map<string, Note>> {
    return this.fetchManager.fetchPrivateNotes(currentUserId)
  }

  public async hasPrivateNotes(currentUserId: string): Promise<boolean> {
    return this.fetchManager.hasPrivateNotes(currentUserId)
  }

  public async listMyTeamspaceNotes(): Promise<string[]> {
    return this.fetchManager.listMyTeamspaceNotes()
  }

  // Team spaces are folders with the type = 10, load them into another part of the sidebar. They might be shared with me or I might be the owner
  public async fetchTeamNotes(currentUserId: string): Promise<Map<string, Note>> {
    return this.fetchManager.fetchTeamNotes(currentUserId)
  }

  // MARK: - Teamspace Member functions

  public async fetchMembers(teamspaceID: string): Promise<TeamspaceMember[]> {
    return this.invitationManager.fetchMembers(teamspaceID)
  }

  public async inviteTeamspaceMember(teamspaceID: string, email: string): Promise<boolean> {
    return this.invitationManager.inviteTeamspaceMember(teamspaceID, email)
  }

  public async leaveTeamspace(teamspaceID: string): Promise<boolean> {
    return this.invitationManager.leaveTeamspace(teamspaceID)
  }

  public async removeTeamspaceMember(teamspaceID: string, email: string): Promise<boolean> {
    return this.invitationManager.removeTeamspaceMember(teamspaceID, email)
  }

  public async updateTeamspaceUserRole(teamspaceID: string, email: string, role: string): Promise<boolean> {
    return this.invitationManager.updateTeamspaceUserRole(teamspaceID, email, role)
  }

  public async acceptInvitation(teamspaceID: string): Promise<boolean> {
    return this.invitationManager.acceptInvitation(teamspaceID)
  }

  public async getAttachmentURL(noteID: string, path: string): Promise<string | null> {
    return this.noteUtils.getAttachmentURL(noteID, path)
  }

  public async updateAttachmentURLs(note: Note): Promise<boolean> {
    return this.noteUtils.updateAttachmentURLs(note)
  }

  public async getSubscription(user_id: string): Promise<boolean | null> {
    return this.subscriptionManager.fetchSubscription(user_id)
  }
}
