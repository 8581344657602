import { QueryClient } from '@tanstack/react-query'
import { useCloudKitClient } from './CloudKitClientProvider'
import { useSupabaseClient } from './SupabaseClientProvider'
import { PersistQueryClientProvider, PersistedClient, Persister } from '@tanstack/react-query-persist-client'
import { get, set, del } from 'idb-keyval'
import { useContext, createContext, useCallback } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { cacheKeys } from '../utils/queryKeyFactory'
import { User } from './UserProvider'
import { Note, filenameToKey, isCalendarNote, isTeamspaceNote } from '../utils/syncUtils'
import { useSafeQuery } from '../hooks/useSafeQuery'
import { getSupabaseFileExtension } from '../utils/syncUtils'
import { mapSet } from '../utils/mapAsState'

// #region data
export const cachedNotesContext = createContext<QueryClient | undefined>(undefined)

export function useCachedNotesQueryClient() {
  const queryClient = useContext(cachedNotesContext)
  if (queryClient === undefined) {
    // throw new Error('useCachedNotesQueryClient must be used within a NotesProvider');
  }
  return queryClient
}

// Because of notification "disconnection" issues, set the cache time to 24h
const cacheTime = 1000 * 60 * 60 * 24 // 24h
const cachedNotesQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: 'always', // TODO do we need set this depending on the data?
      cacheTime: cacheTime,
    },
  },
})

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 * @see https://tanstack.com/query/v4/docs/react/plugins/persistQueryClient#building-a-persister
 */
function createIDBPersister(idbValidKey: IDBValidKey = 'notes') {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client)
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey)
    },
    removeClient: async () => {
      await del(idbValidKey)
    },
  } as Persister
}

const idbPersister = createIDBPersister()

// #endregion

// #region notes

export function usePrivateNotes(user: User, byTitle = false) {
  const ck = useCloudKitClient()
  const sb = useSupabaseClient()
  const userId = user?.cloudKitUserId || user?.supabaseUserId

  return useSafeQuery<Map<string, Note> | Map<string, Note[]>, Error>({
    enabled: !!userId,
    context: cachedNotesContext,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: cacheKeys.private(userId),
    queryFn: async () => {
      // eslint-disable-next-line no-console
      console.log('usePrivateNotes user', user)

      // Add private notes from CloudKit
      if (user && user.cloudKitUserId) {
        return ck.fetchPrivateNotes()
      }

      if (user && user.supabaseUserId) {
        return sb.fetchPrivateNotes(user.supabaseUserId)
      }

      throw new Error('No client signed in')
    },
    select: useCallback(
      (data: Map<string, Note>) => {
        if (byTitle) {
          const notes = new Map<string, Note[]>()
          data.forEach((note: Note) => {
            const title = isCalendarNote(note.noteType) ? filenameToKey(note.filename) : note.title
            if (!title) return

            // exclude notes that starts with an @
            if (!title.startsWith('@')) {
              if (notes.has(title)) {
                notes.get(title).push(note)
              } else {
                notes.set(title, [note])
              }
            }
          })
          return notes
        }
        return data
      },
      [byTitle]
    ),
  })
}

export function useTeamNotes(user: User, byTitle = false) {
  const sb = useSupabaseClient()

  return useSafeQuery<Map<string, Note> | Map<string, Note[]>, Error>({
    enabled: !!user && !!user?.supabaseUserId,
    context: cachedNotesContext,
    queryKey: cacheKeys.team(user?.supabaseUserId),
    queryFn: async () => {
      // NOTE: We load the content from team spaces always from Supabase, even CloudKit users have to login for team spaces separately
      // eslint-disable-next-line no-console
      console.log('useTeamNotes user', user?.supabaseUserId)
      return sb.fetchTeamNotes(user?.supabaseUserId)
    },
    select: useCallback(
      (data: Map<string, Note>) => {
        if (byTitle) {
          const notes = new Map<string, Note[]>()
          data.forEach((note: Note) => {
            const title = isCalendarNote(note.noteType) ? filenameToKey(note.filename) : note.title
            if (notes.has(title)) {
              notes.get(title).push(note)
            } else {
              notes.set(title, [note])
            }
          })
          return notes
        }
        return data
      },
      [byTitle]
    ),
  })
}

export function updateNote(cachedNotesQueryClient: QueryClient, privateUserId: string, teamUserId: string, updatedNote: Note): void {
  if (privateUserId === undefined || updatedNote === undefined) return

  if (isTeamspaceNote(updatedNote.noteType)) {
    // cancel any outgoing refetches (so they don't overwrite our optimistic update)
    cachedNotesQueryClient.cancelQueries(cacheKeys.team(teamUserId))
    // optimistically update to the new value
    cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), (oldData: Map<string, Note>) => {
      return mapSet(oldData, updatedNote.recordName, updatedNote)
    })
  } else {
    // cancel any outgoing refetches (so they don't overwrite our optimistic update)
    cachedNotesQueryClient.cancelQueries(cacheKeys.private(privateUserId))
    // optimistically update to the new value
    cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
      return mapSet(oldData, updatedNote.recordName, updatedNote)
    })
  }
}

export function updateNoteReturnFallback(cachedNotesQueryClient: QueryClient, privateUserId: string, teamUserId: string, updatedNote: Note): Map<string, Note> {
  if (privateUserId === undefined || teamUserId === undefined || updatedNote === undefined) return

  let previousNotes: Map<string, Note> = new Map<string, Note>()

  if (isTeamspaceNote(updatedNote.noteType)) {
    // cancel any outgoing refetches (so they don't overwrite our optimistic update)
    cachedNotesQueryClient.cancelQueries(cacheKeys.team(teamUserId))
    // snapshot the previous value
    previousNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.team(teamUserId))
    // optimistically update to the new value
    cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), (oldData: Map<string, Note>) => {
      return mapSet(oldData, updatedNote.recordName, updatedNote)
    })
  } else {
    // cancel any outgoing refetches (so they don't overwrite our optimistic update)
    cachedNotesQueryClient.cancelQueries(cacheKeys.private(privateUserId))
    // snapshot the previous value
    previousNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.private(privateUserId))
    // optimistically update to the new value
    cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
      return mapSet(oldData, updatedNote.recordName, updatedNote)
    })
  }
  return previousNotes
}

// #endregion

export function useNotesExtension(user: User) {
  const ck = useCloudKitClient()

  return useSafeQuery<string, Error>({
    enabled: !!user,
    context: cachedNotesContext,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: cacheKeys.extension,
    queryFn: async () => {
      // eslint-disable-next-line no-console
      console.log('[useFileExtension] user', user)
      if (user.cloudKitUserId) {
        return ck.fetchFileExtension()
      } else if (user.supabaseUserId) {
        return getSupabaseFileExtension()
      } else {
        throw new Error('No client signed in')
      }
    },
  })
}

export default function CachedNotesProvider({ children }) {
  return (
    <PersistQueryClientProvider context={cachedNotesContext} client={cachedNotesQueryClient} persistOptions={{ persister: idbPersister, maxAge: cacheTime }}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} context={cachedNotesContext} position="bottom-right" />
    </PersistQueryClientProvider>
  )
}
