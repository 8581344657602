import EventContent from './EventContent'
import calculateOffsetAndHeight from '../utils/calculateOffsetAndHeight'

function SingleEvent({ event, segmentHeight, setCalendarEventDrag }) {
  const { eventHeight, offsetPxTop } = calculateOffsetAndHeight(event, segmentHeight)

  return (
    <li
      key={`event-${event.id}`}
      id={`event-${event.id}`}
      className="mt-px flex col-start-1"
      style={{ gridRow: event.position, transform: `translateY(${offsetPxTop}px)`, height: eventHeight - 2 }}
    >
      <EventContent setCalendarEventDrag={setCalendarEventDrag} event={event} eventHeight={eventHeight} segmentHeight={segmentHeight} className="w-full h-full" />
    </li>
  )
}

export default SingleEvent
