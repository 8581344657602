import { createContext, useContext, useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { useSelectedDate, useSelectedDateDispatch } from './SelectedDateProvider'

export const SelectedRecordNameContext = createContext<string | null>(null)
export const SelectedRecordNameDispatchContext = createContext<React.Dispatch<string>>(null)

export function useSelectedRecordName() {
  const context = useContext(SelectedRecordNameContext)
  if (context === undefined) {
    throw new Error('useSelectedRecordName must be used within a SelectedRecordNameProvider')
  }
  return context
}

export function useSelectedRecordNameDispatch() {
  const context = useContext(SelectedRecordNameDispatchContext)
  if (context === undefined) {
    throw new Error('useSelectedRecordNameDispatch must be used within a SelectedRecordNameProvider')
  }
  return context
}

export default function SelectedRecordNameProvider({ children }: { children: React.ReactNode }) {
  const [selectedRecordName, setSelectedRecordName] = useLocalStorage('selectedRecordName', 'daily')
  const selectedDate = useSelectedDate()
  const selectedDateDispatch = useSelectedDateDispatch()

  useEffect(() => {
    // On mount set the selectedDate if it is null and selectedRecordName is a date
    if (selectedDate === null && selectedRecordName === 'daily') {
      selectedDateDispatch({ type: 'today' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Set the selectedRecordName when changing selectedDate, so that it opens the right calendar note
  // This gets triggered when you switch to any date or week in the calendar or in the sidebar
  useEffect(() => {
    // If it's a teamspace calendar note, we need to append the parent id (the id of the teamspace record) in the form of 'daily_<parent-id>' or 'weekly_<parent-id>'. This is how we know from WHICH teamspace to load the calendar note.
    function getNewRecordName(recordName: string, timeframe: string) {
      const parts = recordName.split('_')
      if (parts.length > 1) {
        return timeframe + '_' + parts[1]
      }
      return timeframe
    }

    const recordName = selectedRecordName ?? 'daily'

    if (selectedDate?.active === 'week' && !recordName.startsWith('weekly')) {
      setSelectedRecordName(getNewRecordName(recordName, 'weekly'))
    } else if (selectedDate?.active === 'day' && !recordName.startsWith('daily')) {
      setSelectedRecordName(getNewRecordName(recordName, 'daily'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  // keyboard shortcuts
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.metaKey && event.shiftKey) {
        if (event.key === 'f') {
          setSelectedRecordName('search')
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [setSelectedRecordName])

  return (
    <SelectedRecordNameContext.Provider value={selectedRecordName}>
      <SelectedRecordNameDispatchContext.Provider value={setSelectedRecordName}>{children}</SelectedRecordNameDispatchContext.Provider>
    </SelectedRecordNameContext.Provider>
  )
}
