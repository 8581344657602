import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useUserState } from '../providers/UserProvider'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { teamKeys } from '../utils/queryKeyFactory'

export type TeamspaceMember = {
  email: string
  role: string
}

export default function useListTeamspaceMembers(teamspaceID: string | null): UseQueryResult<TeamspaceMember[] | undefined | null, Error> {
  const sb = useSupabaseClient()
  const user = useUserState()

  // eslint-disable-next-line @tanstack/query/exhaustive-deps
  const queryResult = useQuery<TeamspaceMember[] | undefined | null, Error>({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: teamKeys.members(teamspaceID),
    queryFn: async () => {
      if (teamspaceID && user.supabaseUserId) {
        return sb.fetchMembers(teamspaceID)
      } else {
        return []
      }
    },
  })

  //   const { isError, error } = queryResult;

  return queryResult
}
