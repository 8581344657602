import { Dispatch, SetStateAction } from 'react'
import { CalendarEvent } from '../../CalendarEvent'

export function cleanUpDraggedEvent(setCalendarEventDrag: Dispatch<SetStateAction<CalendarEvent | null>>) {
  setCalendarEventDrag(null)

  const elementToDelete = document.getElementById('dragged-event-image')
  if (elementToDelete) {
    elementToDelete.parentNode?.removeChild(elementToDelete)
  }
}
