import CalendarMobile from './CalendarMobile.tsx'
import Calendar from './Calendar.tsx'
import Timeline from './timeline/Timeline.tsx'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GapiProvider } from '../../providers/GapiProvider.tsx'
import AccessTokenProvider from '../../providers/AccessTokenProvider.tsx'
import { useSelectedDate, useSelectedDateDispatch } from '../../providers/SelectedDateProvider.tsx'
import dayjs, { Dayjs } from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { getWeekdayNames } from '../../utils/locale.tsx'
import { useIsMobile } from '../../hooks/useIsMobile.ts'

const gapiConfig = {
  apiKey: 'AIzaSyDYVUzYigHtFemPCKp0hUUjylyagVFacuQ',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
}

dayjs.extend(isSameOrBefore)
export function eachDayOfMonth(month: number, year: number): Dayjs[] {
  const currentDate = dayjs().year(year).month(month)
  let startDate = currentDate.startOf('month').startOf('week')
  const endDate = currentDate.endOf('month').endOf('week')

  const days = []

  while (startDate.isSameOrBefore(endDate)) {
    days.push(startDate.clone())
    startDate = startDate.add(1, 'day')
  }

  return days
}
export function eachWeekOfMonth(month: number, year: number): { week: number; year: number }[] {
  const currentDate = dayjs().year(year).month(month)
  let startDate = currentDate.startOf('month').startOf('week')
  const endDate = currentDate.endOf('month').endOf('week')

  const weeks = []

  while (startDate.isSameOrBefore(endDate)) {
    weeks.push({ week: startDate.week(), year: startDate.year() })
    startDate = startDate.add(7, 'day')
  }

  return weeks
}

export function WeekDays() {
  return (
    <div className="grid grid-cols-7 text-xs font-medium text-gray-400">
      {getWeekdayNames().map((day) => (
        <div key={`weekday-${day}`}>{day.toUpperCase()}</div>
      ))}
    </div>
  )
}

export default function CalendarWrapper({ showCalendar, toggleCalendar }) {
  const isMobile = useIsMobile()
  const selectedDate = useSelectedDate()
  const selectedDateDispatch = useSelectedDateDispatch()

  // const [timelineDays, setTimelineDays] = useLocalStorage('timelineDays', 1);
  const timelineDays = 1

  function setToday() {
    selectedDateDispatch({ type: 'today', forceDay: true })
  }

  function setWeek({ week, year }: { week: number; year: number }) {
    selectedDateDispatch({ type: 'setWeek', week: week, year: year })
  }

  function setDay(date: Dayjs) {
    selectedDateDispatch({ type: 'setDay', date: date })
  }

  return (
    <div className="calendar-body">
      <AccessTokenProvider>
        <GoogleOAuthProvider clientId="1033205895259-1j3r0otft9i70kj013goupb8h6gpq2og.apps.googleusercontent.com">
          <GapiProvider gapiConfig={gapiConfig}>
            {isMobile ? (
              showCalendar && <CalendarMobile selectedDate={selectedDate} onToday={setToday} onChangeWeek={setWeek} onChangeDay={setDay} />
            ) : (
              <div className="flex justify-center items-center sticky top-0 h-screen max-h-screen select-none">
                <button onClick={toggleCalendar} className="-ml-6 py-4 bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 opacity-25 hover:opacity-100 transition">
                  <i className={`fa-solid fa-chevron-${showCalendar ? 'right' : 'left'}`} />
                </button>
                {showCalendar && (
                  <div className="h-screen border-l-2">
                    <div className="flex-col items-center space-y-2 hidden md:flex">
                      <Calendar
                        selectedDate={selectedDate}
                        onToday={setToday}
                        onChangeWeek={setWeek}
                        onChangeDay={setDay}
                        timelineDays={timelineDays}
                        // onSetTimelineDays={setTimelineDays}
                      />
                      {/* When we have a week view that is a list instead of a timeline, uncomment and implement this, for now it just shifts things up and down */}
                      {/* {selectedDate.active === 'day' && ( */}
                      <Timeline timelineDays={timelineDays} />
                      {/* )} */}
                    </div>
                  </div>
                )}
              </div>
            )}
          </GapiProvider>
        </GoogleOAuthProvider>
      </AccessTokenProvider>
    </div>
  )
}
