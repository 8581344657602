import EventContent from './EventContent'
import calculateOffsetAndHeight from '../utils/calculateOffsetAndHeight'

function MultipleEvents({ events, segmentHeight, setCalendarEventDrag }) {
  const lastEvent = events[events.length - 1]
  const minute = lastEvent.startDate.startOf('minute').toISOString()
  const refDate = lastEvent.startDate.subtract(lastEvent.startDate.minute() % 15, 'minute')
  return (
    <li id={`event-group-${minute}`} className="mt-px flex col-start-1 space-x-0.5" style={{ gridRow: lastEvent.position }}>
      {events.map((event) => {
        const offsetPxTop = (event.startDate.diff(refDate, 'minute') * segmentHeight) / 15
        const { eventHeight } = calculateOffsetAndHeight(event, segmentHeight)
        return (
          <EventContent
            setCalendarEventDrag={setCalendarEventDrag}
            event={event}
            offsetPxTop={offsetPxTop}
            eventHeight={eventHeight}
            segmentHeight={segmentHeight}
            className="basis-1/4 flex-grow group inset-1"
            style={{ transform: `translateY(${offsetPxTop}px)` }}
            key={`event-${event.id}`}
          />
        )
      })}
    </li>
  )
}

export default MultipleEvents
