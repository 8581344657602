import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { useUserState } from '../providers/UserProvider'
import { Note, NoteType, isTeamspaceNote } from '../utils/syncUtils'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { useCachedNotesQueryClient, updateNoteReturnFallback } from '../providers/CachedNotesProvider'
import { cacheKeys, projectNoteQueryKey } from '../utils/queryKeyFactory'
import { SidebarEntry } from '../modules/sidebar/SidebarBuilder'
import { useSafeMutation } from './useSafeMutation'

type SaveNoteTitleOptions = {
  recordName: string
  noteType: NoteType
  title: string
  children?: SidebarEntry[]
}

export default function useSaveNoteTitle() {
  const user = useUserState()
  const privateUserId = user?.cloudKitUserId ?? user?.supabaseUserId
  const ck = useCloudKitClient()
  const sb = useSupabaseClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()
  const queryClient = useQueryClient()

  return useSafeMutation({
    mutationFn: ({ recordName, noteType, title, children }: SaveNoteTitleOptions) => {
      // eslint-disable-next-line no-console
      console.debug('[useSaveNoteTitle] saving', title)

      if (user.cloudKitUserId && !isTeamspaceNote(noteType)) {
        const privateNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.private(privateUserId))
        const changedNotes = compileChangedNotes(privateNotes, recordName, title, children)
        if (changedNotes) {
          return ck.saveNoteMeta(Array.from(changedNotes.values()))
        } else {
          throw new Error('Couldnt find the note')
        }
      }

      if (user.supabaseUserId) {
        return sb.saveNoteTitle(user.supabaseUserId, recordName, title)
      }

      throw new Error('Not signed in')
    },
    onMutate: ({ recordName, noteType, title, children }: SaveNoteTitleOptions) => {
      // eslint-disable-next-line no-console
      console.debug('[useSaveNoteTitle] onMutate', title)

      let previousNotes: Map<string, Note> = new Map<string, Note>()

      if (user.cloudKitUserId && !isTeamspaceNote(noteType)) {
        // cancel any outgoing refetches (so they don't overwrite our optimistic update)
        cachedNotesQueryClient.cancelQueries(cacheKeys.private(privateUserId))
        // snapshot the previous value
        previousNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.private(privateUserId))
        // optimistically update to the new value
        const changedNotes = compileChangedNotes(previousNotes, recordName, title, children)
        if (changedNotes) {
          cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
            return new Map([...oldData, ...changedNotes])
          })
        }
        return previousNotes
      }

      if (user.supabaseUserId) {
        const newNote = cachedNotesQueryClient
          .getQueriesData<Map<string, Note>>(cacheKeys.notes)
          .reduce((acc: Map<string, Note>, [, map]: [QueryKey, Map<string, Note>]) => new Map([...acc, ...map]), new Map<string, Note>())
          .get(recordName)
        // Important: we need to pass a new object instead of newNote.title = title, otherwise react won't rerender
        if (newNote) {
          previousNotes = updateNoteReturnFallback(cachedNotesQueryClient, privateUserId, user.supabaseUserId, { ...newNote, title })
        }
        return previousNotes
      }
    },
    onError: (_error, { noteType }, context: Map<string, Note>) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      // and invalidate the queries. Especially for CloudKit, we need to do this, because the recordChangeTag might have changed
      if (isTeamspaceNote(noteType)) {
        cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(user.supabaseUserId), context)
        cachedNotesQueryClient.invalidateQueries(cacheKeys.team(user.supabaseUserId))
      } else {
        cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), context)
        cachedNotesQueryClient.invalidateQueries(cacheKeys.private(privateUserId))
      }
    },
    onSuccess: (updatedNotes, { noteType }) => {
      // eslint-disable-next-line no-console
      console.debug('[useSaveNoteTitle] onSuccess', updatedNotes)

      // update the note in the cache
      if (isTeamspaceNote(noteType)) {
        cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(user.supabaseUserId), (oldData: Map<string, Note>) => {
          return new Map([...oldData, ...updatedNotes])
        })
      } else {
        cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
          return new Map([...oldData, ...updatedNotes])
        })
        // update the recordChangeTag
        if (user.cloudKitUserId) {
          for (const [, note] of updatedNotes) {
            queryClient.setQueryData(projectNoteQueryKey({ recordName: note.recordName, noteType: noteType }), (oldData: Note) => {
              return { ...oldData, recordChangeTag: note.recordChangeTag }
            })
          }
        }
      }
    },
  })
}

function compileChangedNotes(privateNotes: Map<string, Note>, recordName: string, title: string, children?: SidebarEntry[]): Map<string, Note> | undefined {
  const note = privateNotes.get(recordName)
  if (!note || !note.isFolder) return undefined

  const [oldPath, newPath] = updateFolderTitle(note, title)
  // update the title and filename of the note
  const changedNotes: Map<string, Note> = new Map([[recordName, { ...note, title, filename: newPath }]])
  // update the filename of all children
  return new Map([...changedNotes, ...sidebarEntriesToNotes(privateNotes, oldPath, newPath, children)])
}

function updateFolderTitle(note: Note, title: string): [oldName: string, newName: string] {
  const components = note.filename.split('/')
  components.pop()

  // Remove special characters not allowed in folders
  components.push(title.replace(/[/\\:*?"<>|]/g, ''))
  const filename = components.join('/')

  return [note.filename, filename]
}

function sidebarEntriesToNotes(privateNotes: Map<string, Note>, oldPath: string, newPath: string, children?: SidebarEntry[]): Map<string, Note> {
  if (!children) return new Map<string, Note>()

  let changedNotes: Map<string, Note> = new Map()

  children.forEach((child) => {
    const note = privateNotes.get(child.recordName)
    if (!note) return

    changedNotes.set(note.recordName, { ...note, filename: note.filename.replace(oldPath, newPath) })
    const newNotes = sidebarEntriesToNotes(privateNotes, oldPath, newPath, child.children)
    changedNotes = new Map([...changedNotes, ...newNotes])
  })
  return changedNotes
}
