import dayjs from 'dayjs'

function getDropPosition(event) {
  const rect = event.target.getBoundingClientRect()
  const offsetX = event.clientX - rect.left
  const offsetY = event.clientY - rect.top
  return { x: offsetX, y: offsetY }
}

export function getRowNumber(eventDate) {
  const startOfDay = eventDate.startOf('day')
  const minutesSinceMidnight = eventDate.diff(startOfDay, 'minute')
  return Math.floor(minutesSinceMidnight / 15) + 2
}
export function getRowNumberAndDate(event) {
  const position = getDropPosition(event)
  const boundingClientRect = event.target.getBoundingClientRect()
  const segmentHeight = boundingClientRect.height / 97
  const percentOfTimeline = (position.y - segmentHeight) / boundingClientRect.height
  const hour = Math.floor(percentOfTimeline * 24.5)
  const minute = Math.floor((percentOfTimeline * 24.5 - hour) * 60)
  const weekDate = dayjs(event.target.getAttribute('data-date'), 'YYYY-MM-DD')

  let eventDate = weekDate.hour(hour).minute(minute)
  // Add 15min
  eventDate = eventDate.add(-25, 'minute')

  const lastHour = weekDate.hour(23).minute(30)
  if (eventDate.isAfter(lastHour)) {
    eventDate = lastHour
  }
  const rowNumber = getRowNumber(eventDate)

  const eventDateRoundedTo15Minutes = eventDate.minute(eventDate.minute() - (eventDate.minute() % 15))

  return { rowNumber, eventDateRoundedTo15Minutes }
}
