import { useUserState } from '../providers/UserProvider'

// Determine the baseURL based on the hostname
const determineBaseURL = (hostname: string): string => {
  return hostname === 'localhost' ? 'http://localhost:8000/' : 'https://app.noteplan.co/backend/stripe/'
}

export default function useManageSubscription() {
  const user = useUserState()
  const baseURL: string = determineBaseURL(window.location.hostname)

  const openManageSubscription = () => {
    if (!user) return

    if (user.cloudKitUserId) {
      window.open(`${baseURL}manage.php?user=native&id=${user.cloudKitUserId}`, '_blank')
    } else if (user.supabaseUserId) {
      window.open(`${baseURL}manage.php?user=web&id=${user.supabaseUserId}`, '_blank')
    }
  }

  return { openManageSubscription }
}
