import { SupabaseClient } from '@supabase/supabase-js'

export class AuthManager {
  private supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  public async verifyOTP(email: string, token: string): Promise<boolean> {
    const { error } = await this.supabase.auth.verifyOtp({ email, token, type: 'email' })

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return false
    }

    return true
  }

  public async signInWithEmailOTP(email: string): Promise<boolean> {
    // eslint-disable-next-line no-console
    console.log('signInWithEmailOTP', email)
    const { error } = await this.supabase.auth.signInWithOtp({
      email: email,
      options: {
        emailRedirectTo: 'https://app.noteplan.co',
      },
    })

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return false
    }

    return true
  }

  // Use this to set the password after a magic link / token login
  public async updatePassword(password: string): Promise<boolean> {
    const { error } = await this.supabase.auth.updateUser({ password: password })

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return false
    }

    return true
  }

  //   public async signInWithEmailPassword(email: string, password: string): Promise<boolean> {
  //     const { data, error } = await this.supabase.auth.signInWithPassword({
  //       email: email,
  //       password: password,
  //     });

  //     if (error) {
  //       console.log(error);
  //       return false;
  //     }

  //     console.log(data);
  //     return true;
  //   }

  //   public async signUp(email: string, password: string) {
  //     const { data, error } = await this.supabase.auth.signUp({
  //       email: email,
  //       password: password,
  //     });

  //     if (error) {
  //       console.log(error);
  //       return;
  //     }

  //     console.log(data);
  //   }

  public async signOut() {
    const { error } = await this.supabase.auth.signOut()

    if (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }
}
