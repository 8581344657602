import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import './index.css'
import ErrorBoundary from './components/ErrorBoundary'
import { CloudKitClientProvider } from './providers/CloudKitClientProvider.tsx'
import UserProvider from './providers/UserProvider.tsx'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import SignIn from './modules/user/SignInOut.tsx'
import SupabaseClientProvider from './providers/SupabaseClientProvider.tsx'
import SelectedDateProvider from './providers/SelectedDateProvider.tsx'
import Layout from './modules/Layout.tsx'
import CachedNotesProvider from './providers/CachedNotesProvider.tsx'
import { DarkModeProvider } from './providers/DarkModeProvider.tsx'
import { HtmlHeader } from './modules/HtmlHeader.tsx'
import SelectedRecordNameProvider from './providers/SelectedRecordNameProvider.tsx'

// window.attachments = {};
// window.debug = (...args) => {
//   if (window.__DEV__) {
//     console.log(...args);
//   }
// };

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary>
      <SupabaseClientProvider>
        <SignIn />
        <CloudKitClientProvider>
          <QueryClientProvider client={queryClient}>
            <CachedNotesProvider>
              <UserProvider>
                <HelmetProvider>
                  <DarkModeProvider>
                    <HtmlHeader />
                    <SelectedDateProvider>
                      <SelectedRecordNameProvider>
                        <Layout />
                      </SelectedRecordNameProvider>
                    </SelectedDateProvider>
                  </DarkModeProvider>
                </HelmetProvider>
              </UserProvider>
            </CachedNotesProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </CloudKitClientProvider>
      </SupabaseClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
)
