import { useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import { NoteType } from '../utils/syncUtils'
import useCreateNote from './useCreateNote'
import { User, useUserState } from '../providers/UserProvider'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import useSaveNote from './useSaveNote'
import { selectedDateToKey } from '../providers/SelectedDateProvider'
import dayjs from 'dayjs'
import useSubscribeEmailOctopus from './useSubscribeEmailOctopus'
import { useQueryClient } from '@tanstack/react-query'

type NotePath = {
  path: string
  title: string
  parent?: { name: string; id: string }
}

// TODO: fetching all notes is not a very good solution. It's probably easier to check if there are any notes, means fetching just a single note would be enough.
const useStarterNotes = () => {
  const user = useUserState()
  const ck = useCloudKitClient()
  const sb = useSupabaseClient()
  const { subscribeUser } = useSubscribeEmailOctopus()
  const queryClient = useQueryClient()

  const createNote = useCreateNote((_recordName) => {
    // console.log(`[useStarterNotes] ${recordName} created successfully`)
  })

  const saveNote = useSaveNote()

  const userHasNotes = useCallback(
    async (user: User) => {
      let hasNotes = false

      if (user.cloudKitUserId) {
        const hasNotesString = await ck.fetchUserDefault('hasNotes')
        hasNotes = hasNotesString === 'true'
        if (!hasNotes) {
          hasNotes = await ck.hasPrivateNotes()
          await ck.setUserDefault('hasNotes', hasNotes.toString())
        }
      } else if (user.supabaseUserId) {
        const userMetaData = await sb.getUserMetaData()

        hasNotes = userMetaData?.hasNotes === 'true'
        if (!hasNotes) {
          hasNotes = await sb.hasPrivateNotes(user.supabaseUserId)
          await sb.setUserMetaData({ ...userMetaData, hasNotes: hasNotes.toString() })
        }
      }

      return hasNotes
    },
    [ck, sb]
  )

  const createDirectories = useCallback(
    async (directories: { name: string; id: string }[], noteType = NoteType.PROJECT_NOTE, parentId = null, uploadSync = false) => {
      // Filter for unique directories by id
      directories = directories.filter((dir, index, self) => self.findIndex((d) => d.id === dir.id) === index)

      const uploadNote = async (dir: { name: string; id: string }) => {
        return createNote.mutateAsync({
          recordName: dir.id,
          noteType: noteType,
          parent: parentId,
          filename: dir.name,
          isDir: true,
        })
      }

      if (uploadSync) {
        const createPromises = directories.map((dir) => uploadNote(dir))
        await Promise.all(createPromises)
      } else {
        for (const dir of directories) {
          await uploadNote(dir)
        }
      }
    },
    [createNote]
  )

  const createFilesInDirectories = useCallback(
    async (textFiles: NotePath[], noteType = NoteType.PROJECT_NOTE, isCloudKitUser = false, uploadAsync = false) => {
      const uploadNote = async (file: NotePath) => {
        const response = await fetch(file.path)
        const data = await response.text()

        let filename = file.path.split('/').pop()
        if (file.parent && noteType === NoteType.PROJECT_NOTE && isCloudKitUser) {
          filename = file.parent.name + '/' + filename
        }

        const draft = {
          recordName: uuid(),
          noteType: noteType,
          parent: file.parent?.id, // Assign ID of parent in case it's supabase, ignored by CloudKit
          content: data,
          filename: filename,
          title: file.title,
        }

        return createNote.mutateAsync(draft)
      }

      if (uploadAsync) {
        await Promise.all(textFiles.map(async (file) => uploadNote(file)))
      } else {
        for (const file of textFiles) {
          await uploadNote(file)
        }
      }
    },
    [createNote]
  )

  const createDailyNote = useCallback(
    async (textFile: NotePath, noteType = NoteType.PROJECT_NOTE, teamspaceId: string, isCloudKit: boolean) => {
      const response = await fetch(textFile.path)
      const data = await response.text()

      // In case we are in the process to load the non existing calendar note (it will be loaded blank and might cause a race condition where the blank note is shown first)
      await queryClient.cancelQueries()
      await saveNote.mutateAsync({
        noteType: noteType,
        parent: teamspaceId,
        content: data,
        filename: selectedDateToKey({ active: 'day', week: 0, year: 0, date: dayjs() }) + (isCloudKit ? '.txt' : '.md'),
        attachments: null,
        recordName: uuid(),
        modificationDate: null,
        forceCreate: true,
      })

      // Now invalidate the query client, so it loads the just saved daily note
      queryClient.invalidateQueries()
    },
    [queryClient, saveNote]
  )

  // This will always be a supabase call, since it's for Teamspaces
  const createTeamspaceStarterNotes = useCallback(
    async (teamspaceId: string, readyForOpen?: () => void) => {
      if (!teamspaceId) {
        return
      }

      await Promise.all([
        createFilesInDirectories(
          [{ path: '../default-notes/teamspace-start-here.txt', title: '👉 Start Here', parent: { name: '', id: teamspaceId } }],
          NoteType.TEAM_SPACE_NOTE,
          false,
          true
        ),
        createDailyNote({ path: '../default-notes/teamspace-daily-note.txt', title: '' }, NoteType.TEAM_SPACE_CALENDAR_NOTE, teamspaceId, false),
      ])
      readyForOpen?.()

      const textFiles: NotePath[] = [
        { path: '../default-notes/teamspace-what-are-projects.txt', title: '👨‍💻 What are Projects?', parent: { name: '10 - Projects', id: uuid() } },
        { path: '../default-notes/teamspace-what-are-areas.txt', title: '🗺️ What are Areas?', parent: { name: '20 - Areas', id: uuid() } },
        { path: '../default-notes/teamspace-what-are-resources.txt', title: '📦 What are Resources?', parent: { name: '30 - Resources', id: uuid() } },
        { path: '../default-notes/teamspace-what-goes-into-archive.txt', title: '🗃️ What goes into the Archive?', parent: { name: '40 - Archive', id: uuid() } },
      ]

      await createDirectories(
        textFiles.map((file) => file.parent).filter((parent) => parent.id !== teamspaceId),
        NoteType.TEAM_SPACE_NOTE,
        teamspaceId,
        true
      )
      await createFilesInDirectories(textFiles, NoteType.TEAM_SPACE_NOTE, false, true)
    },
    [createDirectories, createFilesInDirectories, createDailyNote]
  )

  // This can be a supabase or cloudkit call
  const createPrivateStarterNotes = useCallback(
    async (started?: () => void, readyForOpen?: () => void) => {
      if (!user || (await userHasNotes(user))) {
        return
      }

      started?.()
      const isCloudKit = !!user.cloudKitUserId

      // If the user wasn't confirmed today, return and don't create starter notes
      if (!isCloudKit && user.supabaseUserId) {
        if (user.confirmed_at) {
          const currentDate = new Date()
          const confirmedDate = new Date(user.confirmed_at)
          if (
            currentDate.getDate() !== confirmedDate.getDate() ||
            currentDate.getMonth() !== confirmedDate.getMonth() ||
            currentDate.getFullYear() !== confirmedDate.getFullYear()
          ) {
            return
          }
        }

        // Subscribe user (only non-Teamspace and only first time)
        subscribeUser(user.supabaseUserId)
      }

      const resources = { name: '30 - Resources', id: uuid() }
      const textFiles: NotePath[] = [
        { path: '../default-notes/what-are-projects.txt', title: '👨‍💻 What are Projects?', parent: { name: '10 - Projects', id: uuid() } },
        { path: '../default-notes/what-are-areas.txt', title: '🗺️ What are Areas?', parent: { name: '20 - Areas', id: uuid() } },
        { path: '../default-notes/what-are-resources.txt', title: '📦 What are Resources?', parent: resources },
        { path: '../default-notes/how-does-noteplan-work.txt', title: '🙋‍♂️ How does NotePlan work?', parent: resources },
        { path: '../default-notes/what-goes-into-archive.txt', title: '🗃️ What goes into the Archive?', parent: { name: '40 - Archive', id: uuid() } },
      ]

      await createDailyNote({ path: '../default-notes/daily-note.txt', title: '' }, NoteType.CALENDAR_NOTE, undefined, isCloudKit)
      await createFilesInDirectories([{ path: '../default-notes/start-here.txt', title: '👉 Start Here', parent: null }], NoteType.PROJECT_NOTE, !!user.cloudKitUserId)
      readyForOpen?.()

      await createDirectories(
        textFiles.map((file) => file.parent).filter((parent) => parent !== null),
        NoteType.PROJECT_NOTE
      )

      await createFilesInDirectories(textFiles, NoteType.PROJECT_NOTE, isCloudKit)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createDirectories, createFilesInDirectories, userHasNotes, createDailyNote, user]
  )

  return { createPrivateStarterNotes, createTeamspaceStarterNotes, userHasNotes }
}

export default useStarterNotes
