/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefaultBlockSchema, PartialBlock, PartialInlineContent, StyledText } from '@blocknote/core'
import styles from '../../../../../packages/core/src/extensions/Blocks/nodes/Block.module.css'
import classNames from 'classnames'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Disclosure } from '@headlessui/react'
import { pluralize } from '../../utils/commons'
import { useState } from 'react'
import { FromNote } from '../../hooks/useNoteReferences'
import { useLocalStorage } from 'usehooks-ts'
import { Range } from '@tiptap/core'

function SkeletonLoader() {
  const lineLengths = [80, 75, 65]
  return (
    <div>
      {lineLengths.map((length, index) => {
        return <div key={index} className="skeleton rounded-md h-4 my-3" style={{ width: `${length}%` }}></div>
      })}
    </div>
  )
}

export function ReferenceBlock({ block, onSelectNote, keyword }: { block: PartialBlock<DefaultBlockSchema>; onSelectNote: () => void; keyword?: string }) {
  return (
    <div className={styles.blockGroup + ' cursor-pointer pl-2'} data-node-type="blockGroup" onClick={onSelectNote}>
      <div data-id={block.id} className={styles.blockOuter} data-node-type="block-outer">
        <div className={styles.block} data-node-type="blockContainer">
          <div
            className={classNames({
              'flex items-center': true,
              '-ml-3': block.children?.length > 0 && block.type !== 'heading',
              '-ml-2': block.children?.length > 0 && block.type === 'heading',
            })}
          >
            <div
              className={classNames({
                [styles.blockContent]: true,
                ['text-zinc-500 dark:text-zinc-400 font-bold']: block.type === 'heading',
                'text-[15px] dark:opacity-90': block.type !== 'heading',
              })}
              data-content-type={block.type}
              data-checked={block.props?.['checked'] ?? false}
              data-cancelled={block.props?.['cancelled'] ?? false}
              data-scheduled={block.props?.['scheduled'] ?? false}
              data-flagged={block.props?.flagged ?? 0}
              data-folded={block.props?.folded ?? false}
              data-visible={block.props?.visible ?? true}
            >
              {['taskListItem', 'checkListItem'].includes(block.type) && (
                <label
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <input type="checkbox" />
                </label>
              )}
              <BlockContent content={block.content} keyword={keyword} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function BlockContent({ content, keyword }: { content: string | PartialInlineContent[]; keyword?: string }) {
  if (typeof content === 'string') {
    if (keyword) {
      return highlightText(content, keyword)
    } else {
      return <span>{content}</span>
    }
  } else {
    return <InlineContent content={content} keyword={keyword} />
  }
}

function highlightText(text: string, keyword: string) {
  const regex = new RegExp(`(${keyword})`, 'gi')
  const parts = text.split(regex)
  return (
    <span>
      {parts.map((part, index) => {
        if (part.toLowerCase() === keyword.toLowerCase()) {
          return <mark key={index}>{part}</mark>
        } else {
          return part
        }
      })}
    </span>
  )
}

export function InlineContent({ content, className, keyword }: { content: PartialInlineContent[]; className?: string; keyword?: string }) {
  return (
    <div className={`break-all ${className ?? ''}`}>
      {content.map((inlineContent: PartialInlineContent, index) => {
        if (inlineContent.type === 'link') {
          return (
            <a href={inlineContent.href} key={index} className={styles.inlineContent}>
              {typeof inlineContent.content === 'string' ? inlineContent.content : inlineContent.content.map((text, index) => renderStyledText(text, index))}
            </a>
          )
        }
        return renderStyledText(inlineContent, index, keyword)
      })}
    </div>
  )
}

function renderStyledText(content: StyledText, index: number, keyword?: string) {
  const text = keyword ? highlightText(content.text, keyword) : content.text
  if (content.styles.bold) {
    return <strong key={index}>{text}</strong>
  }
  if (content.styles.italic) {
    return <em key={index}>{text}</em>
  }
  if (content.styles.strikethrough) {
    return <del key={index}>{text}</del>
  }
  if (content.styles.underlined) {
    return <u key={index}>{text}</u>
  }
  if (content.styles.highlighted) {
    return <mark key={index}>{text}</mark>
  }
  if (content.styles.timeString) {
    return (
      <span data-content-type="time-string" key={index}>
        {text}
      </span>
    )
  }
  if (content.styles.code) {
    return <code key={index}>{text}</code>
  }
  if (content.styles.hashtag) {
    return (
      <a data-hashtag="hashtag" key={index}>
        {text}
      </a>
    )
  }
  if (content.styles.wikilink) {
    return (
      <a data-wikilink="wikilink" key={index}>
        {text}
      </a>
    )
  }
  if (content.styles.datelink) {
    return (
      <a data-datelink="datelink" key={index}>
        {text}
      </a>
    )
  }

  return (
    <span className="styledText" data-type={content.type} key={index}>
      {text}
    </span>
  )
}

function NoteReferencedFrom({ id, note, onSelectNote }: { id: string; note: FromNote; onSelectNote: (_recordName: string, _range: Range) => void }) {
  const [isOpen, setIsOpen] = useState(true)
  return (
    <Disclosure as="div" defaultOpen={isOpen} className="mt-2">
      {({ open }) => (
        <>
          <Disclosure.Button onClick={() => setIsOpen(!isOpen)} className="flex">
            <ChevronRightIcon className={classNames({ 'rotate-90': open, 'text-gray-500 dark:text-gray-200 h-4 w-4 shrink-0 mr-1': true })} aria-hidden="true" />
            <span className="text-xs font-bold flex items-center justify-between">{note.title}</span>
          </Disclosure.Button>
          <Disclosure.Panel className="ml-10">
            {note.blocks.map((child, index) => (
              <ReferenceBlock key={index} block={child} onSelectNote={() => onSelectNote(id, note.ranges[index])} />
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

type NoteReferenceProps = {
  isLoading: boolean
  references: Map<string, FromNote>
  onSelectNote: (_recordName: string) => void
}

export function NoteReference({ isLoading, references, onSelectNote }: NoteReferenceProps) {
  const [isOpen, setIsOpen] = useLocalStorage('showReferences', true)
  return (
    <Disclosure as="div" defaultOpen={isOpen} className="mx-auto max-w-3xl w-full mt-3 cursor-default" style={{ paddingInline: '54px' }}>
      {({ open }) => (
        <>
          <Disclosure.Button as="h1" className="text-xs font-bold uppercase flex items-center opacity-60" onClick={() => setIsOpen(!isOpen)}>
            <ChevronRightIcon className={classNames({ 'rotate-90': open, 'text-gray-500 dark:text-gray-200 h-4 w-4 shrink-0 mr-1': true })} aria-hidden="true" />
            <span className="opacity-60">
              {references.size} {pluralize('Reference', references.size)}
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className="my-3 bg-zinc-100/70 dark:bg-zinc-800 rounded-md px-0 py-1.5">
            {isLoading ? (
              <SkeletonLoader />
            ) : (
              <div>
                {Array.from(references.entries()).map(
                  ([recordName, note]) => note.blocks.length > 0 && <NoteReferencedFrom key={recordName} id={recordName} note={note} onSelectNote={onSelectNote} />
                )}
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
