import { User } from './../providers/UserProvider'
import { useState } from 'react'
import { Stripe, StripeElements, StripeCardElement } from '@stripe/stripe-js'
import { CardElement } from '@stripe/react-stripe-js'
import axios, { AxiosInstance } from 'axios'

// Define types for the response structure from your backend
interface BackendResponse {
  error?: string
  // Add other fields expected in your successful response here
}

interface Address {
  address: object
  name: string
}

// Define the types for the parameters of usePayment
interface UsePaymentParams {
  stripe: Stripe | null
  elements: StripeElements | null
}

// Function to create the backend client
const createBackendClient = (baseURL: string): AxiosInstance => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// Determine the baseURL based on the hostname
const determineBaseURL = (hostname: string): string => {
  return hostname === 'localhost' ? 'http://localhost:8000/' : 'https://app.noteplan.co/backend/stripe/'
}

const usePayment = ({ stripe, elements }: UsePaymentParams) => {
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean | null>(null)
  const baseURL: string = determineBaseURL(window.location.hostname)
  const backendClient: AxiosInstance = createBackendClient(baseURL)

  const startSubscription = async (
    paymentMethodId: string,
    user: User,
    email: string,
    address: Address,
    duration: string,
    revenueCatUserId: string,
    stripeCustomerId?: string
  ): Promise<BackendResponse | null> => {
    try {
      const response = await backendClient.post<BackendResponse>('/payments.php', {
        paymentMethodId: paymentMethodId,
        email: email ?? user?.email,
        user: user,
        address: address,
        duration: duration,
        revenueCatUserId: revenueCatUserId,
        stripeCustomerId: stripeCustomerId ?? '',
      })

      if (response.status !== 200) {
        setError('Network response was not ok')
        setIsLoading(false)
        return null
      }

      if (response.data.error) {
        setError(response.data.error)
        setIsLoading(false)
        return null
      }

      return response.data
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error)
      } else {
        setError(error.message || 'An unknown error occurred')
      }
      setIsLoading(false)
      return null
    }
  }

  const resubscribe = async (user: User, duration: string, revenueCatUserId: string, stripeCustomerId: string): Promise<boolean> => {
    setError(null)
    setIsLoading(true)

    try {
      const data: BackendResponse | null = await startSubscription('', user, user.email, null, duration, revenueCatUserId, stripeCustomerId)
      if (data && !data.error) {
        // Handle successful payment
        setIsLoading(false)
        return true
      }
    } catch (error) {
      setError(error.message || 'An unknown error occurred')
      setIsLoading(false)
      return false
    }
  }

  const sendPayment = async (user: User, email: string, address: Address, duration: string, revenueCatUserId: string): Promise<boolean> => {
    setError(null)
    setIsLoading(true)

    if (!stripe || !elements) {
      setError('Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.')
      setIsLoading(false)
      return false
    }

    const card: StripeCardElement | null = elements.getElement(CardElement)
    if (!card) {
      setError('Error: Card Element not found')
      setIsLoading(false)
      return false
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: card,
      })

      if (error) {
        setError(error.message)
        setIsLoading(false)
        return false
      }

      if (paymentMethod) {
        const data: BackendResponse | null = await startSubscription(paymentMethod.id, user, email, address, duration, revenueCatUserId)
        if (data && !data.error) {
          // Handle successful payment
          setIsLoading(false)
          return true
        }
      }
    } catch (error) {
      setError(error.message || 'An unknown error occurred')
      setIsLoading(false)
      return false
    }
    return false
  }

  return { sendPayment, error, setError, isLoading, resubscribe }
}

export default usePayment
