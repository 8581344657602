import { Note } from './syncUtils'
import { LinkMarkSuggestionItem } from '@blocknote/core'

export const tagList = (notes: Note[], prefix: string): LinkMarkSuggestionItem[] => {
  const tags = notes
    .flatMap((note) => note.tags ?? [])
    .filter((tag) => tag.startsWith(prefix))
    .map((tag) => tag.substring(1))
  return Array.from(new Set(tags))
    .sort()
    .map((tag) => ({
      icon: prefix == '#' ? 'hashtag' : 'at',
      display: tag,
      paste: tag,
    }))
}
