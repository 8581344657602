/* eslint-disable no-console */
// We can't compile this file because of an issue that the tsl-apple-cloudKit library is causing, couldn't find any solution for this.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// comment this out for running the app, else you get a TypeError
// import CloudKit from 'tsl-apple-cloudkit';
import { QueryClient } from '@tanstack/react-query'
import {
  Attachment,
  Note,
  NoteType,
  convertAttachmentToFiles,
  fileAttachmentFromString,
  SourceDatabase,
  getFilenameFromNoteTitle,
  readNoteTitleFromContent,
  readTagsFromContent,
} from '../utils/syncUtils'
import { cacheKeys, noteQueryKey, privateKeys } from '../utils/queryKeyFactory'
import { mapDelete, mapSet } from '../utils/mapAsState'

type Notification = {
  _containerIdentifier: string
  _dbType: number
  _notificationID: string
  _reason: number
  _recordName: string
  _subscriptionID: string
  _zoneID: { zoneName: string; ownerRecordName: string }
}

export class CloudKitClient {
  private ck!: CloudKit.CloudKit
  private subscribedToNotifications = false
  private zone = { zoneName: 'Notes' }
  private zoneExists = false
  private lastSuccessfulRegistration: number | null = null

  // This is used to skip notifications, because we get a lot of notifications when we save a lot of notes, especially when we update the titles
  // And each notification causes a fetch of the same note and a sidebar update. This is not going well, if thousands of notes are updated at once.
  private skipNotificationRecordNames: string[] = []

  public constructor() {
    this.ck = CloudKit.configure({
      containers: [
        {
          containerIdentifier: 'iCloud.co.noteplan.NotePlan',
          apiTokenAuth: {
            apiToken: '542ae03d758990b548f1ebe6af33401a67781612dbfe590e0927a83c560049f8',
            persist: true,
            signInButton: {
              id: 'apple-sign-in-button',
              theme: 'medium',
            },
            signOutButton: {
              id: 'apple-sign-out-button',
              theme: 'medium',
            },
          },
          environment: 'production',
        },
      ],
    })
  }

  public getContainer() {
    return this.ck.getDefaultContainer()
  }

  public getDatabase() {
    return this.getContainer().privateCloudDatabase
  }

  public setUpAuth(onSuccess: (_result: CloudKit.UserIdentity | null) => unknown, onError: (_reason?: CloudKit.CKError | undefined) => unknown) {
    this.getContainer().setUpAuth().then(onSuccess).catch(onError)
  }

  public onSignedIn(onSuccess: (_result: CloudKit.UserIdentity) => unknown | undefined) {
    this.getContainer().whenUserSignsIn().then(onSuccess)
  }

  public onSignedOut(onSuccess: () => unknown | undefined) {
    this.getContainer().whenUserSignsOut().then(onSuccess)
  }

  public async registerForNotifications(user: User, queryClient: QueryClient, cachedNotesQueryClient: QueryClient) {
    if (!user || !user.cloudKitUserId) return

    let refreshNotification = false
    if (this.getContainer().isRegisteredForNotifications) {
      const currentTime = Date.now()
      const expirationPeriod = 6 * 60 * 60 * 1000 // 6 hours

      if (this.lastSuccessfulRegistration && currentTime - this.lastSuccessfulRegistration > expirationPeriod) {
        refreshNotification = true
      } else {
        return // Subscription didn't expire yet
      }
    }

    let cachedNotifications: Notification[] = []

    // Define a debounce function
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    const debounce = <T extends (...args: any[]) => void>(func: T, delay: number) => {
      let timeoutId: NodeJS.Timeout

      return (...args: Parameters<T>) => {
        clearTimeout(timeoutId)

        timeoutId = setTimeout(() => {
          func(...args)
        }, delay)
      }
    }

    const handleNotificationDebounced = debounce(() => {
      const notifications = cachedNotifications
      cachedNotifications = []

      // Process the notifications here
      notifications.forEach((notification) => {
        this.processNotification(notification, queryClient, cachedNotesQueryClient, user)
      })
    }, 300)

    const handleNotification = async (notification: Notification) => {
      if (!notification._recordName || !queryClient) return

      // Skip notifications if we just saved the note for example when updating the titles
      if (this.skipNotificationRecordNames.includes(notification._recordName)) {
        // Remove this recordName from the skip list, so we don't ignore it again
        this.skipNotificationRecordNames = this.skipNotificationRecordNames.filter((recordName) => recordName !== notification._recordName)
        return
      }

      cachedNotifications.push(notification)
      handleNotificationDebounced()
    }

    if (!this.subscribedToNotifications) {
      await this.subscribeToZoneIfNeeded(user.cloudkitUserId)
      this.getContainer().registerForNotifications()
      this.getContainer().addNotificationListener(handleNotification)

      this.lastSuccessfulRegistration = Date.now()
      this.subscribedToNotifications = true
    } else if (refreshNotification) {
      console.log('[CloudKit] Refresh notifications by unregistering and registering again.')
      this.getContainer().unregisterForNotifications()
      this.getContainer().registerForNotifications()
      this.lastSuccessfulRegistration = Date.now()
    }
  }

  public async processNotification(notification: Notification, queryClient: QueryClient, cachedNotesQueryClient: QueryClient, user: User) {
    // eslint-disable-next-line no-console
    console.log(notification)
    if (!user?.cloudKitUserId) return
    const privateUserId = user?.cloudKitUserId

    // update notes
    const updatedNote = (notification._reason === 1 || notification._reason === 2) && (await this.fetchNoteByRecordName(notification._recordName))
    switch (notification._reason) {
      case 1: // created
        if (updatedNote) {
          cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
            return mapSet(oldData, updatedNote.recordName, updatedNote)
          })

          // Also add it to the regular single note query client
          queryClient.setQueryData<Note>(noteQueryKey(updatedNote), () => updatedNote)
        }
        break

      case 2: // updated
        if (updatedNote) {
          cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
            return mapSet(oldData, updatedNote.recordName, updatedNote)
          })

          // Update also the note cache for the notes that were opened in the editor (especially the one that's right now opened, the cachedNoteQueryClient won't trigger an update)
          queryClient.setQueryData<Note>(noteQueryKey(updatedNote), () => updatedNote)
        }
        break

      case 3: // deleted
        cachedNotesQueryClient.setQueriesData<Map<string, Note>>(cacheKeys.private(user?.cloudKitUserId), (oldData: Map<string, Note>) => {
          if (!oldData) return new Map<string, Note>()
          return mapDelete(oldData, notification._recordName)
        })
        break
    }

    if (notification._reason === 3) {
      const data = queryClient.getQueriesData<Note>(privateKeys.notes)
      for (const [queryKey, note] of data) {
        if (!note || !queryKey || !note.recordName) continue

        if (notification._recordName === note.recordName) {
          queryClient.removeQueries(queryKey)
          return false
        }
      }
    }
  }

  // In the native version we are re-subscribing on every restart, I'm not sure if this is needed. In initial tests, the subscription went stale somehow.
  private async subscribeToZoneIfNeeded(currentUserId: string) {
    const database = this.getDatabase()

    const subscribe = async () => {
      const subscription = {
        subscriptionID: 'note-changes',
        subscriptionType: 'query',
        zoneID: { ownerRecordName: currentUserId, zoneName: '_zoneWide', zoneType: 'ZONE_WIDE' }, //this.zone,
        firesOn: ['create', 'update', 'delete'],
        firesOnce: false,
        zoneWide: true,
        query: {
          recordType: 'Note',
          filterBy: [],
        },
      }

      const response = await database.saveSubscriptions(subscription)

      if (response.hasErrors) {
        console.log(response.errors[0])
        throw response.errors[0]
      }
    }

    // First fetch existing subscriptions, and if there's none, subscribe
    console.log('[CloudKit] Fetch existing subscriptions')
    const response = await database.fetchAllSubscriptions()

    if (response.hasErrors) {
      throw response.errors[0]
    }

    console.log('[CloudKit] Existing subscriptions', response.subscriptions)

    // Alternatively, we could delete and recreate the subscription, that's what we do on native, but I'm not sure if this is needed on web
    // Delete the subscription and re-create it
    // console.log('[CloudKit] Deleting existing subscriptions')
    // await database.deleteSubscriptions({ subscriptionID: 'note-changes' })

    if (response.subscriptions.length === 0) {
      console.log('[CloudKit] Re-suscribing')
      await subscribe()
    }
  }

  public async setUserDefault(key: string, value: string): Promise<void> {
    const database = this.getDatabase()
    const zone = { zoneName: 'Settings' }
    await this.createZoneIfNeeded(zone)

    // Fetch the existing UserVariable record
    const fetchResponse = await database.performQuery(
      {
        recordType: 'UserVariable',
        filterBy: [{ systemFieldName: 'recordName', comparator: CloudKit.QueryFilterComparator.EQUALS, fieldValue: { value: { recordName: key } } }],
      },
      { zoneID: zone }
    )

    if (fetchResponse.hasErrors) {
      throw fetchResponse.errors[0]
    }

    // Check if the fetched record's value is different from the new value
    const existingRecord = fetchResponse.records[0]
    if (existingRecord && existingRecord.fields.stringValue.value === value) {
      console.log('[CloudKit] No change for user default', key)
      return
    } else {
      console.log('[CloudKit] Could not find user default or values are different, so save the new value', key)
    }

    // Save the UserVariable record if the value has changed
    const recordToSave: CloudKit.RecordToSave = {
      recordType: 'UserVariable',
      recordName: key,
      fields: {
        stringValue: { value: value },
      },
    }

    // If the existing record has a recordChangeTag, add it to the record to save
    if (existingRecord && existingRecord.recordChangeTag) {
      recordToSave.recordChangeTag = existingRecord.recordChangeTag
    }

    const saveResponse = await database.saveRecords(recordToSave, { zoneID: zone })

    if (saveResponse.hasErrors) {
      console.log(saveResponse.errors[0])
      throw saveResponse.errors[0]
    }

    console.log('[CloudKit] Saved user default', key, value)
  }

  public async fetchUserDefault(key: string): Promise<string> {
    // Fetch a UserVariable record from the default zone
    const database = this.getDatabase()
    const response = await database.performQuery({
      recordType: 'UserVariable',
      filterBy: [
        {
          systemFieldName: 'recordName',
          comparator: CloudKit.QueryFilterComparator.EQUALS,
          fieldValue: {
            value: { recordName: key },
          },
        },
      ],
    })

    if (response.hasErrors) {
      throw response.errors[0]
    }

    const records = response.records
    const numberOfRecords = records.length

    if (numberOfRecords === 0) {
      return ''
    }

    const record = records[0]
    const value = record.fields?.stringValue.value as string

    console.log('[CloudKit] fetched user default', record, value)

    return value
  }

  public async createNote(currentUserId: string, draft: Note) {
    await this.createZoneIfNeeded()

    console.log('[CloudKit] create note', draft)
    const database = this.getDatabase()

    // Always save as encrypted, an encrypted note has a different note type (asset_calendar_note or asset_project_note)
    // EDIT: Don't save as encrypted, we will later manage that with local encryption, as database encryption is very slow
    // draft.encrypted = true;
    // draft.noteType = isCalendarNote(draft.noteType) ? NoteType.ASSET_CALENDAR_NOTE : draft.isFolder ? NoteType.PROJECT_NOTE : NoteType.ASSET_PROJECT_NOTE;
    // The note is marked as encrypted, so load the content into the assets, CloudKit automatically encrypts assets
    // const contentAsset = fileAttachmentFromString(draft.content);

    const record: CloudKit.RecordToSave = {
      recordType: 'Note',
      fields: {
        noteType: { value: draft.noteType },
        isDir: { value: draft.isFolder ? 1 : 0 },
        filename: { value: draft.filename },
        content: { value: draft.content, type: 'STRING' },
        // title: { value: draft.title, type: 'STRING', isEncrypted: true },
        fileModifiedAt: { value: Date.now() },
        // attachments: draft.isFolder ? {} : { value: [contentAsset] },
      },
    }
    record.recordName = draft.recordName

    this.skipNotificationRecordNames.push(draft.recordName)
    const response = await database.saveRecords([record], { zoneID: this.zone })

    if (response.hasErrors) {
      // Remove the recordName from the skip list, so we don't ignore it again
      this.skipNotificationRecordNames = this.skipNotificationRecordNames.filter((recordName) => recordName !== draft.recordName)
      throw response.errors[0]
    }

    const createdNote = await this.loadNoteFrom(response.records[0])

    console.log('[CloudKit] finished creating', createdNote.recordChangeTag)
    return createdNote
  }

  public async saveNote(note: Note | undefined, content: string, attachments: string[], modifiedAt: Date = Date.now()) {
    await this.createZoneIfNeeded()
    console.log('[CloudKit] saving note', note?.recordChangeTag)

    if (!note) {
      return
    }

    const database = this.getDatabase()

    note.content = content // Update content here so that we get the up-to-date title.

    // Always save as encrypted, an encrypted note has a different note type (asset_calendar_note or asset_project_note)
    // EDIT: Don't save as encrypted, we will later manage that with local encryption
    // note.encrypted = true;
    // note.noteType = isCalendarNote(note.noteType) ? NoteType.ASSET_CALENDAR_NOTE : note.isFolder ? NoteType.PROJECT_NOTE : NoteType.ASSET_PROJECT_NOTE;

    const record: CloudKit.RecordToSave = {
      recordType: 'Note',
      fields: {
        content: { value: content, type: 'STRING' },
        // Save the title in the meta field, so that we can use this instead of the filename (which can't contain special characters like '/', otherwise we get into trouble)
        filename: { value: getFilenameFromNoteTitle(note) },
        // title: { value: readNoteTitleFromContent(note, false), type: 'STRING', isEncrypted: true },
        noteType: { value: note.noteType },
        fileModifiedAt: { value: modifiedAt?.getTime() ?? Date.now() },
        attachments: {
          value: (await convertAttachmentToFiles(attachments))
            .map((attachment: Attachment) => attachment.file)
            .filter((file: File) => file !== undefined) as File[] as unknown as Blob,
        },
      },
    }

    // EDIT: We are reading out the meta data now from the content directly, so no need to save it anymore
    // // Save meta field if there's data
    // if (!note.isFolder) {
    //   // Set the meta field if needed
    //   const tags = readTagsFromContent(note);
    //   if (tags.length > 0 || (note.meta && Object.keys(note.meta).length > 0)) {
    //     note.meta = { ...note.meta, tags: tags };
    //   } else {
    //     note.meta = note.meta && Object.keys(note.meta).length > 0 ? { ...note.meta } : undefined;
    //   }

    //   record.fields.meta = { value: JSON.stringify(note.meta || {}) };
    // }

    // The note is marked as encrypted, so load the content into the assets, CloudKit automatically encrypts assets
    if (note.encrypted || note.noteType == NoteType.ASSET_CALENDAR_NOTE || note.noteType == NoteType.ASSET_PROJECT_NOTE) {
      // Get an asset version of the content
      const contentAsset = fileAttachmentFromString(content)

      // Prepend to attachments
      record.fields['attachments'].value.unshift(contentAsset)

      // Remove the content field
      delete record.fields['content']
    }

    // If it's an existing note apply the recordName and the recordChangeTag, so it updates the existing note
    if (note.recordName && note.recordChangeTag) {
      record.recordName = note.recordName
      record.recordChangeTag = note.recordChangeTag
    }

    this.skipNotificationRecordNames.push(note.recordName)
    const response = await database.saveRecords([record], { zoneID: this.zone })

    if (response.hasErrors) {
      // Remove the recordName from the skip list, so we don't ignore it again
      this.skipNotificationRecordNames = this.skipNotificationRecordNames.filter((recordName) => recordName !== note.recordName)
      throw response.errors[0]
    }

    const savedNote = await this.loadNoteFrom(response.records[0])

    console.log('[CloudKit] finished saving', savedNote.recordChangeTag)
    return savedNote
  }

  // Uploads the title, fileModifiedAt and fiilename of the note(s), using the 'fields' array limit the fields that are uploaded
  public async saveNoteMeta(notes: Note[], fields: string[] = ['filename', 'fileModifiedAt', 'title']) {
    await this.createZoneIfNeeded()
    console.log('[CloudKit] save notes', notes.length, notes)

    // Check if all notes are complete
    const isComplete = notes.every((note) => note.recordName && note.recordChangeTag)
    if (!isComplete) {
      throw new Error('Note needs to have a recordName and a recordChangeTag')
    }

    const database = this.getDatabase()

    // Function to split notes into batches, because we can only upload up to 200 records at once
    const splitIntoBatches = (notes: Note[], batchSize: number): Note[][] => {
      const batches = []
      for (let i = 0; i < notes.length; i += batchSize) {
        const batch = notes.slice(i, i + batchSize)
        batches.push(batch)
      }
      return batches
    }

    // Split notes into batches of 200
    const noteBatches = splitIntoBatches(notes, 200)
    let results: Map<string, Note> = new Map<string, Note>()

    // Process each batch
    for (const batch of noteBatches) {
      const recordsToSave: CloudKit.RecordToSave[] = batch.map((note) => {
        // Save the uploaded record names into the skip list, so we don't get a notification for these records
        // The problem is if we save too many notes, especially the titles, we get a lot of notifications, which causes a lot of refetches for each notification
        this.skipNotificationRecordNames.push(note.recordName)

        const record: CloudKit.RecordToSave = {
          recordType: 'Note',
          recordName: note.recordName,
          recordChangeTag: note.recordChangeTag,
          fields: {},
        }

        // EDIT: We stopped saving into the encrypted title, because it takes up way too much performance and makes the full database query slow
        // if (fields.includes('title')) {
        //   record.fields.title = { value: note.title, type: 'STRING', isEncrypted: true };
        // }

        if (fields.includes('filename')) {
          record.fields.filename = { value: note.filename }
        }

        if (fields.includes('fileModifiedAt')) {
          record.fields.fileModifiedAt = { value: Date.now() }
        }

        return record
      })

      console.log('[CloudKit] recordsToSave', recordsToSave)

      // Save each batch of records
      const response = await database.saveRecords(recordsToSave, { zoneID: this.zone })
      console.log('[CloudKit] response', response)

      if (response.hasErrors) {
        // Remove the recordNames from the skip list, so we don't ignore them again
        this.skipNotificationRecordNames = this.skipNotificationRecordNames.filter((recordName) => !batch.some((note) => note.recordName === recordName))

        this.isSaving = false
        throw response.errors[0]
      } else {
        const notes = await this.loadNotesFrom(response.records)
        results = new Map([...results, ...notes])
      }
    }

    this.isSaving = false
    console.log('[CloudKit] finished saving notes')
    return results
  }

  public async deleteNotes(recordNames: string[]): Promise<void> {
    console.log('[CloudKit] deleting notes', recordNames)
    const database = this.getDatabase()
    const dedublicatedRecordNames = [...new Set(recordNames)]

    const response = await database.deleteRecords(dedublicatedRecordNames, { zoneID: this.zone })

    if (response.hasErrors) {
      throw response.errors[0]
    }

    console.log('[CloudKit] finished deleting', dedublicatedRecordNames)
    return dedublicatedRecordNames
  }

  // MARK: - Fetching

  public async fetchFileExtension(): Promise<string> {
    // eslint-disable-next-line no-console
    console.log('[CloudKit] fetch file extension')

    const fetchOptions: CloudKit.RecordFetchOptions = {
      desiredKeys: ['filename'],
      resultsLimit: 5,
    }

    const query: CloudKit.Query = {
      recordType: 'Note',
      filterBy: [
        {
          fieldName: 'noteType',
          comparator: CloudKit.QueryFilterComparator.IN,
          fieldValue: {
            value: [NoteType.CALENDAR_NOTE, NoteType.ASSET_CALENDAR_NOTE],
          },
        },
      ],
      sortBy: [
        {
          fieldName: 'fileModifiedAt',
          ascending: false,
        },
      ],
    }

    const response = await this.performQuery(query, fetchOptions, false)
    const records = response.records

    // Map records to fielnames, extracting record.fields?.filename?.value
    const filenames = records.map((record) => record.fields?.filename?.value as string)

    // If filenames is empty, return txt, otherwise check which extension is most common
    if (filenames.length === 0) {
      return 'txt'
    }

    // Count the number of occurences of each extension
    const counts: { [key: string]: number } = {}
    filenames.forEach((filename) => {
      const ext = filename.split('.').pop() ?? 'txt'
      counts[ext] = (counts[ext] || 0) + 1
    })

    // Sort the extensions by the number of occurences
    const sorted = Object.keys(counts).sort((a, b) => counts[b] - counts[a])
    const ext = sorted[0]

    // eslint-disable-next-line no-console
    console.log('[CloudKit] loaded extension = ', ext)

    // Return the most common extension
    return ext
  }

  public async testFetch() {
    // eslint-disable-next-line no-console
    console.log('[CloudKit] test fetching')

    const query: CloudKit.Query = {
      recordType: 'Note',
    }

    const fetchOptions: CloudKit.RecordFetchOptions = {
      desiredKeys: ['filename'],
      resultsLimit: 0,
    }

    await this.performQuery(query, fetchOptions, false)
    console.log('[CloudKit] test fetch done')
    return 'fetched'
  }

  public async fetchNoteByRecordName(recordName: string) {
    // eslint-disable-next-line no-console
    console.log('[CloudKit] fetching by recordName (recordName=)', recordName)

    const query: CloudKit.Query = {
      recordType: 'Note',
      filterBy: [
        {
          systemFieldName: 'recordName',
          comparator: CloudKit.QueryFilterComparator.EQUALS,
          // comparator: CloudKit.QueryFilterComparator.IN,
          fieldValue: {
            // value: [recordNames],
            value: { recordName: recordName },
          },
        },
      ],
    }

    const desiredKeys = this.noteFields
    const fetchOptions: CloudKit.RecordFetchOptions = {
      desiredKeys: desiredKeys,
      resultsLimit: 1,
    }

    const response = await this.performQuery(query, fetchOptions)
    console.log('[CloudKit] fetched by recordName')
    return await this.loadNoteFrom(response.records[0])
  }

  public async fetchNoteByFilename(filename: string, shouldExist?: boolean) {
    // eslint-disable-next-line no-console
    console.log('[CloudKit] fetching by filename ', filename)

    const query: CloudKit.Query = {
      recordType: 'Note',
      filterBy: [
        {
          fieldName: 'filename',
          comparator: CloudKit.QueryFilterComparator.EQUALS,
          fieldValue: {
            value: filename,
          },
        },
        {
          fieldName: 'noteType',
          comparator: CloudKit.QueryFilterComparator.IN,
          fieldValue: {
            value: [NoteType.CALENDAR_NOTE, NoteType.ASSET_CALENDAR_NOTE],
          },
        },
      ],
    }

    const fetchOptions: CloudKit.RecordFetchOptions = {
      desiredKeys: this.noteFields,
      resultsLimit: 1,
    }

    const response = await this.performQuery(query, fetchOptions, false)

    const records = response.records
    const numberOfRecords = records.length

    if (numberOfRecords === 0) {
      if (shouldExist) {
        throw new Error('Could not fetch note')
      } else {
        const initialNote: Note = {
          content: '',
          noteType: NoteType.CALENDAR_NOTE,
          filename: filename,
          source: SourceDatabase.CLOUDKIT,
        }
        return initialNote
      }
    }

    const loadedNote = await this.loadNoteFrom(response.records[0])

    // eslint-disable-next-line no-console
    console.log('[CloudKit] fetched by filename ', loadedNote.recordChangeTag)
    return loadedNote
  }

  public async hasPrivateNotes(): Promise<boolean> {
    const fetchOptions: CloudKit.RecordFetchOptions = {
      desiredKeys: ['filename'],
    }

    const query: CloudKit.Query = {
      recordType: 'Note',
    }

    const response = await this.performQuery(query, fetchOptions, false)
    const records = response.records

    // console.log('[CloudKit] hasPrivateNotes', records)
    return records.length > 0
  }

  // Fetches all private notes. If there are a lot of notes, it fetches recursively.
  public async fetchPrivateNotes(
    continuationMarker: string | null = null,
    existingResults: Map<string, Note> = new Map<string, Note>(),
    shouldUpdateTitles = false
  ): Promise<Map<string, Note>> {
    // eslint-disable-next-line no-console
    console.log('[CloudKit] fetching all filenames, loaded = ', existingResults.length)

    const query: CloudKit.Query = {
      recordType: 'Note',
      filterBy: [
        {
          fieldName: 'noteType',
          comparator: CloudKit.QueryFilterComparator.IN,
          fieldValue: {
            value: [NoteType.PROJECT_NOTE, NoteType.ASSET_PROJECT_NOTE, NoteType.CALENDAR_NOTE, NoteType.ASSET_CALENDAR_NOTE],
          },
        },
      ],
      sortBy: [
        {
          fieldName: 'filename',
          ascending: true,
        },
      ],
    }

    const fetchOptions: CloudKit.RecordFetchOptions = {
      desiredKeys: this.noteFields,
      continuationMarker: continuationMarker,
    }

    // Set throwNoResult to false to allow empty results
    const response = await this.performQuery(query, fetchOptions, false)
    const records = response.records

    if (records.length === 0) {
      return new Map<string, Note>()
    }

    const notes: Map<string, Note> = new Map([...existingResults, ...(await this.loadNotesFrom(records))])

    // Keep fetching if there are more results to load
    if (response.moreComing && response.continuationMarker) {
      return await this.fetchPrivateNotes(response.continuationMarker, notes, shouldUpdateTitles)
    } else {
      // eslint-disable-next-line no-console
      console.log('Fetched all notes', notes)
      return notes
    }
  }

  // MARK: - Helpers

  private async performQuery(query: CloudKit.Query, fetchOptions: CloudKit.RecordFetchOptions, throwNoResult = true): Promise<CloudKit.QueryResponse> {
    await this.createZoneIfNeeded()
    const database = this.getDatabase()

    // Zone is important, without we get almost no results
    fetchOptions.zoneID = this.zone

    const response = await database.performQuery(query, fetchOptions)

    if (response.hasErrors) {
      throw response.errors[0]
    }

    if (response.records.length === 0 && throwNoResult) {
      throw new Error('Note not found')
    }

    return response
  }

  // private async deleteZone() {
  //   const database = this.getDatabase()

  //   const response = await database.deleteRecordZones(this.zone)

  //   if (response.hasErrors) {
  //     throw response.errors[0]
  //   }

  //   this.zoneExists = false
  //   return response
  // }

  private async createZoneIfNeeded(zone = this.zone): Promise<void> {
    if (zone.zoneName === this.zone.zoneName && this.zoneExists) {
      return
    }
    const response = await this.fetchRecordZone(zone)

    if (response) {
      console.log('[CloudKit] zone exists', response)
      if (zone.zoneName === this.zone.zoneName) {
        this.zoneExists = true
      }
      return
    }

    console.log('[CloudKit] zone does not exist')
    await this.createRecordZone(zone)
  }

  private async fetchRecordZone(zone = this.zone): Promise<CloudKit.QueryResponse> {
    const database = this.getDatabase()

    const response = await database.fetchRecordZones(zone)
    console.log('[CloudKit] fetching zone', response.zones)
    if (response.hasErrors) {
      return
    }

    return response.zones[0]
  }

  private async createRecordZone(zone = this.zone): Promise<CloudKit.QueryResponse> {
    console.log('[CloudKit] creating zone')
    const database = this.getDatabase()

    const response = await database.saveRecordZones(zone)

    if (response.hasErrors) {
      throw response.errors[0]
    }

    if (zone.zoneName === this.zone.zoneName) {
      this.zoneExists = true
    }
    return response
  }

  private get noteFields() {
    // NOTE: Instead of querying title (which is encrypted and super slow) and meta, we can query content and get the tag, backlink and title data from there directly.
    // For this we should do only a query to update the cache from a specific modifiedDate instead of updating all of it all the time.
    return ['recordName', 'recordChangeTag', 'filename', 'noteType', 'isDir', 'content', 'fileModifiedAt', 'attachments']
  }

  private async loadNotesFrom(records: CloudKit.Record[]): Promise<Map<string, Note>> {
    const promises = records.map(async (record) => {
      return [record.recordName, await this.loadNoteFrom(record)] as [string, Note]
    })

    const notesTuples = await Promise.all(promises)
    return new Map<string, Note>(notesTuples)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async loadNoteFrom(record: any): Promise<Note> {
    // The meta field is a special value that can contain meta values such as the title of the note
    // EDIT: We don't read them from the meta field anymore, but process them from the content directly, this would make it easy to get the complete data right away
    // Otherwise we would have to save the meta data also on native and existing users would have to re-index everything, so it's easier to process it directly here.
    // const meta = readMetaField(record.fields?.meta?.value);

    let date: Date | undefined
    if (record.fields?.fileModifiedAt?.value) {
      date = new Date(record.fields?.fileModifiedAt?.value)
    }

    const isFolder = record.fields?.isDir?.value === 1
    const filename = record.fields?.filename?.value
    let title = record.fields?.title?.value

    // If it's a folder we need to use the filename as the title, since we are not using the title field in the native version and filename == title for folders, there's no content to read the title from
    if (isFolder) {
      const path = filename.split('/')
      title = path[path.length > 0 ? path.length - 1 : 0]
    }

    const note: Note = {
      content: record.fields?.content ? record.fields?.content?.value : '',
      noteType: record.fields?.noteType?.value ?? NoteType.ASSET_CALENDAR_NOTE,
      filename: filename,
      recordName: record.recordName,
      recordChangeTag: record.recordChangeTag,
      fileModifiedAt: date,
      isFolder: isFolder,
      source: SourceDatabase.CLOUDKIT,
      title: title,
      tags: [],
    }

    // If there are attachments and the content is empty, take out the first attachment and fetch it as the content.
    // This means the user has "encryption" of notes activated and this saves the content as an asset instead of text.
    // CloudKit automatically encrypts assets.
    if (record.fields?.attachments && record.fields?.attachments?.value) {
      const attachments = record.fields.attachments.value

      // Either it's specified as an asset or the there is something in the assets but no content
      if (attachments.length > 0 && (note.content.length === 0 || note.noteType == NoteType.ASSET_CALENDAR_NOTE || note.noteType == NoteType.ASSET_PROJECT_NOTE)) {
        // Download attachment
        try {
          const attachmentResponse = await fetch(attachments[0].downloadURL)
          const blob = await attachmentResponse.blob()

          // Turn blob into a string
          note.content = await new Promise<string>((resolve) => {
            const reader = new FileReader()

            reader.onloadend = () => {
              // This usually means the blob was empty
              if (reader.result == 'Bad Request\nFile not found') {
                resolve('')
              } else {
                resolve(reader.result as string)
              }
            }
            reader.readAsText(blob)
          })
        } catch (err) {
          console.error('Error fetching attachment:', err)
        }

        // Remove attachment from list
        note.encrypted = true
        attachments.shift()
      }

      note.attachments = JSON.stringify(attachments.map(({ downloadURL }: { downloadURL: string }) => downloadURL))
    }

    // If the title is empty, get it from the content, if any content is available
    if (!isFolder) {
      if (note.content.length > 0) {
        note.title = readNoteTitleFromContent(note, false)
      }

      // Set the meta field if needed
      note.tags = readTagsFromContent(note)
    }

    return note
  }
}
