import { useCallback, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { Menu, MenuItem, MenuButton, MenuHeader } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import { BlockNoteEditor, DefaultBlockSchema, Block } from '@blocknote/core'
import useNote, { useNoteConfig } from '../../hooks/useNote.ts'
import useSaveNote from '../../hooks/useSaveNote.ts'
import { googleLogout, useGoogleLogin } from '@react-oauth/google'
import { usePersistToken, useAccessToken, useSignin } from '../../providers/AccessTokenProvider.tsx'
import { NoteType } from '../../utils/syncUtils.ts'
import { SelectedDate, isSameDay, isSameWeek, selectedDateToDay, selectedDateToKey, weekToDate } from '../../providers/SelectedDateProvider.tsx'
import { eachDayOfMonth, eachWeekOfMonth } from './CalendarWrapper.tsx'
import { WeekDays } from './CalendarWrapper.tsx'
import { useUserState } from '../../providers/UserProvider.tsx'

type WindowWithEditor = Window & typeof globalThis & { editor: BlockNoteEditor | null }

// type SettingsMenuProps = { timelineDays: number; onSetTimelineDays: (_days: number) => void };

// const SettingsMenu = ({ timelineDays, onSetTimelineDays }: SettingsMenuProps) => {
//   return (
//     <Menu
//       className=""
//       menuButton={
//         <MenuButton className="p-1 hover:bg-gray-500/25">
//           <i className="h-5 w-5 fa-regular fa-gear text-sm" aria-hidden="true" />
//         </MenuButton>
//       }
//     >
//       <MenuRadioGroup value={timelineDays} onRadioChange={(e) => onSetTimelineDays(e.value)}>
//         <MenuHeader>Timeline</MenuHeader>
//         <MenuItem type="radio" value={1}>
//           1 day
//         </MenuItem>
//         <MenuItem type="radio" value={2}>
//           2 days
//         </MenuItem>
//         <MenuItem type="radio" value={3}>
//           3 days
//         </MenuItem>
//         <MenuItem type="radio" value={4}>
//           4 days
//         </MenuItem>
//         <MenuItem type="radio" value={5}>
//           5 days
//         </MenuItem>
//         <MenuItem type="radio" value={6}>
//           6 days
//         </MenuItem>
//         <MenuItem type="radio" value={7}>
//           7 days
//         </MenuItem>
//       </MenuRadioGroup>
//     </Menu>
//   );
// };

const GoogleMenu = () => {
  const { data: accessToken, isError, isFetching } = useAccessToken()

  const signin = useSignin()
  const calendarIcon = accessToken ? 'fa-calendar-circle-user' : signin.isError ? 'fa-calendar-circle-exclamation' : 'fa-calendar-circle-plus'
  const persistToken = usePersistToken()

  const login = useGoogleLogin({
    // important config for google identity service to work with our backend
    flow: 'auth-code',
    ux_mode: 'popup',
    scope: 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',
    onSuccess: ({ code }) => signin.mutate(code), // sign in was successful, let's store the refresh token and retrieve the access token
    onError: ({ error_description }) => {
      logout()
      // eslint-disable-next-line no-console
      console.error(error_description)
    },
  })

  function logout() {
    // reset the access token so the menu will show the sign in again
    persistToken(null)
    googleLogout()
  }

  // check at the start if the backend is available or still fetching and disable the calendar button
  return (
    <Menu
      className=""
      menuButton={
        <MenuButton className={`p-1 ${isError || isFetching ? 'opacity-30' : 'hover:bg-gray-500/25'}`} disabled={isError || isFetching}>
          <i className={`h-5 w-5 fa-regular text-sm ${calendarIcon}`} aria-hidden="true" />
        </MenuButton>
      }
    >
      <MenuHeader>Google Calendar</MenuHeader>
      {signin.isError && <MenuItem>{signin.error['message']}</MenuItem>}
      {accessToken?.token ? <MenuItem onClick={() => logout()}>Sign out of Google</MenuItem> : <MenuItem onClick={() => login()}>Sign in with Google</MenuItem>}
    </Menu>
  )
}

function monthToDate({ month, year }: { month: number; year: number }): Dayjs {
  return dayjs().year(year).month(month)
}

function isSameMonth({ month, year }: { month: number; year: number }, date: Dayjs): boolean {
  return date.month() === month && date.year() === year
}

export type CalendarProps = {
  selectedDate: SelectedDate
  onToday: () => void
  onChangeWeek: (_week: { week: number; year: number }) => void
  onChangeDay: (_day: Dayjs) => void
  timelineDays: number
  // onSetTimelineDays: (_days: number) => void;
}
export default function Calendar({
  selectedDate,
  onToday,
  onChangeWeek,
  onChangeDay,
  timelineDays,
}: // onSetTimelineDays,
CalendarProps) {
  const user = useUserState()
  const selectedDay = selectedDateToDay(selectedDate)
  const [currentMonth, setCurrentMonth] = useState({ month: selectedDay.month(), year: selectedDay.year() })
  const days = useMemo(() => eachDayOfMonth(currentMonth.month, currentMonth.year), [currentMonth])
  const weeks = useMemo(() => eachWeekOfMonth(currentMonth.month, currentMonth.year), [currentMonth])

  function handlePreviousMonth() {
    const newDate = dayjs().year(currentMonth.year).month(currentMonth.month).subtract(1, 'month')
    setCurrentMonth(() => ({ month: newDate.month(), year: newDate.year() }))
  }

  function handleNextMonth() {
    const newDate = dayjs().year(currentMonth.year).month(currentMonth.month).add(1, 'month')
    setCurrentMonth(() => ({ month: newDate.month(), year: newDate.year() }))
  }

  // jump to month if selectedDate changed
  useEffect(() => {
    if (!isSameMonth(currentMonth, selectedDay)) {
      setCurrentMonth(() => ({ month: selectedDay.month(), year: selectedDay.year() }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay]) // Don't add the dependency on currentMonth, otherwise it will not switch to the next month clicking the arrow buttons

  // #region <drop>
  const [dropDate, setDropDate] = useState<SelectedDate | null>(null)
  const noteKey = useCallback((): useNoteConfig | null => {
    if (!dropDate) {
      return null
    }

    return {
      noteType: selectedDate.teamspace ? NoteType.TEAM_SPACE_CALENDAR_NOTE : NoteType.CALENDAR_NOTE,
      recordName: null,
      filename: selectedDateToKey(dropDate),
      parent: selectedDate.teamspace,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDate])

  const { data: dropNote } = useNote(noteKey())
  const [dropText, setDropText] = useState<string | null>(null)
  const saveDropNote = useSaveNote(onSaveSucccess)

  function onSaveSucccess() {
    // remove dragged item

    const editor = (window as WindowWithEditor).editor
    editor?._tiptapEditor.chain().focus().deleteSelection().run()
  }

  // prepend task on drop
  useEffect(() => {
    if (dropNote && dropText) {
      saveDropNote.mutate({
        filename: dropNote.filename,
        recordName: dropNote.recordName,
        content: dropText + '\n' + dropNote.content,
        attachments: JSON.parse(dropNote.attachments?.length > 0 ? dropNote.attachments : '[]') ?? [],
        noteType: dropNote.noteType,
        parent: dropNote.parent,
        modificationDate: new Date(),
      })
      setDropDate(null)
      setDropText(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropNote, dropText])

  // The user can drop a task into a day or week and we need to remove it from the current editor and prepend it to the target note.
  function handleTaskDrop(e: React.DragEvent<HTMLButtonElement>) {
    // Get block text, which the user is dragging currently, so we know what to prepend to the target note.
    // example: <div data-id=\"b0ad8da5-e879-4a5a-87e9-a0b6a46f1e18\" class=\"_blockOuter_164rt_26\" data-node-type=\"block-outer\" data-pm-slice=\"0 0 []\"><div data-id=\"b0ad8da5-e879-4a5a-87e9-a0b6a46f1e18\" class=\"_block_164rt_26\" data-node-type=\"blockContainer\"><div class=\"_blockContent_164rt_44\" data-content-type=\"taskListItem\"><label><input type=\"checkbox\"><span></span></label><div data-flagged=\"0\" data-checked=\"false\" data-cancelled=\"false\" data-scheduled=\"false\" class=\"_inlineContent_164rt_427\">test</div></div></div></div>
    const html = e.dataTransfer.getData('text/html')
    const element = document.createElement('div')
    element.innerHTML = html

    // Loop through each child element and get the block id, then get the text of the block and it.
    // We have to loop in case the user has multiple lines selected and dragged them.
    const lines: string[] = []
    for (let i = 0; i < element.childElementCount; i++) {
      const child = element.children[i]
      const blockId: string | null = (child as HTMLElement)?.getAttribute('data-id')

      if (blockId && blockId.length > 0) {
        const block: Block<DefaultBlockSchema> | undefined = (window as WindowWithEditor).editor?.getBlock(blockId)
        if (block) {
          // TODO: Missing is copying the attachments to the target note.
          // If there's no teamspace set and we are logged into CloudKit, then we need to use the legacy attachment format.
          // Because we need isLegacyAttachment = true only for CloudKit notes and those never have a teamspace set.
          const isCloudKitSignedIn = !!user.cloudKitUserId
          const isCloudKitNote = !selectedDate.teamspace && isCloudKitSignedIn
          lines.push(BlockNoteEditor.blocksToNotePlan([block], isCloudKitNote))
        }
      }
    }

    // The text that will be prepended to the target note.
    setDropText(lines.join('\n'))
  }
  // #endregion

  return (
    <div id="calendar-view" style={{ width: 250 + timelineDays * 50 }}>
      <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
        <div className="flex justify-between items-center text-gray-900 pl-2 space-x-2 pt-2.5">
          <div className="flex flex-auto">
            <button
              type="button"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Open today (⌃T)"
              className="flex flex-none text-left text-lg text-black dark:text-white hover:bg-gray-500/25"
              onClick={onToday}
            >
              {monthToDate(currentMonth).format('MMMM YYYY')}
            </button>
          </div>

          <GoogleMenu />
          {/* <SettingsMenu timelineDays={timelineDays} onSetTimelineDays={onSetTimelineDays} /> */}
          <button type="button" className="flex flex-none items-center justify-center p-1 hover:bg-gray-500/25" onClick={handlePreviousMonth}>
            <span className="sr-only">Previous month</span>
            <i className="h-5 w-5 fa-solid fa-arrow-left text-sm" aria-hidden="true" />
          </button>
          <button type="button" className="flex flex-none items-center justify-center p-1 hover:bg-gray-500/25" onClick={handleNextMonth}>
            <span className="sr-only">Next month</span>
            <i className="h-5 w-5 fa-solid fa-arrow-right text-sm" aria-hidden="true" />
          </button>
        </div>

        <div className="flex mt-2 ml-2">
          <div className="calendar-weeks flex flex-col">
            <div className="text-xs text-orange-500 mb-1">CW</div>
            {weeks.map((week) => (
              <button
                key={`calendar-week-number-${week.week}`}
                type="button"
                onClick={() => onChangeWeek(week)}
                onDrop={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!isSameWeek(selectedDate, week)) {
                    // remove highlights
                    e.currentTarget.classList.remove('bg-orange-200/40', 'border-orange-400')
                    e.currentTarget.classList.add('border-transparent')
                    // fetch note
                    setDropDate({ ...week, active: 'week', date: weekToDate(week) })
                    handleTaskDrop(e)
                  }
                }}
                onDragEnd={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!isSameWeek(selectedDate, week)) {
                    // remove highlights
                    e.currentTarget.classList.remove('bg-orange-200/40', 'border-orange-400')
                    e.currentTarget.classList.add('border-transparent')
                  }
                }}
                onDragOver={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  if (!isSameWeek(selectedDate, week)) {
                    // add highlights
                    e.currentTarget.classList.add('bg-orange-200/40', 'border-orange-400')
                    e.currentTarget.classList.remove('border-transparent')
                  }
                }}
                onDragLeave={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (!isSameWeek(selectedDate, week)) {
                    // remove highlights
                    e.currentTarget.classList.remove('bg-orange-200/40', 'border-orange-400')
                    e.currentTarget.classList.add('border-transparent')
                  }
                }}
                onDragEnter={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                className={classnames(
                  'leading-tight text-orange-500 text-xs bg-transparent hover:bg-zinc-200 dark:hover:bg-zinc-700 focus:z-10 rounded-none m-0 p-[0.585em] border-2 border-inset border-transparent',
                  {
                    'font-semibold text-gray-600 !bg-blue-300/30': selectedDate.active === 'week' && isSameWeek(selectedDate, week),
                  }
                )}
              >
                {week.week}
              </button>
            ))}
          </div>

          <div className="calendar-days flex-auto">
            <WeekDays />

            <div className="isolate mt-1 grid grid-cols-7 bg-transparent text-sm w-full">
              {days.map((day, dayIdx) => (
                <button
                  id={`${dayIdx}: ${isSameDay(selectedDate, day)}`}
                  key={`calendar-day-view-${day}-${dayIdx}`}
                  type="button"
                  onClick={() => onChangeDay(day)}
                  className={classnames(
                    'leading-tight bg-transparent hover:bg-zinc-100 dark:hover:bg-zinc-700 focus:z-10 rounded-none m-0 p-[0.375rem] border-2 border-inset border-transparent',
                    {
                      'font-semibold': selectedDate.active === 'day' && (isSameDay(selectedDate, day) || day.isSame(dayjs(), 'day')),
                      'text-gray-900 dark:text-gray-50': !isSameDay(selectedDate, day) && isSameMonth(currentMonth, day) && !day.isSame(dayjs(), 'day'),
                      'text-gray-400': !isSameDay(selectedDate, day) && !isSameMonth(currentMonth, day) && !day.isSame(dayjs(), 'day'),
                      'text-blue-400': day.isSame(dayjs(), 'day'),
                      '!bg-blue-300/30': selectedDate.active === 'day' && isSameDay(selectedDate, day),
                    },
                    dayIdx === 0 && day.weekday() > 0 && `col-start-${day.weekday()}`
                  )}
                  onDrop={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!isSameDay(selectedDate, day)) {
                      // remove highlights
                      e.currentTarget.classList.remove('bg-orange-200/40', 'border-orange-400')
                      e.currentTarget.classList.add('border-transparent')
                      // fetch note
                      setDropDate({ active: 'day', date: day, week: day.week(), year: day.year() })
                      handleTaskDrop(e)
                    }
                  }}
                  onDragEnd={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!isSameDay(selectedDate, day)) {
                      // remove highlights
                      e.currentTarget.classList.remove('bg-orange-200/40', 'border-orange-400')
                      e.currentTarget.classList.add('border-transparent')
                    }
                  }}
                  onDragOver={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!isSameDay(selectedDate, day)) {
                      // add highlights
                      e.currentTarget.classList.add('bg-orange-200/40', 'border-orange-400')
                      e.currentTarget.classList.remove('border-transparent')
                    }
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!isSameDay(selectedDate, day)) {
                      // remove highlights
                      e.currentTarget.classList.remove('bg-orange-200/40', 'border-orange-400')
                      e.currentTarget.classList.add('border-transparent')
                    }
                  }}
                  onDragEnter={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <time dateTime={day.format('yyyy-MM-DD')} className={classnames('h-7 w-7 text-center')}>
                    {day.date()}
                  </time>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
