/* eslint-disable no-console */
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useRef } from 'react'
import CloudKit from 'tsl-apple-cloudkit'
import { useCloudKitClient } from './CloudKitClientProvider'
import { useQueryClient } from '@tanstack/react-query'
import { AuthChangeEvent, Session } from '@supabase/supabase-js'
import { useSupabaseClient } from './SupabaseClientProvider'
import { useCachedNotesQueryClient } from './CachedNotesProvider'
import { useLocalStorage } from 'usehooks-ts'
import { googleLogout } from '@react-oauth/google'
import { usePersistToken } from './AccessTokenProvider'

export type User = null | { cloudKitUserId?: string; supabaseUserId?: string; email?: string; confirmed_at?: Date }
export type UserMetaData = { cloudKitUserId?: string; hasNotes?: string }

export function mainId(user: User) {
  if (user?.cloudKitUserId) {
    return user.cloudKitUserId
  } else if (user?.supabaseUserId) {
    return user.supabaseUserId
  } else {
    return null
  }
}

// #region Context
const UserStateContext = createContext<User | undefined>(undefined)
const UserDispatchContext = createContext<Dispatch<SetStateAction<User>> | undefined>(undefined)

export function useUserState() {
  const userStateContext = useContext(UserStateContext)
  if (userStateContext === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return userStateContext
}

export const useUserDispatch = () => {
  const userDispatchContext = useContext(UserDispatchContext)
  if (userDispatchContext === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return userDispatchContext
}
// #endregion

// #region UI functions
function reload() {
  window.location.href = '/'
}

function hideSignInForm() {
  document.getElementById('sign-in-container')?.classList.add('hidden')
  document.getElementById('authContainer')?.classList.add('hidden')
}

function showSignInForm() {
  document.getElementById('sign-in-container')?.classList.remove('hidden')
  document.getElementById('sign-out-container')?.classList.add('hidden')
  document.getElementById('authContainer')?.classList.remove('hidden')
  document.getElementById('cloudkit-sign-in')?.classList.remove('hidden')

  document.getElementById('teamspace-sign-in-container')?.classList.add('hidden')
  document.getElementById('apple-sign-out-button-container')?.classList.add('hidden')
  document.getElementById('supabase-signout')?.classList.add('hidden')
}

export function showTeamspaceSignIn(visible = true) {
  if (visible) {
    document.getElementById('teamspace-sign-in-container')?.classList.remove('hidden')
    document.getElementById('authContainer')?.classList.remove('hidden')
  } else {
    document.getElementById('teamspace-sign-in-container')?.classList.add('hidden')
    document.getElementById('authContainer')?.classList.add('hidden')
  }
}

export function showTeamspaceSignOut() {
  document.getElementById('supabase-signout')?.classList.remove('hidden')
  document.getElementById('authContainer')?.classList.remove('hidden')
  document.getElementById('sign-out-container')?.classList.remove('hidden')
  document.getElementById('apple-sign-out-button-container')?.classList.add('hidden')

  // Hide the teampspace login form
  document.getElementById('teamspace-sign-in-container')?.classList.add('hidden')
}

export function showSignOut(supabaseSignedIn: boolean, cloudKitSignedIn: boolean) {
  if (supabaseSignedIn && !cloudKitSignedIn) {
    // If cloudkit is signed in, show only cloudkit logout, because it's preferred
    document.getElementById('supabase-signout')?.classList.remove('hidden')
    document.getElementById('apple-sign-out-button-container')?.classList.add('hidden')
  } else {
    document.getElementById('supabase-signout')?.classList.add('hidden')
    document.getElementById('apple-sign-out-button-container')?.classList.remove('hidden')
  }
  document.getElementById('sign-out-container')?.classList.remove('hidden')
  document.getElementById('authContainer')?.classList.remove('hidden')
  document.getElementById('teamspace-sign-in-container')?.classList.add('hidden')
}
// #endregion

/*
Login scenarios:
1. Just CloudKit
2. Just Supabase
3. First, CloudKit, then Supabase Teamspaces (sidebar has to be reloaded)
4. CloudKit + Teamspace (Supabase) - user logs out (on purpose) 
  = logout supabase and reset the sidebar
   
5. CloudKit + Teamspace (Supabase) - 24h auto logout
  = keep Supabase login, when logging in again with CloudKit 
    5a. Except it's a different user that logged in, then logout supabase and reset the sidebar
*/

export default function UserProvider({ children }) {
  const [user, setUser] = useLocalStorage<User>('user', null)
  const cloudKitClient = useCloudKitClient()
  const supabaseClient = useSupabaseClient()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()

  const persistToken = usePersistToken()

  // We need to store the user in a ref, because the user state is not updated immediately.
  // With this the functions below can access the current user state.
  const userRef = useRef(user)
  useEffect(() => {
    userRef.current = user
  }, [user])

  // Logging for debug reasons
  // console.log('extension', cachedNotesQueryClient?.getQueryData(cacheKeys.extension));

  function signInWithCloudKit(id: string) {
    setUser((prevUser) => ({ ...prevUser, cloudKitUserId: id }))
  }

  function signInWithSupabase(id: string, email: string, confirmed_at: Date) {
    setUser((prevUser) => ({ ...prevUser, supabaseUserId: id, email, confirmed_at }))
  }

  function signOutCloudKit() {
    setUser(null)
    reload()
  }

  function signOutSupabase() {
    setUser((prevUser) => {
      if (prevUser?.cloudKitUserId) {
        return { cloudKitUserId: prevUser.cloudKitUserId }
      }
      return null
    })
  }

  function signOutGoogle() {
    persistToken(null)
    googleLogout()
  }

  async function setupCloudKit(userID: string) {
    console.log('setupCloudKit, userID: ', userID)

    // If the user logged in with CloudKit and Supabase for teamspaces, he will get signed out automatically out of CloudKit every 24h.
    // We want to keep Supabase signed in in this case unless a different CloudKit user logs in. Then sign out the supabase user.
    if (userRef.current?.supabaseUserId && userRef.current?.cloudKitUserId && userRef.current?.cloudKitUserId !== userID) {
      // Log out supabase here if the stored user ID is different or null
      await supabaseClient.signOut()
    }

    signInWithCloudKit(userID)
    cloudKitClient.registerForNotifications(userRef.current, queryClient, cachedNotesQueryClient)

    // If we are logged into a teamspace, handle that here. Otherwise, if we are logged out of CloudKit, but logged into Supabase, the user will be null
    await handleSupabaseSessionUpdate(await supabaseClient.session())

    cloudKitClient.onSignedOut(async () => {
      queryClient.clear()
      cachedNotesQueryClient.clear()

      // If we are signed into a teamspace (supabase), we need to logout that too
      if (userRef.current?.supabaseUserId) {
        // Update the meta data since we are logged out now.
        const meta = await supabaseClient.getUserMetaData()

        await supabaseClient.setUserMetaData({ ...meta, cloudKitUserId: null })
        await supabaseClient.signOut()
      }

      signOutGoogle()
      signOutCloudKit()
    })
  }

  function handleSupabaseAuthEvent(event: AuthChangeEvent, session: Session) {
    if (event == 'USER_UPDATED') {
      return
    }

    if (event === 'SIGNED_OUT') {
      queryClient.clear()
      cachedNotesQueryClient.clear()

      signOutSupabase()

      // If we are not logged into CloudKit log out Google as well
      if (!userRef.current?.cloudKitUserId) {
        signOutGoogle()
      }
      return
    }

    console.log('auth state changed: ', event, userRef.current)
    handleSupabaseSessionUpdate(session)
  }

  async function handleSupabaseSessionUpdate(session: Session) {
    console.log('handleSupabaseSession', session)
    if (session && session.user && session.user.aud === 'authenticated') {
      // If the user is signed into CloudKit, set a meta data value in supabase, so we know this is meant as a teamspace login when CloudKit gets logged out

      // Fetch the meta data of the user
      const meta = await supabaseClient.getUserMetaData()

      // Update the meta data if needed
      if (meta?.cloudKitUserId !== userRef.current?.cloudKitUserId) {
        // Case 1: If the cloudKitUserId is empty but the meta data is not, we need to show the login form
        // Case 2: If the cloudKitUserId is different from the meta data (none is empty) we need to logout the supabase user

        // Case 1
        if (!userRef.current?.cloudKitUserId && meta?.cloudKitUserId) {
          console.log('CloudKit is logged out, but teamspace was logged in, show the login form')
          return
        }

        // Case 2
        if (userRef.current?.cloudKitUserId && meta?.cloudKitUserId && userRef.current?.cloudKitUserId !== meta?.cloudKitUserId) {
          console.log('CloudKit ID is different from the last login, logout teamspace')
          signOutGoogle()
          await supabaseClient.signOut()
          return
        }

        // Update the meta data, it means we are logged in with CloudKit and a teamspace
        await supabaseClient.setUserMetaData({ ...meta, cloudKitUserId: userRef.current?.cloudKitUserId })
      }

      const confirmed_at = new Date(session.user.email_confirmed_at)
      signInWithSupabase(session.user.id, session.user.email, confirmed_at)
      supabaseClient.registerForNotifications(session.user.id, queryClient, cachedNotesQueryClient)
      showTeamspaceSignIn(false)
    }
  }

  useEffect(() => {
    cloudKitClient.setUpAuth(
      async (userIdentity: CloudKit.UserIdentity | null) => {
        if (userIdentity) {
          // The user is authenticated
          await setupCloudKit(userIdentity.userRecordName)
        } else {
          // Not authenticated
          cloudKitClient.onSignedIn((userIdentity: CloudKit.UserIdentity) => {
            setupCloudKit(userIdentity.userRecordName)
          })
        }
        supabaseClient.setUpAuth(handleSupabaseAuthEvent)
      },
      async (error: CloudKit.CKError | undefined) => {
        // eslint-disable-next-line no-console
        console.error('Error setting up auth:', error)

        // Logout supabase (teamspaces) as well, if we ran into a cloudkit error
        if (userRef.current?.supabaseUserId) {
          await supabaseClient.signOut()
        }
      }
    )

    function handleFocus() {
      // Attempt to register to notifications again, in case we lost the connection
      supabaseClient?.registerForNotifications(userRef.current?.supabaseUserId, queryClient, cachedNotesQueryClient)
      cloudKitClient.registerForNotifications(userRef.current, queryClient, cachedNotesQueryClient)
    }

    // sometimes we loose the subscription, so we need to re-register
    window.addEventListener('focus', handleFocus)

    // Clean up function to remove the event listener
    return () => {
      window.removeEventListener('focus', handleFocus)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Only run once on mount

  useEffect(() => {
    if (user === null) {
      showSignInForm()
      return
    }

    hideSignInForm()
    return
  }, [user])

  return (
    <UserStateContext.Provider value={user}>
      <UserDispatchContext.Provider value={setUser}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}
