import dayjs from 'dayjs'
import { CalendarEvent } from '../../CalendarEvent'
import { PlaceholderEvent } from '../types'

export default function calculateOffsetAndHeight(event: CalendarEvent | PlaceholderEvent, segmentHeight: number) {
  const remainingMinutesTop = event.startDate.minute() % 15
  const offsetPxTop = (remainingMinutesTop * segmentHeight) / 15

  let endDate = event.endDate
  if (endDate.isAfter(dayjs(endDate).endOf('day'))) {
    endDate = dayjs(endDate).endOf('day')
  }

  const eventHeight = Math.max((endDate.diff(event.startDate, 'minute') * segmentHeight + offsetPxTop) / 15, segmentHeight / 2 + 2)

  return { eventHeight, offsetPxTop }
}
