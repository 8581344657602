import { useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { useUserState } from '../providers/UserProvider'
import { TeamspaceMember } from './useListTeamspaceMembers'
import { useSafeMutation } from './useSafeMutation'
import { teamKeys } from '../utils/queryKeyFactory'

// eslint-disable-next-line no-unused-vars
export default function useInviteMember(successCallback?: (data: TeamspaceMember | undefined) => void) {
  const sb = useSupabaseClient()
  const user = useUserState()

  const queryClient = useQueryClient()

  return useSafeMutation({
    mutationFn: ({ teamspaceID, email }: { teamspaceID: string; email: string }) => {
      if (!teamspaceID || !email || email.length < 3) {
        return Promise.resolve(undefined)
      }

      // Verify if the email is valid
      const emailRegex = /\S+@\S+\.\S+/
      if (!emailRegex.test(email)) {
        return Promise.resolve(undefined)
      }

      // eslint-disable-next-line no-console
      console.debug('[useInviteMember] inviting member to teamspace', email, teamspaceID)

      if (user.supabaseUserId) {
        return sb.inviteTeamspaceMember(teamspaceID, email)
      }

      throw new Error('Not signed in')
    },
    onSuccess: (data, { teamspaceID, email }) => {
      // eslint-disable-next-line no-console
      console.log('[useInviteMember] onSuccess')
      if (data && teamspaceID && email) {
        const teamspaceMembers = queryClient.getQueryData<[TeamspaceMember]>(teamKeys.members(teamspaceID)) ?? []
        queryClient.setQueryData(teamKeys.members(teamspaceID), [...teamspaceMembers, { email: email.toLowerCase(), role: 'pending' }])
      }
      successCallback?.(data)
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('[useInviteMember] onError ' + error)
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}
