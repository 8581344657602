import { useEffect, useMemo, useRef } from 'react'
import { useSidebarProvider } from '../../providers/SidebarProvider'
import { BlockNoteEditor, DefaultBlockSchema, PartialBlock } from '@blocknote/core'
import { Note, NoteType, Timeframe, filenameToDate, isCalendarNote } from '../../utils/syncUtils'
import { useDebounce } from 'usehooks-ts'
import { Dayjs } from 'dayjs'
import SectionComponent from './SectionComponent'
import CalendarSectionComponent from './CalendarSectionComponent'

function NoMatches() {
  return (
    <div className="flex flex-col gap-4 p-3 items-center justify-center" style={{ height: 'calc(100vh - 60px' }}>
      <i className="far fa-search text-7xl text-zinc-700"></i>
      <h2 className="font-normal text-xl">No Matches</h2>
      <p style={{ marginBottom: '25vh' }}>Search for anything inside your notes.</p>
    </div>
  )
}

type Section = NotesSection | CalendarSection

export type NotesSection = {
  title: string
  type: 'notes'
  teamSpaceId?: string
  noteResults: NoteResult[]
}

type NoteResult = {
  recordName: string
  title: string
  blocks: PartialBlock<DefaultBlockSchema>[]
}

export type CalendarSection = {
  title: string
  type: 'calendar'
  teamSpaceId?: string
  months: Month[]
}

type Month = {
  month: number
  year: number
  calendarResults: CalendarResult[]
}

type CalendarResult = {
  recordName: string
  date: Dayjs
  timeframe: Timeframe
  blocks: PartialBlock<DefaultBlockSchema>[]
}

function getTeamSpace(note: Note, teamNotesMap: Map<string, Note>): Note {
  const parentNote = teamNotesMap.get(note.parent)
  if (parentNote?.noteType === NoteType.TEAM_SPACE) {
    return parentNote
  } else {
    return getTeamSpace(parentNote, teamNotesMap)
  }
}

function searchContent(query: string, content: string): PartialBlock<DefaultBlockSchema>[] {
  const blocks: PartialBlock<DefaultBlockSchema>[] = []

  if (query.length <= 1 || !content) {
    return blocks
  }

  const lines = content.split('\n')
  for (const line of lines) {
    // case insensitive search
    if (line.toLowerCase().includes(query.toLowerCase())) {
      const block = BlockNoteEditor.notePlanToBlocks(line, '')[0]
      blocks.push(block)
    }
  }

  return blocks
}

function searchNotes(query: string, privateNotesMap: Map<string, Note>, teamNotesMap: Map<string, Note>) {
  const results: Section[] = []

  let resultsCount = 0
  const resultsLimit = 1000
  let blocksCount = 0
  const blocksLimit = 1000

  const teamCalendarSections: CalendarSection[] = []
  const teamSections: NotesSection[] = []
  if (teamNotesMap) {
    try {
      teamNotesMap.forEach((note) => {
        if (resultsCount > resultsLimit || blocksCount > blocksLimit) {
          throw new Error('Too many results')
        }

        if (note.noteType === NoteType.TEAM_SPACE) {
          return
        }

        const teamSpace: Note = getTeamSpace(note, teamNotesMap)

        if (note.noteType === NoteType.TEAM_SPACE_CALENDAR_NOTE) {
          // prepare data
          const { date, timeframe } = filenameToDate(note.filename)
          const calendarResult: CalendarResult = {
            recordName: note.recordName,
            date,
            timeframe,
            blocks: searchContent(query, note.content),
          }

          // add to results
          if (calendarResult.blocks.length > 0) {
            const section = teamCalendarSections.find((section) => section.teamSpaceId === teamSpace.recordName)
            if (section) {
              const month = section.months?.find((month) => month?.month === date.month() && month?.year === date.year())
              if (month) {
                month.calendarResults.push(calendarResult)
              } else {
                section.months.push({ month: date.month(), year: date.year(), calendarResults: [calendarResult] })
              }
            } else {
              teamCalendarSections.push({
                type: 'calendar',
                title: teamSpace.title + ' Calendar',
                teamSpaceId: teamSpace.recordName,
                months: [{ month: date.month(), year: date.year(), calendarResults: [calendarResult] }],
              })
            }
            resultsCount += 1
            blocksCount += calendarResult.blocks.length
          }
        } else {
          // prepare data
          const noteResult: NoteResult = {
            recordName: note.recordName,
            title: note.title,
            blocks: searchContent(query, note.content),
          }

          // add to results
          if (noteResult.blocks.length > 0) {
            const section = teamSections.find((section) => section.teamSpaceId === teamSpace.recordName)
            if (section) {
              section.noteResults.push(noteResult)
            } else {
              teamSections.push({
                type: 'notes',
                title: teamSpace.title,
                teamSpaceId: teamSpace.recordName,
                noteResults: [noteResult],
              })
            }
            resultsCount += 1
            blocksCount += noteResult.blocks.length
          }
        }
      })
    } catch (e) {
      // do nothing
    }

    if (teamCalendarSections.length > 0) {
      teamCalendarSections.forEach((section) => {
        // descending order
        section.months.sort((a, b) => b.year - a.year || b.month - a.month)
        section.months.forEach((month) =>
          month.calendarResults.sort((a: CalendarResult, b: CalendarResult) => {
            // put week notes at the end of the week
            const correctedA = a.timeframe === 'week' ? a.date.add(6, 'day') : a.date
            const correctedB = b.timeframe === 'week' ? b.date.add(6, 'day') : b.date
            return correctedB.diff(correctedA)
          })
        )
      })
      results.push(...teamCalendarSections)
    }
    if (teamSections.length > 0) {
      // ascending order
      teamSections.forEach((section) => section.noteResults.sort((a, b) => a.title.localeCompare(b.title)))
      results.push(...teamSections)
    }

    // Sort in ascending order, so notes and calendar sections of the same team space are grouped together
    results.sort((a, b) => a.title.localeCompare(b.title))
  }

  const privateCalendarSection: CalendarSection = { type: 'calendar', title: teamSections.length > 0 ? 'Private calendar' : 'Calendar', months: [] }
  const privateSection: NotesSection = { type: 'notes', title: teamSections.length > 0 ? 'Private notes' : 'Notes', noteResults: [] }
  if (privateNotesMap) {
    try {
      privateNotesMap.forEach((note) => {
        if (resultsCount > resultsLimit || blocksCount > blocksLimit) {
          throw new Error('Too many results')
        }

        if (isCalendarNote(note.noteType)) {
          // prepare data
          const { date, timeframe } = filenameToDate(note.filename)
          const calendarResult: CalendarResult = {
            recordName: note.recordName,
            date,
            timeframe,
            blocks: searchContent(query, note.content),
          }

          // add to results
          if (date && calendarResult.blocks.length > 0) {
            const month = privateCalendarSection.months.find((month) => month.month === date.month() && month.year === date.year())
            if (month) {
              month.calendarResults.push(calendarResult)
            } else {
              privateCalendarSection.months.push({ month: date.month(), year: date.year(), calendarResults: [calendarResult] })
            }
            resultsCount += 1
            blocksCount += calendarResult.blocks.length
          }
        } else {
          // prepare data
          const noteResult: NoteResult = {
            recordName: note.recordName,
            title: note.title,
            blocks: searchContent(query, note.content),
          }

          // add to results
          if (noteResult.blocks.length > 0) {
            privateSection.noteResults.push(noteResult)
            resultsCount += 1
            blocksCount += noteResult.blocks.length
          }
        }
      })
    } catch (e) {
      // do nothing
    }

    if (privateCalendarSection.months.length > 0) {
      // descending order
      privateCalendarSection.months.sort((a, b) => b.year - a.year || b.month - a.month)
      privateCalendarSection.months.forEach((month) =>
        month.calendarResults.sort((a: CalendarResult, b: CalendarResult) => {
          // put week notes at the end of the week
          const correctedA = a.timeframe === 'week' ? a.date.add(6, 'day') : a.date
          const correctedB = b.timeframe === 'week' ? b.date.add(6, 'day') : b.date
          return correctedB.diff(correctedA)
        })
      )
      results.push(privateCalendarSection)
    }

    if (privateSection.noteResults.length > 0) {
      // ascending order
      privateSection.noteResults.sort((a, b) => a.title.localeCompare(b.title))
      results.push(privateSection)
    }
  }

  return results
}

export default function Search({ query, onSearch }: { query: string; onSearch: (_query: string) => void }) {
  const debouncedQuery = useDebounce(query, 300)
  const { privateNotesMap, teamNotesMap, handleRevealNote } = useSidebarProvider()
  const results: Section[] = useMemo(() => searchNotes(debouncedQuery, privateNotesMap, teamNotesMap), [debouncedQuery, privateNotesMap, teamNotesMap])
  const inputRef = useRef<HTMLInputElement>(null)

  // scroll to top when query changes
  useEffect(() => {
    const searchResults = document.querySelector('.search-results')
    searchResults?.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior })
  }, [debouncedQuery])

  // add command+a to select all text in input
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'a' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        inputRef.current?.select()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  })

  return (
    <div className="note-body flex flex-col">
      <div className="flex flex-row items-center gap-10 w-full p-3">
        <div className="flex flex-row items-center">
          <i className="far fa-search text-xl mr-2 opacity-70"></i>
          <h1 className="text-xl">Search</h1>
        </div>
        <div className="relative flex-grow">
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <i className="fas fa-search"></i>
          </span>
          <input
            ref={inputRef}
            type="text"
            className="form-input block w-full pl-10 py-1 px-4 leading-5 rounded-md dark:bg-zinc-800 border-2 dark:border-zinc-700"
            placeholder="Search"
            value={query}
            onChange={(e) => onSearch(e.target.value)}
            autoFocus={true}
          />
          {query.length > 0 && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-3">
              <i
                className="fas fa-times-circle text-lg cursor-pointer"
                onClick={() => {
                  onSearch('')
                  // focus on input
                  inputRef.current?.focus()
                }}
              ></i>
            </span>
          )}
        </div>
      </div>
      <div className="search-results overflow-y-scroll overflow-x-hidden" style={{ maxHeight: 'calc(100vh - 60px)' }}>
        {results.length > 0 ? (
          results.map((section, index) => {
            if (section.type === 'notes') {
              return <SectionComponent key={index} section={section} index={index} handleRevealNote={handleRevealNote} keyword={debouncedQuery} />
            }
            if (section.type === 'calendar') {
              return <CalendarSectionComponent key={index} section={section} index={index} handleRevealNote={handleRevealNote} keyword={debouncedQuery} />
            }
          })
        ) : (
          <NoMatches />
        )}
      </div>
    </div>
  )
}
