import { useQueryClient } from '@tanstack/react-query'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import { Note, NoteType, getSupabaseFileExtension, isFolder, isTeamspaceNote } from '../utils/syncUtils'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { useUserState } from '../providers/UserProvider'
import { updateNote, updateNoteReturnFallback, useCachedNotesQueryClient, useNotesExtension } from '../providers/CachedNotesProvider'
import { cacheKeys, noteQueryKey } from '../utils/queryKeyFactory'
import { useSafeMutation } from './useSafeMutation'

export type CreateOptions = {
  recordName: string
  noteType: NoteType
  parent: string | null
  filename?: string
  isDir?: boolean
  content?: string
  title?: string
}

function createOptionsToDraft({ recordName, noteType, parent, filename, isDir, content = '# ', title }: CreateOptions): Note {
  const correctedParent: string = parent === 'notes' || parent === 'teamspaces' ? null : parent
  return {
    recordName: recordName,
    parent: correctedParent,
    noteType: noteType,
    isFolder: isDir,
    filename: filename,
    title: title ?? filename,
    content: isDir ? '' : content,
  }
}

function filenameExists(privateNotes: Map<string, Note>, filename: string, recordName: string): boolean {
  return Array.from(privateNotes.values()).some((note) => note.filename === filename && note.recordName !== recordName)
}

function generateFilename(privateNotes: Map<string, Note>, baseFilename: string, recordName: string, extension: string, number: number): string {
  // Construct the filename with the current number
  const newFilename = number > 0 ? `${baseFilename} ${number}${extension}` : `${baseFilename}${extension}`

  // Check if this filename exists
  if (filenameExists(privateNotes, newFilename, recordName)) {
    // If it does, increment the number and try again
    return generateFilename(privateNotes, baseFilename, recordName, extension, number + 1)
  }

  return newFilename
}

function augmentNoteForCloudKit(draft: Note, filename: string, ext: string, privateNotes: Map<string, Note>) {
  // make filename unique because CloudKit doesn't allow duplicate filenames
  draft.filename = draft.isFolder ? filename : `${filename}.${ext}`

  if (draft.parent) {
    const parentFilename = privateNotes.get(draft.parent)?.filename
    if (parentFilename) {
      // edge case: sometimes the parent filename contains the filename, so we need to remove it
      const folderName = parentFilename.endsWith(`.${ext}`) ? parentFilename.substring(0, parentFilename.lastIndexOf('/')) : parentFilename
      draft.filename = `${folderName}/${draft.filename}`
    }
  }

  // Regex to extract base filename and extension
  const regex = /^(.*?)(\.[^.]+)?$/
  const matches = draft.filename.match(regex)
  const baseFilename = matches[1]
  const extension = matches[2] || ''

  draft.filename = generateFilename(privateNotes, baseFilename, draft.recordName, extension, 0)
}

export default function useCreateNote(successCallback: (_note: Note) => void) {
  const ck = useCloudKitClient()
  const sb = useSupabaseClient()
  const user = useUserState()
  const privateUserId = user?.cloudKitUserId || user?.supabaseUserId
  const { data: ext } = useNotesExtension(user)
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()

  return useSafeMutation<Note, Error, CreateOptions, Map<string, Note>>({
    mutationFn: ({ recordName, noteType, parent = null, filename = 'Untitled', isDir: isDir = false, content, title }: CreateOptions) => {
      const draft: Note = createOptionsToDraft({ recordName, noteType, parent, filename, isDir, content, title })
      // eslint-disable-next-line no-console
      console.debug('[useCreateNote] creating', draft)

      // Teamspace notes are managed by supabase
      if (user.cloudKitUserId && !isTeamspaceNote(noteType)) {
        const privateNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.private(privateUserId))
        augmentNoteForCloudKit(draft, filename, ext, privateNotes)
        return ck.createNote(user.cloudKitUserId, draft)
      }

      if (user.supabaseUserId) {
        draft.filename = draft.isFolder ? filename : `${filename}.${getSupabaseFileExtension()}`
        return sb.createNote(user.supabaseUserId, draft)
      }

      throw new Error('Not signed in')
    },
    onMutate: ({ recordName, noteType, parent = null, filename = 'Untitled', isDir: isDir = false, content, title }: CreateOptions) => {
      // eslint-disable-next-line no-console
      console.debug('[useCreateNote] onMutate', recordName, noteType, parent, filename, isDir, title)

      const newNote: Note = createOptionsToDraft({ recordName, noteType, parent, filename, isDir, content, title })
      if (user.cloudKitUserId && !isTeamspaceNote(newNote.noteType)) {
        const privateNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.private(privateUserId))
        augmentNoteForCloudKit(newNote, filename, ext, privateNotes)
      }

      const previousNotes = updateNoteReturnFallback(cachedNotesQueryClient, privateUserId, user.supabaseUserId, newNote)

      if (!isFolder(newNote)) {
        queryClient.setQueryData(noteQueryKey(newNote), () => newNote)
        // Only jump right away to the new note if it's a Supabase note
        // CloudKit doesn't have a recordChangeTag yet and an attempt to save the note will fail / create another unwanted note
        if (!user.cloudKitUserId || isTeamspaceNote(newNote.noteType)) {
          successCallback(newNote)
        }
      }

      // return a context object with the snapshotted value
      return previousNotes
    },
    onError: (_error, variables: CreateOptions, context: Map<string, Note>) => {
      if (isTeamspaceNote(variables.noteType)) {
        cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(user.supabaseUserId), context)
      } else {
        cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), context)
      }
    },
    onSuccess: (newNote: Note) => {
      // eslint-disable-next-line no-console
      console.log('[useCreateNote] onSuccess', newNote)

      // update the cache
      updateNote(cachedNotesQueryClient, privateUserId, user.supabaseUserId, newNote)

      if (!isFolder(newNote)) {
        // set the new note in the cache
        queryClient.setQueryData(noteQueryKey(newNote), () => newNote)
      }

      // We always need to return the callback
      successCallback(newNote)
    },
  })
}
