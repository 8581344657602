/* eslint-disable no-console */
import { SupabaseClient } from '../../api/SupabaseClient'
import { Note, NoteType, SourceDatabase, isCalendarNote } from '../../utils/syncUtils'
import JSZip from 'jszip'
import { User, showTeamspaceSignIn, showTeamspaceSignOut } from '../../providers/UserProvider'
import { UseMutateFunction } from '@tanstack/react-query'
import { CreateOptions } from '../../hooks/useCreateNote'
import { Menu, MenuButton, MenuHeader, MenuItem } from '@szhsin/react-menu'
import { v4 as uuid } from 'uuid'
import { SelectedDate, selectedDateToKey } from '../../providers/SelectedDateProvider'
import { useNoteConfig } from '../../hooks/useNote'

function removeExtension(filename: string, isFolder: boolean): string {
  if (isFolder) {
    return filename
  }

  const lastDotIndex = filename.lastIndexOf('.')
  if (lastDotIndex === -1) return filename // no extension found
  return filename.substring(0, lastDotIndex)
}

export function notesToNotesTree(notes: Map<string, Note>): Note[] {
  // Given a map of notes, return a tree of notes
  // A folder is indicated by isFolder = true
  const tree: Note[] = []
  // console.log('[SidebarEntry] creating a file tree...', notes);

  if (notes === undefined || notes.size === 0) {
    console.log('[SidebarEntry] No notes found, return')
    return tree
  }

  if (!(notes instanceof Map)) {
    console.log('[SidebarEntry] Notes are not a map, return')
    return tree
  }

  // Check what the sourceDatabase is
  let sourceDatabase = notes.values().next().value.source

  // Check for parent as fallback.
  if (!sourceDatabase) {
    // Test if any of the notes has parent defined as fallback indicator
    notes.forEach((note) => {
      if (note.parent) {
        sourceDatabase = SourceDatabase.SUPABASE
        return false
      }
    })
  }

  if (sourceDatabase == SourceDatabase.SUPABASE) {
    // Case: Supabase
    // populate notes with additional info
    const extendedNotes = Array.from(notes.values())
      .filter((note) => [NoteType.ASSET_PROJECT_NOTE, NoteType.PROJECT_NOTE, NoteType.TEAM_SPACE, NoteType.TEAM_SPACE_NOTE].includes(note.noteType))
      .map((note) => {
        return { ...note, noteType: note.noteType ?? NoteType.PROJECT_NOTE, title: note.title ?? removeExtension(note.filename, note.isFolder), children: [] }
      })

    // recursively build the tree by nesting the notes with a parent attribute into the parent
    const buildTree = (parent: Note) => {
      const children = extendedNotes.filter((note) => note.parent === parent.recordName)
      parent.children = children
      children.forEach((child) => {
        buildTree(child)
      })
    }
    const rootNotes = extendedNotes.filter((note) => note.parent === null || note.parent === undefined)

    rootNotes.forEach((note) => {
      buildTree(note)
    })
    tree.push(...rootNotes)
  } else {
    // console.log('[SidebarEntry] The data is from CloudKit');

    // If this is in any case not sorted, we get the wrong results, the algo depends on the array to be sorted
    const sortedNotes = Array.from(notes.values())
      .filter((note) => [NoteType.ASSET_PROJECT_NOTE, NoteType.PROJECT_NOTE].includes(note.noteType))
      .sort((a, b) => a.filename.localeCompare(b.filename))

    // Case: CloudKit
    // The filename indicates the level of the note in the tree. Each level is separated by a slash
    // If the parent folder of the note does not exist, don't add it to the tree
    sortedNotes.forEach((note) => {
      const filename = note.filename
      let path = ''

      const parts = filename.split('/')
      let parent = tree

      for (let i = 0; i < parts.length; i++) {
        const part = parts[i]

        // Reassemble the path as filename, we need this to search the existing list and avoid duplicates
        path += (i > 0 ? '/' : '') + part

        const existing = parent.find((item) => item.filename === path)
        if (existing) {
          if (note.isFolder) {
            existing.isFolder = true
          }
          parent = existing.children
        } else {
          // Anything but the last part must be a folder (unless it's just one part)
          // That's because in a path only the last part can be the actual filename (not a folder)
          const isFolder = note.isFolder || i < parts.length - 1

          const item: Note = {
            filename: path,
            noteType: NoteType.PROJECT_NOTE,
            title: note.title ?? removeExtension(part, isFolder),
            recordName: note.recordName,
            children: [],
            isFolder: isFolder,
            source: note.source,
          }

          parent.push(item)
          parent = item.children
        }
      }
    })

    // move items where isFolder == false and has children to the @Trash folder
    const trashFolder = tree.find((item) => item.filename === '@Trash')
    if (trashFolder) {
      const trashItems = tree.filter((item) => !item.isFolder && item.children.length > 0)
      trashItems.forEach((item) => {
        trashFolder.children.push(item)
        // remove the item from the tree
        const index = tree.findIndex((i) => i === item)
        if (index !== -1) {
          tree.splice(index, 1)
        }
      })
    }
  }
  // filter out folders that starts with @ at the top level
  const filteredTree = tree.filter((item) => !item.isFolder || (item.isFolder && !item.filename.startsWith('@')))
  function sortNotes(a: Note, b: Note) {
    if (a.isFolder && !b.isFolder) {
      return -1
    } else if (!a.isFolder && b.isFolder) {
      return 1
    } else {
      // Sort emojis down, like in the native app
      const regexEmoji = /^\p{Extended_Pictographic}/u
      const aStartsWithEmoji = regexEmoji.test(a.title)
      const bStartsWithEmoji = regexEmoji.test(b.title)
      if (aStartsWithEmoji && !bStartsWithEmoji) {
        return 1
      } else if (!aStartsWithEmoji && bStartsWithEmoji) {
        return -1
      }
      return a.title.localeCompare(b.title)
    }
  }

  // sort top level
  filteredTree.sort(sortNotes)

  // traverse the tree and sort the children
  const traverseAndSort = (node: Note) => {
    node.children.sort(sortNotes)
    node.children.forEach(traverseAndSort)
  }
  filteredTree.forEach(traverseAndSort)

  // console.log('[SidebarEntry] file tree loaded', filteredTree);
  return filteredTree
}
// Converts a note to a sidebar item

export function noteToSidebarEntry(item: Note, userID: string): SidebarEntry {
  const sidebarEntry: SidebarEntry = {
    noteType: item.noteType,
    recordName: item.recordName,
    filename: item.filename,
    title: item.title,
    icon: 'fal fa-file-lines',
    color: 'text-gray-600 dark:text-gray-400',
    parent: item.parent,
    hasAdminRights: item.owner == userID || (item.admins && item.admins.includes(userID)) || item.source == SourceDatabase.CLOUDKIT,
    isOwner: item.owner == userID || item.source == SourceDatabase.CLOUDKIT,
    source: item.source,
  }

  if (item.isFolder) {
    sidebarEntry.icon = 'far fa-folder'
    sidebarEntry.color = 'text-blue-500'
    sidebarEntry['children'] = []
  }

  if (item.noteType == NoteType.TEAM_SPACE) {
    sidebarEntry.icon = 'far fa-screen-users'
    sidebarEntry.color = 'text-green-500'
    sidebarEntry.childNoteType = NoteType.TEAM_SPACE_NOTE
  }

  if (item.children && item.children.length > 0) {
    sidebarEntry['children'] = item.children.map((child) => noteToSidebarEntry(child, userID))
  }

  return sidebarEntry
}

export async function exportNoteChildren(folder: Note, notes: Map<string, Note>, supabase: SupabaseClient) {
  const zip = new JSZip()
  const notesMap = new Map<string, Note[]>()
  const calendarNotesFolder = zip.folder('Calendar Notes')
  const startTime = performance.now() // Start measuring time

  // Recursively search for children using the parent ID
  function findChildren(parentNote: Note, allNotes: Note[]): Note[] {
    return allNotes.filter((note) => note.parent === parentNote.recordName)
  }

  // Populate the notesMap with the children of 'folder' and their descendants
  function populateNotesMap(parentNote: Note, allNotes: Note[]) {
    const children = findChildren(parentNote, allNotes)
    notesMap.set(parentNote.recordName, children)
    children.forEach((childNote) => {
      populateNotesMap(childNote, allNotes)
    })
  }

  // Initialize the map with the folder's children
  populateNotesMap(folder, Array.from(notes.values()))

  async function downloadAndAddAttachments(child: Note, attachmentsZip: JSZip, attachmentFolderName: string) {
    await supabase.updateAttachmentURLs(child)
    if (!child.attachments || child.attachments.length == 0) {
      return
    }

    const attachments = JSON.parse(child.attachments)
    const downloadPromises = attachments.map(async (attachment: string) => {
      const asset = JSON.parse(attachment)
      const filename = asset.filename
      const url = asset.url
      if (url) {
        try {
          const response = await fetch(url)
          if (!response.ok) {
            throw new Error(`Failed to download asset. Status: ${response.status}`)
          }
          const blob = await response.blob()
          attachmentsZip.file(filename, blob)
          // Replace markdown links in the note content
          const markdownLinkRegex = new RegExp(`(\\!\\[.*?\\]\\()(${filename})(\\))`, 'g')
          child.content = child.content.replace(markdownLinkRegex, `$1${attachmentFolderName}/$2$3`)
        } catch (error) {
          console.error('Error downloading asset:', error)
        }
      }
    })
    await Promise.all(downloadPromises)
  }

  async function addChildrenToZip(parentZip: JSZip, parentRecordName: string) {
    const children = notesMap.get(parentRecordName) || []
    for (const child of children) {
      if (child.isFolder) {
        const folderZip = parentZip.folder(child.title)
        await addChildrenToZip(folderZip, child.recordName)
      } else {
        // Sanitize title to be used in filenames
        const sanitizedTitle = child.title ? child.title.replace(/[#/\\\\?%*|"<>:@#()\\]/g, '') : ''
        if (child.attachments && child.attachments.length > 0) {
          const attachmentsFolderName = sanitizedTitle ? `${sanitizedTitle}_attachments` : `${child.filename.split('.')[0]}_attachments`
          const attachmentsZip = sanitizedTitle ? parentZip.folder(attachmentsFolderName) : calendarNotesFolder.folder(attachmentsFolderName)
          await downloadAndAddAttachments(child, attachmentsZip, attachmentsFolderName)
        }

        // Use sanitized title if available, otherwise use filename and place in "Calendar Notes" folder
        if (!isCalendarNote(child.noteType) && sanitizedTitle) {
          parentZip.file(`${sanitizedTitle}.md`, child.content || '')
        } else {
          calendarNotesFolder.file(child.filename, child.content || '')
        }
      }
    }
  }

  // Start with the folder's children
  await addChildrenToZip(zip, folder.recordName)

  const endTime = performance.now() // End measuring time
  console.log(`Downloaded all attachments in ${(endTime - startTime).toFixed(2)}ms`)

  zip.generateAsync({ type: 'blob' }).then((content) => {
    const url = window.URL.createObjectURL(content)
    const link = document.createElement('a')
    link.href = url
    link.download = `${folder.title}.zip`
    link.click()
  })
}

export type SidebarEntry = {
  recordName: string
  title: string
  filename?: string
  icon?: string
  color?: string
  header?: boolean
  children?: SidebarEntry[]
  noteType?: NoteType
  childNoteType?: NoteType
  disabled?: boolean
  parent?: string
  selectable?: boolean
  menu?: React.ReactNode
  isLoading?: boolean
  isHidden?: boolean
  hideContext?: boolean
  hasAdminRights?: boolean
  isOwner?: boolean
  action?: () => boolean
  shortcut?: string
  source?: SourceDatabase
}

function createDailyNote(parent: string) {
  return {
    filename: 'Daily',
    recordName: 'daily_' + parent,
    color: 'text-orange-600 dark:text-orange-400',
    title: 'Daily',
    icon: 'fal fa-calendar-star',
    noteType: NoteType.TEAM_SPACE_CALENDAR_NOTE,
    parent: parent,
    hideContext: true,
  }
}

function createWeeklyNote(parent: string) {
  return {
    filename: 'Weekly',
    recordName: 'weekly_' + parent,
    color: 'text-fuchsia-600 dark:text-fuchsia-500',
    title: 'Weekly',
    icon: 'fal fa-calendar-week',
    noteType: NoteType.TEAM_SPACE_CALENDAR_NOTE,
    parent: parent,
    hideContext: true,
  }
}

export function createSidebarEntries(
  user: User,
  isLoadingPrivate: boolean,
  privateNotes: Map<string, Note>,
  isLoadingTeam: boolean,
  teamNotes: Map<string, Note>,
  createNote: UseMutateFunction<Note, Error, CreateOptions, Map<string, Note>>,
  showCommandBar: (_visible: boolean, _search: string) => void,
  openManageSubscription: () => void
): SidebarEntry[] {
  if (user === null || user === undefined) {
    return [{ recordName: 'noteplan', title: 'NotePlan', color: 'text-gray-400', header: true, isLoading: true, children: [], hideContext: true }]
  }

  const isTeamspace = !!user.supabaseUserId && teamNotes && teamNotes.size > 0

  const noteplan: SidebarEntry = {
    recordName: 'noteplan',
    title: 'NotePlan',
    color: 'text-gray-400',
    header: true,
    hideContext: true,
    children: [
      // SETTINGS
      // Currently the settings are showing a teamspace (supabase) login for cloudkit users
      {
        recordName: 'settings',
        color: 'text-gray-600 dark:text-gray-400',
        title: '',
        icon: '',
        selectable: false,
        menu: (
          <Menu
            align="start"
            boundingBoxPadding="30px 0px 0px 0px"
            menuButton={
              <MenuButton className={`p-0 text-left w-full align-middle relative`}>
                <i className={`h-1 w-4 mr-2 ml-1 fa-regular text-sm far fa-gear`} aria-hidden="true" />
                Settings
              </MenuButton>
            }
          >
            {/* Show this only if we are logged into cloudkit */}
            {user.cloudKitUserId && (
              <>
                {/* Show the email as header if available */}
                {user.supabaseUserId && <MenuHeader className="pl-6 pr-2">{user.email}</MenuHeader>}

                {/* Team space (supabase) login/logout button */}
                <MenuItem disabled={false} key="supabase" onClick={() => (user.supabaseUserId ? showTeamspaceSignOut() : showTeamspaceSignIn())}>
                  {user.supabaseUserId ? 'Logout from Teamspaces' : 'Login to Teamspaces'}
                </MenuItem>
              </>
            )}

            {user && (
              <MenuItem disabled={false} key="supabase" onClick={() => openManageSubscription()}>
                Manage Subscription
              </MenuItem>
            )}
          </Menu>
        ),
      },
      {
        recordName: 'search',
        color: 'text-gray-600 dark:text-gray-400',
        title: 'Search',
        icon: 'far fa-search',
        hideContext: true,
        shortcut: '⌘+⇧+F',
        action: () => {
          return false
        },
      },
      {
        recordName: 'commandbar',
        color: 'text-gray-600 dark:text-gray-400',
        title: 'Command bar',
        icon: 'far fa-file-magnifying-glass',
        hideContext: true,
        selectable: false,
        action: () => {
          showCommandBar(true, '')
          return true
        },
        shortcut: '⌘+J',
      },
      {
        recordName: 'create',
        color: 'text-gray-600 dark:text-gray-400',
        title: '',
        icon: '',
        selectable: false,
        menu: (
          <Menu
            align="start"
            boundingBoxPadding="30px 0px 0px 0px"
            menuButton={
              <MenuButton className={`p-0 text-left w-full align-middle relative`}>
                <i className={`h-1 w-4 mr-2 ml-1 fa-regular text-sm fas fa-plus`} aria-hidden="true" />
                Create
              </MenuButton>
            }
          >
            {/* Create a new team space */}
            <MenuItem
              // disabled={!user.supabaseUserId}
              key="create teamspace"
              onClick={async () => {
                if (!user.supabaseUserId) {
                  showTeamspaceSignIn()
                } else {
                  const recordName = uuid()
                  createNote({ recordName: recordName, noteType: NoteType.TEAM_SPACE, parent: null, isDir: true })
                }
              }}
            >
              <i className="far fa-screen-users mr-2 w-4 text-center" aria-hidden="true"></i>
              {!user.supabaseUserId ? 'Teamspace (login first)' : 'Teamspace'}
            </MenuItem>
            <MenuItem
              key="create folder"
              onClick={() => {
                createNote({ recordName: uuid(), noteType: NoteType.PROJECT_NOTE, parent: null, isDir: true })
              }}
            >
              <i className="far fa-folder mr-2 w-4 text-center"></i>
              Folder
            </MenuItem>
            <MenuItem
              key="create note"
              onClick={() => {
                createNote({ recordName: uuid(), noteType: NoteType.PROJECT_NOTE, parent: null })
              }}
            >
              <i className="far fa-file-lines mr-2 w-4 text-center"></i>
              Note
            </MenuItem>
          </Menu>
        ),
      },
    ],
  }

  const individualCalendarNotes: SidebarEntry[] = [
    { recordName: 'daily', color: 'text-orange-600 dark:text-orange-400', title: 'Daily', icon: 'fal fa-calendar-star', hideContext: true },
    { recordName: 'weekly', color: 'text-fuchsia-600 dark:text-fuchsia-500', title: 'Weekly', icon: 'fal fa-calendar-week', hideContext: true },
    // { recordName: 'spacer_' + uuid(), title: '', disabled: true },
  ]

  const calendarNotes: SidebarEntry = {
    recordName: 'calendar-notes',
    title: 'Calendar Notes',
    color: 'text-gray-400',
    header: true,
    isHidden: isTeamspace,
    children: individualCalendarNotes,
  }

  const projectNotes: SidebarEntry = {
    recordName: 'notes',
    title: isTeamspace ? 'Private Notes' : 'Notes',
    color: 'text-gray-400',
    header: true,
    isLoading: isLoadingPrivate,
    children: [],
    childNoteType: NoteType.PROJECT_NOTE,
    source: user?.cloudKitUserId ? SourceDatabase.CLOUDKIT : SourceDatabase.SUPABASE,
  }

  if (isTeamspace) {
    // Add the calendar notes into the "private notes" section, so private and shared are better split up
    projectNotes.children.push(...individualCalendarNotes)
  }

  const teamspaces: SidebarEntry = {
    recordName: 'teamspaces',
    title: 'Teamspaces',
    color: 'text-gray-400',
    header: true,
    isLoading: isLoadingTeam,
    isHidden: !isTeamspace,
    children: [],
    childNoteType: NoteType.TEAM_SPACE,
  }

  if (privateNotes) {
    const privateNotesTree: Note[] = notesToNotesTree(privateNotes)
    projectNotes.children.push(...privateNotesTree.map((item) => noteToSidebarEntry(item, user.supabaseUserId)))
  }

  if (teamNotes) {
    const teamNotesTree: Note[] = notesToNotesTree(teamNotes)
    teamspaces.children = teamNotesTree.map((item) => noteToSidebarEntry(item, user.supabaseUserId))

    const spacer = {
      recordName: 'spacer_' + uuid(),
      title: '',
      disabled: true,
    }

    // Add to each teamspace calendar notes
    for (const teamspace of teamspaces.children) {
      if (teamspace.noteType == NoteType.TEAM_SPACE) {
        const existingChildren = teamspace.children || []
        teamspace.children = [createDailyNote(teamspace.recordName), createWeeklyNote(teamspace.recordName), /*spacer,*/ ...existingChildren]
        if (teamspace != teamspaces.children[teamspaces.children.length - 1]) {
          teamspace.children.push(spacer)
        }
      }
    }
  }

  return [noteplan, teamspaces, calendarNotes, projectNotes, getTagsFromNotes(privateNotes, teamNotes, showCommandBar)]
}

export function getTagsFromNotes(privateNotes: Map<string, Note>, teamNotes: Map<string, Note>, showCommandBar: (_visible: boolean, _search: string) => void) {
  const tagMap = new Map()

  // Helper function to recursively add nested tags
  const addNestedTags = (tag, parent = null) => {
    if (tag.length <= 1) return

    let current = parent
    tag.split('/').forEach((part) => {
      const fullPath = current ? `${current}/${part}` : part
      part = part.replace(/^[@#]/, '')

      if (!tagMap.has(fullPath)) {
        tagMap.set(fullPath, {
          recordName: `tag_${fullPath}`,
          title: part,
          fullPath: fullPath,
          parent: current,
          children: [],
          color: 'text-gray-400',
          hideContext: true,
          icon: fullPath.startsWith('#') ? 'far fa-hashtag' : 'far fa-at',
          action: () => showCommandBar(true, 'tag: ' + fullPath),
        })
      }
      current = fullPath
    })
  }

  // Process all notes
  const allNotes = new Map([...(privateNotes ?? []), ...(teamNotes ?? [])])
  allNotes.forEach((note) => {
    if (note && note.tags) {
      note.tags.forEach((tag: string) => addNestedTags(tag))
    }
  })

  // Build the hierarchical structure
  tagMap.forEach((tag) => {
    if (tag.parent) {
      tagMap.get(tag.parent).children.push(tag)
    }
  })

  // Filter to get only top-level tags
  const topTags = Array.from(tagMap.values()).filter((tag) => !tag.parent)

  // Separate mentions and hashtags
  const mentions = topTags.filter((tag) => tag.fullPath.startsWith('@')).sort((a, b) => a.fullPath.localeCompare(b.fullPath))
  const hashtags = topTags.filter((tag) => tag.fullPath.startsWith('#')).sort((a, b) => a.fullPath.localeCompare(b.fullPath))

  return {
    recordName: 'tags',
    title: 'Tags',
    color: 'text-gray-400',
    header: true,
    children: [
      {
        recordName: 'mentions',
        title: `Mentions (${mentions.length})`,
        color: 'text-gray-400',
        icon: 'far fa-at',
        hideContext: true,
        children: mentions,
      },
      {
        recordName: 'hashtags',
        title: `Hashtags (${hashtags.length})`,
        color: 'text-gray-400',
        icon: 'far fa-hashtag',
        hideContext: true,
        children: hashtags,
      },
    ],
  }
}

function isValidCalendarNote(item: SidebarEntry, timeframe: string, active: string, selectedDate: SelectedDate) {
  return (item.recordName === timeframe || (item.recordName.startsWith(timeframe + '_') && item.noteType === NoteType.TEAM_SPACE_CALENDAR_NOTE)) && selectedDate?.active === active
}

export function findNoteKey(navigation: SidebarEntry[], recordName: string, selectedDate: SelectedDate, nested = false): useNoteConfig | null {
  for (const item of navigation) {
    if (item.recordName === recordName) {
      const noteType = item.noteType ?? NoteType.CALENDAR_NOTE
      let filename = item.filename

      if (!filename || isValidCalendarNote(item, 'daily', 'day', selectedDate) || isValidCalendarNote(item, 'weekly', 'week', selectedDate)) {
        filename = selectedDateToKey(selectedDate)
      }

      return { noteType, recordName, filename, parent: item.parent }
    } else if (item.children) {
      const result = findNoteKey(item.children, recordName, selectedDate, true)
      if (result) {
        return result
      }
    }
  }
  if (nested) {
    return null
  }
  // We got here because of the initial load or if the note was deleted, so we need to return a calendar note as fallback
  if (selectedDate.teamspace) {
    return { noteType: NoteType.TEAM_SPACE_CALENDAR_NOTE, recordName, filename: selectedDateToKey(selectedDate), parent: selectedDate.teamspace }
  } else {
    return { noteType: NoteType.CALENDAR_NOTE, recordName, filename: selectedDateToKey(selectedDate), parent: null }
  }
}
