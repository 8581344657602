import { useState, useCallback } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { Tooltip } from 'react-tooltip'
import MainSidebar from './sidebar/MainSidebar'
import CalendarWrapper from './calendar/CalendarWrapper'
import CommandBar from './command-bar/CommandBar'
import useLoginCheck from '../hooks/useLoginCheck'
import { EditorContentProvider } from '../providers/EditorContentProvider'
import NoteEditor from './editor/NoteEditor'
import { SidebarProvider } from '../providers/SidebarProvider'
import useSubscriptionVerification from '../hooks/useSubscriptionVerification'
import TrialBanner from './payments/TrialBanner'
import CheckoutDialog from './payments/CheckoutDialog'
import { useUserState } from '../providers/UserProvider'

declare global {
  interface Window {
    Beacon: (_action: string, _data: unknown) => void
  }
}
import { useSelectedRecordName } from '../providers/SelectedRecordNameProvider'
import Search from './search/Search'

export default function Layout() {
  // eslint-disable-next-line no-empty-pattern
  const {} = useLoginCheck()
  const user = useUserState()
  const { setDialogOpen, dialogOpen, trialEndDate, isSubscribed, isTrialRunning } = useSubscriptionVerification()

  window.Beacon('identify', {
    email: user?.email,
  })
  const selectedRecordName = useSelectedRecordName()

  // #region command bar
  const [commandBarState, setCommandBarState] = useState<{ visible: boolean; search: string }>({ visible: false, search: '' })

  const handleSetCommandBarState = useCallback((visible: boolean, search: string) => {
    setCommandBarState({ visible, search })
  }, [])
  // #endregion

  // #region calendar
  const [showCalendar, setShowCalendar] = useLocalStorage('showCalendar', true)

  const toggleCalendar = useCallback(() => {
    setShowCalendar(!showCalendar)
  }, [setShowCalendar, showCalendar])
  // #endregion

  // #region search
  const [searchQuery, setSearchQuery] = useLocalStorage('searchQuery', '')

  const handleSearch = useCallback(
    (query: string) => {
      setSearchQuery(query)
    },
    [setSearchQuery]
  )
  // #endregion

  return (
    <>
      {trialEndDate && <TrialBanner isTrialRunning={isTrialRunning} trial_end_date={trialEndDate} onClick={() => setDialogOpen(true)} />}
      <div className="note-container">
        <SidebarProvider onOpenCommandBar={handleSetCommandBarState}>
          <div className="flex overflow-hidden flex-col-reverse md:flex-row">
            {user && <CheckoutDialog setOpen={setDialogOpen} open={dialogOpen} />}
            <MainSidebar />
            <EditorContentProvider>
              {selectedRecordName === 'search' ? (
                <Search query={searchQuery} onSearch={handleSearch} />
              ) : (
                <NoteEditor
                  showCalendar={showCalendar}
                  toggleCalendar={toggleCalendar}
                  onCommandBarOpen={handleSetCommandBarState}
                  isSubscribed={isSubscribed}
                  isTrialRunning={isTrialRunning}
                  setDialogOpen={setDialogOpen}
                />
              )}
              <CalendarWrapper showCalendar={showCalendar} toggleCalendar={toggleCalendar} />
            </EditorContentProvider>

            <CommandBar state={commandBarState} setState={handleSetCommandBarState} />

            <Tooltip id="my-tooltip" className="tooltip" disableStyleInjection={true} />
          </div>
        </SidebarProvider>
      </div>
    </>
  )
}
