// updateContentNodes.tsx
import { Dayjs } from 'dayjs'
import { InlineContent, PartialInlineContent, StyledText } from '@blocknote/core'
import { CalendarEvent } from '../../CalendarEvent'
import { WindowWithEditor } from '../types'

export function updateContentNodesWithTime(contentNodes: InlineContent[], newStartDate: Dayjs, newEndDate: Dayjs, timeFormat: string): PartialInlineContent[] {
  const format = `${timeFormat ? 'hh' : 'HH'}:mm` + timeFormat

  // check if contentNodes contains a time block string
  const hasTimeString = contentNodes.some((contentNode) => (contentNode as StyledText).styles.timeString)
  if (hasTimeString) {
    // set time string and keep only one time block string
    return contentNodes
      .map((contentNode) =>
        (contentNode as StyledText).styles.timeString
          ? {
              ...contentNode,
              text: `${newStartDate.format(format)} - ${newEndDate.format(format)}`,
            }
          : contentNode
      )
      .reduce((acc, contentNode) => {
        if (acc.length > 0 && (acc[acc.length - 1] as StyledText).styles.timeString && (contentNode as StyledText).styles.timeString) {
          return acc
        }
        return [...acc, contentNode]
      }, [] as PartialInlineContent[])
  } else {
    return [
      {
        type: 'text',
        text: `${newStartDate.format(format)} - ${newEndDate.format(format)}`,
        styles: { timeString: true },
      },
      {
        type: 'text',
        text: ' ',
        styles: {},
      },
      ...contentNodes,
    ]
  }
}

export function updateContentNodesAndBlock(eventId: string, event: CalendarEvent | null, newStartDate: Dayjs, newEndDate: Dayjs) {
  const previousEvent = (window as WindowWithEditor).editor?.getBlock(eventId)
  const timeFormat = event?.startDateMatch.match(/\b(?:AM|PM)\b/g) ? ' A' : ''
  const updatedContentNodes = updateContentNodesWithTime(previousEvent?.content ?? [], newStartDate, newEndDate, timeFormat)
  ;(window as WindowWithEditor).editor?.updateBlock(eventId, {
    id: eventId,
    content: updatedContentNodes,
  })
}
