import { useUserState } from '../providers/UserProvider'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { useState } from 'react'
import { useSafeMutation } from './useSafeMutation'

export default function useAcceptInvitation(): {
  acceptInvitation: (_teamspaceID: string) => void
  isLoading: boolean
  error: Error | null
} {
  const [isLoading, setIsLoading] = useState(false)
  const sb = useSupabaseClient()
  const user = useUserState()

  const mutation = useSafeMutation<void, Error, { teamspaceID: string }>({
    mutationFn: async ({ teamspaceID }) => {
      setIsLoading(true)
      try {
        if (teamspaceID && user.supabaseUserId) {
          await sb.acceptInvitation(teamspaceID)
          window.location.href = '/' // When we are successful, reload the page so we get rid of the pathname with the teamspace ID
        } else {
          throw new Error('Invalid teamspace ID or user ID')
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        throw new Error(error)
      } finally {
        setIsLoading(false)
      }
    },
  })

  const acceptInvitation = (teamspaceID: string) => {
    mutation.mutate({ teamspaceID })
  }

  return {
    acceptInvitation,
    isLoading,
    error: mutation.error,
  }
}
