interface TrialBannerProps {
  trial_end_date: string
  isTrialRunning: string
  onClick: () => void
}

const TrialBanner: React.FC<TrialBannerProps> = ({ trial_end_date, isTrialRunning, onClick }) => {
  const today = new Date()
  const trialEndDate = new Date(trial_end_date)
  const diffTime = trialEndDate.getTime() - today.getTime()
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  return (
    <div className="h-auto py-2 w-full bg-orange-100 flex items-center justify-center cursor-pointer" onClick={onClick}>
      <p className="text-gray-900 dark:text-gray-900 m-0">
        {isTrialRunning === 'expired' ? (
          <span className="mb-0" style={{ fontWeight: 500 }}>
            Your trial has expired. Subscribe now to continue using NotePlan (save 10% off)
          </span>
        ) : (
          `You have ${diffDays} days left on your free trial. Subscribe before trial ends »`
        )}
      </p>
    </div>
  )
}

export default TrialBanner
