/* eslint-disable no-console */
import { SupabaseClient } from '@supabase/supabase-js'

export class SubscriptionManager {
  private supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  // MARK: - invite functions
  public async fetchSubscription(user_id: string): Promise<boolean> {
    if (!user_id) {
      return false
    }

    // First get the shared user ids, and the owner
    const { data, error } = await this.supabase.from('subscriptions').select('status').eq('user_id', user_id)

    if (error) {
      throw error
    }

    if (Array.isArray(data)) {
      if (data.length < 1) return false

      return data[0].status
    }
  }
}
