/* eslint-disable no-console */
import { User, useUserState } from '../providers/UserProvider'
import { useEffect, useState } from 'react'
import axios, { AxiosInstance } from 'axios'
import { useFetchUserDefault } from './useFetchUserDefault'
// import { useSupabaseClient } from '../data-utils/SupabaseClientProvider'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'

// Define types for the response structure from your backend
interface BackendResponse {
  error?: string
  // Add other fields expected in your successful response here
}

// Function to create the backend client
const createBackendClient = (baseURL: string): AxiosInstance => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// Determine the baseURL based on the hostname
const determineBaseURL = (hostname: string): string => {
  return hostname === 'localhost' ? 'http://localhost:8000/' : 'https://app.noteplan.co/backend/stripe/'
}

const useSubscriptionVerification = () => {
  const [error, setError] = useState<string | null>(null)
  const [isTrialLoading, setIsTrialLoading] = useState<boolean | null>(null)
  const baseURL = determineBaseURL(window.location.hostname)
  const backendClient: AxiosInstance = createBackendClient(baseURL)
  const user = useUserState()
  // const supabase = useSupabaseClient()
  const cloudKitClient = useCloudKitClient()
  const [trialEndDate, setTrialEndDate] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isTrialRunning, setIsTrialRunning] = useState<string | null>(null)
  const { data: revenueCatUserId } = useFetchUserDefault('revenuecat_id', 'web-app-subscription-rc-id')
  const [stripeCustomerId, setStripeCustomerId] = useState<string | null>(null)
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState<string | null>(null)

  const manageTrial = async (
    user: User,
    revenueCatUserId: string
  ): Promise<{
    error?: string
    trial?: string
    subscribed?: boolean
    trial_start_date?: Date
    trial_end_date?: Date
    revenuecat_id?: string
  }> => {
    try {
      const response = await backendClient.post<BackendResponse>(
        '/trial.php',
        JSON.stringify({
          user: user,
          revenueCatUserId: revenueCatUserId,
        })
      )

      if (response.status !== 200) {
        const error = 'Network response was not ok'
        setError(error)
        setIsTrialLoading(false)
        return { error }
      }

      if (response.data.error) {
        setError(response.data.error)
        setIsTrialLoading(false)
        return { error: response.data.error }
      }

      return response.data
    } catch (error) {
      let errorMessage = 'An unknown error occurred'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      } else if (error.message) {
        errorMessage = error.message
      }
      setError(errorMessage)
      setIsTrialLoading(false)
      return { error: errorMessage }
    }
  }

  const manageSupabaseTrial = async (
    user_id: string
  ): Promise<{
    error?: string
    subscription_status?: boolean
    trial?: string
    trial_end_date?: Date
    trial_start_date?: Date
    stripeCustomerId?: string
    stripeSubscriptionId?: string
  }> => {
    try {
      const response = await backendClient.post<BackendResponse>(
        '/supabase-trial.php',
        JSON.stringify({
          user_id: user_id,
        })
      )

      if (response.status !== 200) {
        const error = 'Network response was not ok'
        setError(error)
        setIsTrialLoading(false)
        return { error }
      }

      if (response.data.error) {
        setError(response.data.error)
        setIsTrialLoading(false)
        return { error: response.data.error }
      }

      return response.data
    } catch (error) {
      let errorMessage = 'An unknown error occurred'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      } else if (error.message) {
        errorMessage = error.message
      }
      setError(errorMessage)
      setIsTrialLoading(false)
      return { error: errorMessage }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return

      if (user?.cloudKitUserId) {
        if (revenueCatUserId === undefined) return

        // Check through RevenueCat, if the subscription exists,
        // console.log('REVENUECAT ID', revenueCatUserId)
        const trial_res = await manageTrial(user, revenueCatUserId)
        console.log('[useSubscriptionVerification] CloudKit user response', trial_res)

        if (!revenueCatUserId && trial_res?.revenuecat_id) {
          try {
            await cloudKitClient.setUserDefault('web-app-subscription-rc-id', trial_res.revenuecat_id)
          } catch (error) {
            console.error('Failed to save revenuecat_id:', error)
          }
        }

        setIsSubscribed(trial_res?.subscribed)
        if (!trial_res?.subscribed) {
          setTrialEndDate(trial_res?.trial_end_date)
          setIsTrialRunning(trial_res?.trial?.toString() || 'false')
        }
      } else if (user?.supabaseUserId) {
        // handle subscription
        const res = await manageSupabaseTrial(user?.supabaseUserId)
        console.log('[useSubscriptionVerification] Supabase user response', res)
        setIsSubscribed(res?.subscription_status)
        setStripeCustomerId(res?.stripeCustomerId)
        setStripeSubscriptionId(res?.stripeSubscriptionId)

        if (res?.subscription_status == false) {
          setTrialEndDate(res?.trial_end_date)
          setIsTrialRunning(res?.trial)
        }
      }
      // setOpen(true)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, revenueCatUserId])

  return { error, setError, isTrialLoading, manageTrial, setDialogOpen, dialogOpen, trialEndDate, isSubscribed, isTrialRunning, stripeCustomerId, stripeSubscriptionId }
}

export default useSubscriptionVerification
