import { useLocalStorage } from 'usehooks-ts'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePrivateNotes, useTeamNotes } from '../../providers/CachedNotesProvider'
import { SidebarEntry } from './SidebarBuilder'
import { SidebarItem } from './SidebarItem'
import { useUserState } from '../../providers/UserProvider'
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import useMoveNote from '../../hooks/useMoveNote'
import { AcceptInvitationDialog, ManageMembersDialog } from '../user/TeamspaceDialogs'
import { Notification } from './Notification'
import { useSidebarProvider } from '../../providers/SidebarProvider'
import { validate } from 'uuid'

export function findSidebarEntry(
  sidebarEntries: SidebarEntry[],
  recordName: string,
  parent: SidebarEntry | null = null
): { entry: SidebarEntry | null; parent: SidebarEntry | null } {
  for (const entry of sidebarEntries) {
    if (entry.recordName === recordName) {
      return { entry, parent }
    } else if (entry.children) {
      const result = findSidebarEntry(entry.children, recordName, entry)
      if (result.entry) {
        return result
      }
    }
  }

  return { entry: null, parent: null }
}

export default function MainSidebar() {
  const [showSidebar, setShowSidebar] = useLocalStorage('showSidebar', true)
  const { notification, setNotification, sidebarEntries } = useSidebarProvider()
  const { isFetching: isFetchingPrivate } = usePrivateNotes(useUserState())
  const { isFetching: isFetchingTeam } = useTeamNotes(useUserState())

  // #region resizing
  // Manage resizing the sidebar, which is saved to the localsotrage
  const sidebarRef = useRef(null)
  const isResizing = useRef(false)

  // Get the saved width from localStorage when component mounts
  useEffect(() => {
    const savedWidth = localStorage.getItem('sidebarWidth') || '300px'
    if (sidebarRef.current) {
      sidebarRef.current.style.width = savedWidth
    }
  }, [])

  const handleMouseDown = (e) => {
    e.preventDefault()
    isResizing.current = true
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const handleMouseMove = (e) => {
    e.preventDefault()
    if (!isResizing.current) return

    const newWidth = e.clientX - sidebarRef.current.getBoundingClientRect().left

    if (newWidth < 150 || newWidth > 1000) return
    sidebarRef.current.style.width = `${newWidth}px`
  }

  const handleMouseUp = (e) => {
    e.preventDefault()
    isResizing.current = false

    if (sidebarRef.current) {
      localStorage.setItem('sidebarWidth', sidebarRef.current.style.width)
    }
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }
  // #endregion

  // #region drag and drop
  const [draggedId, setDraggedId] = useState(null)
  const moveNote = useMoveNote()
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5,
      // delay: 50,
    },
  })
  const sensors = useSensors(pointerSensor)

  function handleDragStart(event) {
    setDraggedId(event.active.id)
  }

  function handleDragEnd(event) {
    setDraggedId(null)

    const { active, over } = event
    // if the parent id is not an uuid then set it to null
    const parentRecordName = validate(over?.id) ? over.id : null

    if (active.id === over?.id || active.data.current.parentRecordName === parentRecordName || active.data.current.parentPath === over.data.current.filename) return

    // disable dragging from CloudKit to Supabase and show an info dialog
    if (active.data.current.source !== over.data.current.source) {
      alert('You cannot move a teamspace into a private folder and vice versa.')
      return
    }

    // If we are dragging a note to a different parent, then move it
    moveNote.mutate({
      recordName: active.id,
      noteType: active.data.current.noteType,
      parentRecordName,
      parentNoteType: over.data.current.noteType,
      children: active.data.current.children,
    })
  }
  // #endregion

  // #region manage teamspace
  const [manageTeamspaceId, setManageTeamspaceId] = useState<{ id: string; title: string } | null>(null)
  const handleSetManageTeamspaceId = useCallback((id: string, title: string) => {
    setManageTeamspaceId({ id, title })
  }, [])

  const closeManageTeamspace = useCallback(() => {
    setManageTeamspaceId(null)
  }, [])
  // #endregion

  return (
    <div className="sidebar-body" style={{ zIndex: 5 }}>
      <div className="hidden md:flex justify-center items-center sticky top-0 h-screen max-h-screen">
        {showSidebar && (
          <div className="flex-col items-center border-r-2 flex bg-stone-100 dark:bg-[#333333]">
            {/* This is the sidebar drag handle for resizing it (the width) */}
            <div className="resize-handle hover:bg-slate-200 hover:dark:bg-slate-400" onMouseDown={handleMouseDown}></div>

            {/* The actual sidebar content */}
            {/* <div ref={sidebarRef} className="flex grow flex-col gap-y-5 overflow-y-auto p-0 h-screen w-64 min-w-32 overflow-x-hidden relative"> */}
            <div ref={sidebarRef} className="flex grow flex-col gap-y-5 overflow-y-auto px-0 pt-4 h-screen w-64 min-w-32 overflow-x-hidden relative">
              {/* <div className="flex h-16 shrink-0 items-center">
              <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company" />
            </div> */}
              <div className="inline-loader absolute right-2 top-3" style={{ display: isFetchingPrivate || isFetchingTeam ? 'inline' : 'none' }}></div>

              <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors} modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}>
                {/* <nav className="flex flex-1 flex-col mx-2 pb-32 pt-4"> */}
                <nav className="flex flex-1 flex-col mr-2 pb-32">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7 select-none">
                    {sidebarEntries.map((navItem: SidebarEntry) => (
                      <SidebarItem key={navItem.recordName} item={navItem} onManageTeamspace={handleSetManageTeamspaceId} />
                    ))}
                    {/* The account button at the bottom */}
                    {/* <li className="mx-0 mt-auto">
                  <button
                    type="button"
                    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-700 dark:text-gray-200"
                    onClick={() => showSignOut(sbClient, ckClient)}
                  >
                    <i className="fas fa-user"></i>
                    <span aria-hidden="true">Account</span>
                  </button>
                </li> */}
                  </ul>
                  <DragOverlay modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}>
                    {draggedId ? (
                      <ul role="list" className="flex flex-1 flex-col gap-y-7 select-none opacity-50">
                        <SidebarItem item={findSidebarEntry(sidebarEntries, draggedId).entry} onManageTeamspace={handleSetManageTeamspaceId} collapsed={true} />
                      </ul>
                    ) : null}
                  </DragOverlay>
                </nav>
              </DndContext>
            </div>
          </div>
        )}
        {/* Button to show and hide the sidebar completely */}
        <button onClick={() => setShowSidebar(!showSidebar)} className="-mr-6 py-4 bg-transparent hover:bg-gray-200 dark:hover:bg-gray-700 opacity-25 hover:opacity-100 transition">
          <i className={`fa-solid fa-chevron-${showSidebar ? 'left' : 'right'}`} />
        </button>
        <AcceptInvitationDialog />
        <ManageMembersDialog teamspaceID={manageTeamspaceId} closeManageTeamspace={closeManageTeamspace} />
        <Notification notification={notification} setNotification={setNotification} />
      </div>
    </div>
  )
}
