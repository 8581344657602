import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Toggle from '../../components/Toggle'
import CheckoutForm from './StripeCheckout'
import { CheckIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

const stripePromise = loadStripe('pk_live_51LNe8pD2RtXCdIzHTNtlWBfkkoIDVCmQmK8LFITYn08VOor8rYQSelXXZU7OJDAuSQo1mbAtkKq6OvSufIMZrwjH00rt6blgq2') // Production key
// const stripePromise = loadStripe('pk_test_51LNe8pD2RtXCdIzHFRRBjpon510YaSFdWn406RT08wBQTkdvF1N6u4j1EbTAAbOf6Hm3rb5A4l2Oco3YUgYOwZAn00ALUoj0r2') // Test key

export default function CheckoutDialog({ open, setOpen }) {
  const [isToggled, setIsToggled] = useState(true)
  const [isSuccessful, setIsSuccessful] = useState(null)

  const handleToggle = (newState: boolean) => {
    setIsToggled(newState)
    // Additional actions when the toggle state changes
  }

  useEffect(() => {
    if (isSuccessful) {
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }, [isSuccessful])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen} static={true}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full ${
                  isSuccessful ? 'sm:max-w-xl' : 'sm:max-w-4xl'
                } sm:p-6`}
              >
                <button onClick={() => setOpen(false)} className="absolute bg-transparent right-4 top-4">
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                {isSuccessful ? (
                  <div className="text-center">
                    <CheckIcon className="mx-auto h-12 w-12 text-green-500" aria-hidden="true" />
                    <h3 className="mt-2 text-lg leading-6 font-medium text-gray-900">Successfully Subscribed</h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Reloading the page....</p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="mb-6 text-center pt-12">
                      <h2 className="text-3xl font-semibold text-gray-900">Unlock continued access to NotePlan</h2>
                      <p className="text-gray-600">Continue using NotePlan without interruption by subscribing today.</p>
                    </div>
                    <div className="w-full flex justify-center mb-3">
                      <Toggle isEnabled={isToggled} onToggle={handleToggle} label="Annual Plan" description="Save 10% off" />
                    </div>
                    <div className="grid grid-cols-2 gap-1">
                      <div>
                        <div className="w-full p-4 pt-0 bg-white sm:p-8">
                          {/* <h5 className="mb-4 no-underline text-xl font-medium text-gray-500">Standard plan</h5> */}
                          <div className="flex items-baseline text-gray-900">
                            <span className="text-3xl font-semibold">$</span>
                            <span className="text-5xl font-extrabold tracking-tight">{isToggled ? 99 : 9.99}</span>
                            <span className="ms-1 text-xl font-normal text-gray-500">{isToggled ? '/year' : '/month'}</span>
                          </div>
                          <ul role="list" className="space-y-5 my-7">
                            <li className="flex items-center">
                              <svg
                                className="flex-shrink-0 w-4 h-4 text-amber-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Access notes, tasks and calendar in one place </span>
                            </li>
                            <li className="flex items-center">
                              <svg
                                className="flex-shrink-0 w-4 h-4 text-amber-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Flexible daily, weekly, yearly planning views</span>
                            </li>
                            <li className="flex items-center">
                              <svg
                                className="flex-shrink-0 w-4 h-4 text-amber-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Support all devices: Web, Mac, iPad, iPhone</span>
                            </li>
                            <li className="flex">
                              <svg
                                className="flex-shrink-0 w-4 h-4 text-amber-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Integrated with Google Calendar</span>
                            </li>
                            <li className="flex">
                              <svg
                                className="flex-shrink-0 w-4 h-4 text-amber-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Backlinking and bi-directional links for interconnected notes</span>
                            </li>
                            <li className="flex">
                              <svg
                                className="flex-shrink-0 w-4 h-4 text-amber-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Tags, mentions, and filter for easy organization</span>
                            </li>
                            <li className="flex">
                              <svg
                                className="flex-shrink-0 w-4 h-4 text-amber-600"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                              </svg>
                              <span className="text-base font-normal leading-tight text-gray-500 ms-3">Markdown support for efficient note-taking</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="border-l dark:border-gray-200">
                        <div className="h-full overflow-auto">
                          <div>
                            <Elements stripe={stripePromise}>
                              <CheckoutForm duration={isToggled ? 'y' : 'm'} setIsSuccessful={setIsSuccessful} />
                            </Elements>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
