// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Resizable } from 're-resizable'
import classNames from 'classnames'
import { handleResizeStop } from '../utils/resize'
import { DragEvent, useState } from 'react'
import { EventContentProps } from '../types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
function EventContent({ setCalendarEventDrag, event, eventHeight, segmentHeight, offsetPxTop = 0, className = '', style = {} }: EventContentProps) {
  const [deltaHeight, setDeltaHeight] = useState<number | null>(null)
  const [maxHeight, setMaxHeight] = useState<number | null>(null)

  const isShortEvent = eventHeight < segmentHeight * 2
  const eventDate = event.startDate
  const bounds = document.getElementById('calendar-event-bounds')?.getBoundingClientRect()
  const iconName = event.contentType === 'taskListItem' ? 'fa-regular fa-circle' : event.contentType === 'bulletListItem' ? 'fa-solid fa-circle-small' : 'fa-regular fa-square'

  const position =
    event.contentType === 'bulletListItem'
      ? 'text-[0.5rem] left-[0.6em] ' + (isShortEvent ? 'top-[0.45em]' : 'top-[0.45em]')
      : 'left-[0.2em] ' + (isShortEvent ? 'top-[0.2em]' : 'top-[0.1em]')

  const icon = event.contentType !== 'event' && <i className={`absolute ${position} ${iconName}${event.checked ? '-check' : ''}`}></i>
  const color = event.contentType == 'event' ? 'blue' : 'orange'
  return (
    <Resizable
      className={classNames('relative h-full flex-grow user-none', { 'opacity-50': event.id == 'temporary' }, { 'opacity-[0.46]': event.checked })}
      style={{ ...style, transform: deltaHeight ? `translateY(${-deltaHeight + (offsetPxTop ?? 0)}px)` : style.transform ?? '' }}
      onResize={(_e, dir, _ref, delta) => {
        dir == 'top' && setDeltaHeight(delta.height)
      }}
      onResizeStop={(e, dir, _ref, delta) => {
        e.preventDefault()
        handleResizeStop(event, segmentHeight, delta, dir)
        setDeltaHeight(0)
        // setMaxHeight(null);
      }}
      onResizeStart={(e, dir, ref) => {
        e.preventDefault()
        setMaxHeight(eventHeight - 2 + (dir == 'top' ? Math.abs(bounds.top - ref.getBoundingClientRect().top) : Math.abs(bounds.bottom - ref.getBoundingClientRect().bottom)))
      }}
      size={{ height: eventHeight - 2, width: 'auto' }}
      enable={{ top: true, right: false, bottom: true, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
      grid={[1, segmentHeight]}
      maxHeight={maxHeight ?? 0 - 2}
      minHeight={segmentHeight}
      draggable="true"
      onDragEnter={(_e: DragEvent) => {
        const calendarEventList = document.getElementById('calendar-events-list')
        calendarEventList?.style.setProperty('pointer-events', 'none')
      }}
      onDragStart={(e: DragEvent) => {
        e.dataTransfer.setData('text/plain', JSON.stringify({ ...event, type: 'timeblock' }))
        const target = e.target as HTMLElement

        const clientRect = target.getBoundingClientRect()
        const scaledElement = target.cloneNode(true) as HTMLElement

        scaledElement.id = 'dragged-event-image'
        scaledElement.style.width = String(clientRect.width * 0.6)
        scaledElement.style.height = String(clientRect.height * 0.6)

        // Hide the new element by moving it away, because setDragImage will copy it
        scaledElement.style.transform = 'translateX(-5000px)'
        document.body.appendChild(scaledElement)

        e.dataTransfer.setDragImage(scaledElement, 0, 0)
        setCalendarEventDrag(event)
      }}
    >
      <div id={`event-content-${event.id}`} className={`truncate event-content-background ${color} ${className}`}>
        <p className={classNames('time text-opacity-95 text-[0.65rem] ml-px -mb-0.5', { hidden: isShortEvent })}>
          <time dateTime={eventDate.format('YYYY-MM-DDTHH:mm')}>{eventDate.format('LT')}</time>
          {event.contentType === 'event' && <i className="ml-1 fa-light fa-xs fa-alarm-clock"></i>}
        </p>
        <p className="relative truncate font-semibold label text-opacity-95 text-xs ml-px">
          {icon}
          <span className={`ml-${event.contentType === 'event' ? '0' : '5'}`}>{event.content}</span>
        </p>
      </div>
    </Resizable>
  )
}
export default EventContent
