import { dateCompletionChronoParser } from './dateCompletionChronoParser'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { LinkMarkSuggestionItem } from '@blocknote/core'

dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isYesterday)
dayjs.extend(quarterOfYear)

export const dateLinkSuggestions = (keyword: string): LinkMarkSuggestionItem[] => {
  if (keyword.length === 0) {
    return createDefaultOptions()
  } else {
    return dateCompletionChronoParser
      .parse(keyword, new Date(), { forwardDate: true })
      .map((result) => {
        const parsedDate = result.date()
        if (result.tags().has('week')) {
          return [createWeekOption(parsedDate)]
        } else {
          return [createDayOption(parsedDate), createWeekOption(parsedDate)]
        }
      })
      .flat()
  }
}

const createDefaultOptions = (): LinkMarkSuggestionItem[] => {
  const today = new Date()
  const tomorrow = dayjs(today).add(1, 'day').toDate()
  const nextWeek = dayjs(today).add(7, 'day').toDate()
  //   const nextMonth = dayjs(today).add(1, 'month').toDate()

  return [
    createDayOption(today),
    createDayOption(tomorrow),
    createDayOption(nextWeek, 'In 1 week, '),
    createWeekOption(today, 'This week, '),
    createWeekOption(nextWeek, 'Next week, '),
    // createMonthOption(today, 'This month, '),
    // createMonthOption(nextMonth, 'Next month, '),
    // createQuarterOption(today, 'This quarter, '),
    // createYearOption(today, 'This year, '),
  ]
}

// Add a 'week x' option for that given date, i.e. figure out which week it is, start, end date of that week and create an entry for 'Week 5 (Feb 25 - Mar 2); for example with the paste value of '2021-W05'
const createWeekOption = (parsedDate: Date, prefix = ''): LinkMarkSuggestionItem => {
  const week = dayjs(parsedDate).week()
  const year = dayjs(parsedDate).year()
  const startOfWeek = dayjs(parsedDate).startOf('week')
  const endOfWeek = dayjs(parsedDate).endOf('week')
  const weekOption = `${prefix}Week ${week} (${startOfWeek.format('MMM D')} - ${endOfWeek.format('MMM D')})`
  const weekPaste = `${year}-W${week.toString().padStart(2, '0')}`
  return { display: weekOption, paste: weekPaste, icon: 'calendar-week', shortcut: `>${weekPaste}` }
}

const createDayOption = (parsedDate: Date, prefix = ''): LinkMarkSuggestionItem => {
  let option = dayjs(parsedDate).format('ddd, D MMM')

  if (dayjs(parsedDate).isToday()) {
    option = 'Today, ' + option
  } else if (dayjs(parsedDate).isTomorrow()) {
    option = 'Tomorrow, ' + option
  } else if (dayjs(parsedDate).isYesterday()) {
    option = 'Yesterday, ' + option
  } else {
    option = `${prefix}${option}`
  }

  const paste = dayjs(parsedDate).format('YYYY-MM-DD')
  return { display: option, paste: paste, icon: 'calendar-star', shortcut: `>${paste}` }
}

// EDIT: Uncomment and use it, once we support other note time frames:
// const createMonthOption = (parsedDate: Date, prefix = ''): { display: string; paste: string } => {
//   const month = dayjs(parsedDate).format('MMMM')
//   const year = dayjs(parsedDate).year()
//   const monthOption = `${prefix}${month}`
//   const monthPaste = `${year}-${dayjs(parsedDate).format('MM')}`
//   return { display: monthOption, paste: monthPaste }
// }

// const createQuarterOption = (parsedDate: Date, prefix = ''): { display: string; paste: string } => {
//   const quarter = dayjs(parsedDate).quarter()
//   const year = dayjs(parsedDate).year()
//   const quarterOption = `${prefix}Q${quarter}`
//   const quarterPaste = `${year}-Q${quarter}`
//   return { display: quarterOption, paste: quarterPaste }
// }

// const createYearOption = (parsedDate: Date, prefix = ''): { display: string; paste: string } => {
//   const year = dayjs(parsedDate).year()
//   const yearOption = `${prefix}${year}`
//   const yearPaste = `${year}`
//   return { display: yearOption, paste: yearPaste }
// }
