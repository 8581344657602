/* eslint-disable no-console */
import { PartialInlineContent, PartialBlock, DefaultBlockSchema, BlockNoteEditor, BlockSchema, uniqueFilename } from '@blocknote/core'
import { v4 as uuidv4 } from 'uuid'

export class TextUtils {
  public static loadAttachment(file: File, editor: BlockNoteEditor<BlockSchema>) {
    const insertAttachment = (url: string) => {
      const extension = file.name.split('.').pop() ?? file.type.split('/').pop() ?? 'png'
      const name = file.name.replace(/\(/g, '').replace(/\)/g, '')
      const filename = uuidv4() + '.' + extension

      // Create an inlineAttachment mark
      const content: PartialInlineContent = {
        type: 'text',
        // Get the last path component of the filename
        text: name,
        styles: { inlineAttachment: true },
        attrs: {
          filename: filename,
          downloadUrl: url,
          downloaded: true,
          title: uniqueFilename(name, editor._tiptapEditor.state),
          src: url,
          href: url,
        },
      }

      const block: PartialBlock<DefaultBlockSchema> = {
        type: 'paragraph',
        content: [content],
        children: [],
      }

      const node = BlockNoteEditor.convertBlockToNode(block, editor._tiptapEditor.schema)

      const tr = editor._tiptapEditor.state.tr.insert(editor._tiptapEditor.state.selection.from, node)
      editor._tiptapEditor.view.dispatch(tr)
    }

    // Max 50mb
    if (file.size > 52428800) {
      console.log('File is too large to upload. Max size is 50MB.', file.size, 'bytes')
      return
    }

    const reader = new FileReader()

    // Set up the onload event handler for the reader
    reader.onload = () => {
      // Create a blob
      const blob = new Blob([reader.result as ArrayBuffer], {
        type: file.type,
      })

      // Create a url to the blob
      const url = URL.createObjectURL(blob)
      insertAttachment(url)
    }

    // Read the file as a data URL
    reader.readAsArrayBuffer(file)
  }
}
