import { NoteType, isCalendarNote, isTeamspaceNote } from './syncUtils'

// privateKeys
// 0: private, calendar, $filename
// 7: private, calendar, $filename
// 1: private, project, $recordName
// 5: private, project, $recordName
// references: private, references
// logincheck: private, logincheck
export const privateKeys = {
  0: (filename: string) => ['private', 'notes', 'calendar', filename] as const,
  7: (filename: string) => ['private', 'notes', 'calendar', filename] as const,
  1: (recordName: string) => ['private', 'notes', 'project', recordName] as const,
  5: (recordName: string) => ['private', 'notes', 'project', recordName] as const,
  notes: ['private', 'notes'] as const,
  references: ['private', 'references'] as const,
  logincheck: ['private', 'logincheck'] as const,
}

// teamKeys
// 10: team, $teamspaceId
// members: team, $teamspaceId, members
// 12: team, $teamspaceId, calendar, $filename
// 11: team, project, $recordName
// references: team, references
export const teamKeys = {
  10: (teamspaceId: string) => ['team', teamspaceId] as const,
  members: (teamspaceId: string) => ['team', teamspaceId, 'members'] as const,
  12: (teamspaceId: string, filename: string) => ['team', teamspaceId, 'calendar', filename] as const,
  11: (recordName: string) => ['team', 'project', recordName] as const,
  all: ['team'] as const,
  references: ['team', 'references'] as const,
}

// cacheKeys
// extension: cache, extension
// notes: cache, notes
// private: cache, notes, private, $userId
// team: cache, notes, team, $userId
export const cacheKeys = {
  extension: ['cache', 'extension'] as const,
  notes: ['cache', 'notes'] as const,
  private: (userId: string | null = null) => (userId ? (['cache', 'notes', 'private', userId] as const) : (['cache', 'notes', 'private'] as const)),
  team: (userId: string | null = null) => (userId ? (['cache', 'notes', 'team', userId] as const) : (['cache', 'notes', 'team'] as const)),
}

export function noteQueryKey({ noteType, recordName, filename, parent }: { noteType: NoteType; recordName?: string; filename: string; parent?: string }) {
  if (isTeamspaceNote(noteType)) {
    if (isCalendarNote(noteType)) {
      if (!parent) throw new Error('parent is required for team calendar notes')
      if (!filename) throw new Error('filename is required for calendar notes')
      return teamKeys[noteType](parent, filename)
    } else {
      if (!recordName) throw new Error('recordName is required for project notes')
      return teamKeys[noteType](recordName)
    }
  } else {
    if (isCalendarNote(noteType)) {
      if (!filename) throw new Error('filename is required for calendar notes')
      return privateKeys[noteType](filename)
    } else {
      if (!recordName) throw new Error('recordName is required for project notes')
      return privateKeys[noteType](recordName)
    }
  }
}

export function projectNoteQueryKey({ recordName, noteType }: { recordName: string; noteType: NoteType }) {
  if (isTeamspaceNote(noteType)) {
    return teamKeys[noteType](recordName)
  } else {
    return privateKeys[noteType](recordName)
  }
}
