// hooks/useSearchResults.ts
import { useMemo } from 'react'
import Fuse from 'fuse.js'
import { Note, NoteType } from '../../../utils/syncUtils'

export type SearchResult = {
  id: string
  filename?: string
  noteType?: NoteType
  name: string
  modified: number
  tags: string[]
  type: 'tag' | 'note'
}

export const useSearchResults = (notes: Array<Note>, query: string, openSearch: string) => {
  const term = (query.length === 0 ? openSearch : query).trim().toLocaleLowerCase()

  const isTagQuery = useMemo(() => term.startsWith('hashtag:') || term.startsWith('mention:') || term.startsWith('tag:'), [term])

  const typeFilter = useMemo(
    () =>
      isTagQuery || term.startsWith('#') || term.startsWith('@')
        ? [NoteType.PROJECT_NOTE, NoteType.ASSET_PROJECT_NOTE, NoteType.CALENDAR_NOTE, NoteType.ASSET_CALENDAR_NOTE, NoteType.TEAM_SPACE_NOTE, NoteType.TEAM_SPACE_CALENDAR_NOTE]
        : [NoteType.PROJECT_NOTE, NoteType.ASSET_PROJECT_NOTE, NoteType.TEAM_SPACE_NOTE],
    [isTagQuery, term]
  )

  const eligibleNotes = useMemo(
    () =>
      notes
        .filter((note) => typeFilter.includes(note.noteType) && !note.isFolder && !note.filename.startsWith('@'))
        .map((note) => ({
          id: note.recordName,
          name: note.title ?? note.filename ?? '',
          modified: (note.fileModifiedAt ?? new Date()).getTime(),
          tags: note.tags ?? [],
          type: 'note',
          filename: note.filename,
          noteType: note.noteType,
        })) as SearchResult[],
    [notes, typeFilter]
  )

  const allTagsAndMentions = useMemo(
    () =>
      Array.from(new Set(eligibleNotes.flatMap((note) => note.tags)))
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
        .map((tag: string) => ({
          id: tag,
          name: tag,
          modified: new Date().getTime(),
          tags: [],
          type: 'tag',
        })),
    [eligibleNotes]
  )

  const recent = useMemo(() => eligibleNotes.sort((a, b) => b.modified - a.modified).slice(0, 100), [eligibleNotes])

  const filteredResults = useMemo(() => {
    if (term.length > 0) {
      if (term.startsWith('#') || term.startsWith('@')) {
        return allTagsAndMentions.filter((tag) => tag.name.toLowerCase().includes(term))
      } else {
        if (isTagQuery) {
          const searchTag = term
            .replace(/^(hashtag:|mention:|tag:)/, '')
            .trim()
            .toLowerCase()
          return eligibleNotes.filter((note) => note.tags.map((tag) => tag.toLowerCase()).includes(searchTag))
        } else {
          if (term.length <= 1) {
            return []
          }
          const options = {
            threshold: 0.5,
            maxPatternLength: 42,
            tokenize: true,
            location: 0,
            distance: 600,
            keys: ['name'],
            shouldSort: true,
            isCaseSensitive: false,
          }
          const fuse = new Fuse(eligibleNotes, options)
          const fuzzyResults = fuse.search(term.slice(0, 100), { limit: 250 })
          return fuzzyResults.map((result) => result.item)
        }
      }
    }
    return []
  }, [term, allTagsAndMentions, eligibleNotes, isTagQuery])

  return { recent, filteredResults }
}
