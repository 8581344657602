import * as React from 'react'
import { CloudKitClient } from '../api/CloudKitClient'

export const cloudKitClientContext = React.createContext<CloudKitClient | undefined>(undefined)
const cloudKitClient = new CloudKitClient()

export const useCloudKitClient = () => {
  const cloudKitClient = React.useContext(cloudKitClientContext)

  if (!cloudKitClient) {
    throw new Error('No QueryClient set, use CloudKitClientProvider to set one')
  }

  return cloudKitClient
}

export const CloudKitClientProvider = ({ children }): JSX.Element => {
  const Context = cloudKitClientContext

  return <Context.Provider value={cloudKitClient}>{children}</Context.Provider>
}
