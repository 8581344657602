/* eslint-disable no-console */
import axios, { AxiosInstance } from 'axios'

// Define types for the response structure from your backend
interface BackendResponse {
  error?: string
  // Add other fields expected in your successful response here
}

// Function to create the backend client
const createBackendClient = (baseURL: string): AxiosInstance => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// Determine the baseURL based on the hostname
const determineBaseURL = (hostname: string): string => {
  return hostname === 'localhost' ? 'http://localhost:8000/' : 'https://app.noteplan.co/backend/stripe/'
}

const useSubscribeEmailOctopus = () => {
  const baseURL = determineBaseURL(window.location.hostname)
  const backendClient: AxiosInstance = createBackendClient(baseURL)

  const subscribeUser = async (
    user_id: string
  ): Promise<{
    error?: string
  }> => {
    try {
      const response = await backendClient.post<BackendResponse>(
        '/emailoctopus.php',
        JSON.stringify({
          supabaseUserId: user_id,
        })
      )

      if (response.status !== 200) {
        const error = 'Network response was not ok'
        return { error }
      }

      if (response.data.error) {
        return { error: response.data.error }
      }

      return
    } catch (error) {
      let errorMessage = 'An unknown error occurred'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      } else if (error.message) {
        errorMessage = error.message
      }

      return { error: errorMessage }
    }
  }

  return { subscribeUser }
}

export default useSubscribeEmailOctopus
