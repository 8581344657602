import { Dayjs } from 'dayjs'
import { convertTo24HourFormat } from '../../utils/time'
import { Block, BlockSchema } from '@blocknote/core'

export type CalendarEvent = {
  id: string;
  contentType: string;
  content: string;
  startDate: Dayjs;
  endDate: Dayjs;
  position: string;
  checked?: boolean;
  cancelled?: boolean;
  startDateMatch?: string;
  endDateMatch?: string;
  fullDate?: string;
  allDay?: boolean;
  description?: string;
};

interface ProcessedResult {
  startDateMatch: string;
  endDateMatch: string;
  fullDate: string;
  startDate: Dayjs;
  endDate: Dayjs;
  position: string;
}

export function calculateEventPosition(startDateTime: Dayjs, endDateTime: Dayjs) {
  const row = Math.floor(startDateTime.diff(startDateTime.clone().startOf('day'), 'minute') / 15) + 2
  const span = Math.ceil(endDateTime.diff(startDateTime, 'minute') / 15)

  return `${row} / span ${span}`
}

export function processEventsInBlock(block: Block<BlockSchema>, selectedDay: Dayjs) {
  const validTypes = ['taskListItem', 'checkListItem', 'bulletListItem', 'heading']
  let events: CalendarEvent[] = []

  // Canceled tasks or checklists are not counted as timeblocks and shouldn't be displayed
  if (validTypes.includes(block.type) && !block.props.cancelled) {
    let timeBlock: ProcessedResult | null = null
    let eventContent = ''

    for (const inlineContent of block.content ?? []) {
      if (inlineContent.type !== 'text') continue

      const match = inlineContent.styles.timeString && inlineContent.text.match(/\d{1,2}\s*:\s*\d{2}\s*(?:AM|PM)?\s*-\s*\d{1,2}\s*:\s*\d{2}\s*(?:AM|PM)?/)

      if (match) {
        const fullTime = match[0].split('-')
        const startTime: string = fullTime[0]
        const endTime: string = fullTime[1]
        const is24hours = !startTime.match(/AM|PM/)
        const start24Hour = !is24hours ? convertTo24HourFormat(startTime) : startTime
        const end24Hour = !is24hours ? convertTo24HourFormat(endTime.trim()) : endTime
        const startDateTime = selectedDay
          .set('hour', parseInt(start24Hour.split(':')[0], 10))
          .set('minute', parseInt(start24Hour.split(':')[1], 10))
          .set('second', 0)
          .set('millisecond', 0)
        let endDateTime = selectedDay
          .set('hour', parseInt(end24Hour.split(':')[0], 10))
          .set('minute', parseInt(end24Hour.split(':')[1], 10))
          .set('second', 0)
          .set('millisecond', 0)
        if (startDateTime > endDateTime) {
          endDateTime = startDateTime.add(15, 'minute')
        }

        timeBlock = {
          startDateMatch: startTime,
          endDateMatch: endTime,
          fullDate: inlineContent.text,
          startDate: startDateTime,
          endDate: endDateTime,
          position: calculateEventPosition(startDateTime, endDateTime),
        }
      } else {
        eventContent += inlineContent.text + ' '
      }
    }

    if (timeBlock && eventContent.trim().length > 0) {
      events.push({
        ...timeBlock,
        content: eventContent.trim(),
        id: block.id,
        contentType: block.type,
        checked: block.props.checked,
        cancelled: block.props.cancelled,
      })
    }
  }

  for (const child of block.children ?? []) {
    events = events.concat(processEventsInBlock(child, selectedDay))
  }

  return events
}
