import React, { useEffect, useState, FormEvent } from 'react'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { CardElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js'
import usePayment from '../../hooks/usePayment'
import { useFetchUserDefault } from '../../hooks/useFetchUserDefault'
import { useUserState } from '../../providers/UserProvider'
import useSubscriptionVerification from '../../hooks/useSubscriptionVerification'
import useManageSubscription from '../../hooks/useManageSubscription'

declare const console: {
  error: (_message?: unknown, ..._optionalParams: unknown[]) => void
  log: (_message?: unknown, ..._optionalParams: unknown[]) => void
  // Add other console methods if needed
}

type CardStyle = {
  hidePostalCode: boolean
  style: {
    base: {
      color: string
      backgroundColor?: string
    }
  }
}

interface CheckoutFormProps {
  duration: string
  setIsSuccessful: (_value: boolean) => void
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ duration, setIsSuccessful }) => {
  const stripe: Stripe | null = useStripe()
  const elements: StripeElements | null = useElements()
  const { sendPayment, resubscribe, error, setError, isLoading } = usePayment({ stripe, elements })
  const user = useUserState()
  const [isCardElementReady, setCardElementReady] = useState(false)
  const [address, setAddress] = useState(null)
  const { data: revenueCatUserId } = useFetchUserDefault('revenuecat_id', 'web-app-subscription-rc-id')
  const { stripeCustomerId } = useSubscriptionVerification()
  const { openManageSubscription } = useManageSubscription()

  const [email, setEmail] = useState(user.email)

  const lightStyle: CardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        color: '#32325d',
      },
    },
  }

  const darkStyle: CardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        // color: '#ffffff',
        // backgroundColor: 'rgb(87 83 78)',
        color: '#32325d',
      },
    },
  }

  const [cardStyle, setCardStyle] = useState<CardStyle>(lightStyle)

  useEffect(() => {
    const matchDark = window.matchMedia('(prefers-color-scheme: dark)')
    const handleChange = () => {
      setCardStyle(matchDark.matches ? darkStyle : lightStyle)
    }

    console.log('revenueCatUserId', revenueCatUserId)

    matchDark.addEventListener('change', handleChange)
    handleChange()

    return () => {
      matchDark.removeEventListener('change', handleChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // If we have a stripe customer id, we just need to re-subscribe
    if (stripeCustomerId) {
      try {
        if (await resubscribe(user, duration, revenueCatUserId, stripeCustomerId)) {
          setIsSuccessful(true)
        }
      } catch (err) {
        console.error('Resubscribe error error:', err)
      }
      return
    }

    if (!stripe || !elements || !isCardElementReady) {
      console.error('Stripe has not been properly initialized')
      return
    }

    // Add logic to handle the address data along with payment
    const addressData = elements.getElement(AddressElement)
    if (!addressData) {
      console.error('Address data is not available')
      setError('Address data not available')
      return
    }

    const result = await addressData.getValue()
    if (!result.complete) {
      setError('Please fill out the complete address')
      return
    }

    try {
      console.log('Before sending', user, address, duration, revenueCatUserId)
      if (await sendPayment(user, email, address, duration, revenueCatUserId)) {
        setIsSuccessful(true)
      }
    } catch (err) {
      console.error('Payment error:', err)
    }
  }

  if (!stripe) {
    return <div>Loading</div>
  }

  return (
    <div className="overflow-hidden w-full m-auto p-3">
      <form onSubmit={handleSubmit}>
        {stripeCustomerId ? (
          <div className="bg-white rounded-lg px-4 mb-2">
            <p>Existing account found, resubscribe or click below to change your payment method or address.</p>
            <div className="mt-4 sm:mt-3 sm:flex w-full justify-center">
              <button
                className="inline-flex w-full justify-center rounded-md bg-transparent border-2 border-amber-600 px-10 py-2 mt-2 text-sm font-semibold text-amber-800 shadow-sm hover:bg-amber-50 sm:w-full"
                onClick={(e) => {
                  e.preventDefault()
                  openManageSubscription()
                }}
              >
                Manage Account
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-lg p-4 mb-2">
            <div className="mb-3 mt-1 pr-1 border border-gray-200 focus:border-blue-400 rounded shadow-sm">
              <input
                type="email"
                className="w-full p-2 bg-white focus:ring focus:ring-4 outline-none dark:text-gray-900"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'a' && (e.metaKey || e.ctrlKey)) {
                    e.preventDefault()
                    const input = e.target as HTMLInputElement
                    input.select()
                  }
                }}
                value={email}
                required
              />
            </div>
            <span className="text-[14px] text-gray-600">Card Details</span>
            <div className="px-3 py-3 mb-3 mt-1 border rounded shadow-sm focus:outline dark:border-gray-200">
              <CardElement options={cardStyle} onReady={() => setCardElementReady(true)} />
            </div>

            <AddressElement options={{ mode: 'billing' }} onChange={(e) => setAddress(e.complete ? e.value : null)} />
          </div>
        )}
        {error && (
          <div role="alert" className="px-4">
            <div className="flex items-center p-4 py-2 mb-2 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
              <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <span className="sr-only">Info</span>
              <div>{error}</div>
            </div>
          </div>
        )}
        <div className="mt-4 px-4 sm:mt-3 sm:flex w-full justify-center">
          <button
            className="inline-flex w-full justify-center rounded-md bg-amber-600 px-10 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 sm:w-full"
            type="submit"
            aria-label="Pay"
            disabled={(!isCardElementReady && !stripeCustomerId) || isLoading}
          >
            {isLoading ? (
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : duration === 'y' && !stripeCustomerId ? (
              'Subscribe Now (save 10%)'
            ) : (
              'Subscribe Now'
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default CheckoutForm
