/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react'
import { useSupabaseClient } from '../../providers/SupabaseClientProvider'

const NotePlanIcon = () => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img className="mx-auto h-24 w-auto drop-shadow-md" src="icon-mac.png" alt="NotePlan" />
    </div>
  )
}

const EmailOTPSignIn = ({ isTeamspace, id }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState(null)
  const [token, setToken] = useState(null)
  const [hasError, setHasError] = useState(false)

  // const [password, setPassword] = useState(null);
  const supabase = useSupabaseClient()
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  // const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPassword(event.target.value);
  // };

  const handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToken(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Stop event from propagating, or CMD+A will select the text of the editor behind it
    event.stopPropagation()

    if (event.key === 'Enter') {
      buttonRef.current?.click()
    }
  }

  const handleExistingSignIn = async () => {
    try {
      // We need to check if supabase is already logged (from a previous teamspace login) it or has the meta field set if this is not a teamspace login, then logout and reset the meta field
      if (!isTeamspace) {
        console.debug('[Sign In/Out] Already signed-in, check meta and sign out')
        const meta = await supabase.getUserMetaData()

        if (meta?.cloudKitUserId) {
          console.debug('[Sign In/Out] meta data found, resetting...', meta)
          await supabase.setUserMetaData({ ...meta, cloudKitUserId: null })
        }

        await supabase.signOut()
      }
    } catch (error) {
      console.log('Error while checking if already signed-in', error)
    }
  }

  const handleSignIn = async () => {
    // NOTICE: To set and use passwords after logging in with the magic link (after verifyOTP is called)
    // use supabase.updatePassword(password) to set the password. After that, the user can login with the password.
    // (to enable the password login flow, just uncomment the password field in the returned html)
    // To make the sign in flow work properly, we need to detect if it's an existing user after the user enters the
    // email address and hits continue. If it's an existing user, we can ask to set a password.
    // If it's a new user, we can send a magic link / OTP and ask for the password after that.
    // Or if it's a new user, let him set the password right away and confirm the email address.
    // Notion sends an OTP and asks for the password after that, Craft works only with OTP and magic links.

    // if (password && email) {
    // const success = await supabase.signInWithEmailPassword(email, password);
    // console.log('email and password success', success);
    // } else

    setHasError(false)

    if (token) {
      setIsLoading(true)
      const success = await supabase.verifyOTP(email, token)

      if (success) {
        document.getElementById('sign-up-token-container' + id)?.classList.add('hidden')
        setToken(null) // Reset here so we can do it again
        setEmail(null)

        ;(document.getElementById('email' + id) as HTMLInputElement).value = ''
        ;(document.getElementById('token' + id) as HTMLInputElement).value = ''
      } else {
        setHasError(true)
      }

      setIsLoading(false)
    } else if (email) {
      setIsLoading(true)

      await handleExistingSignIn()

      const success = await supabase.signInWithEmailOTP(email)
      if (success) {
        document.getElementById('sign-up-token-container' + id)?.classList.remove('hidden')
        // Set focus on the token input field
        document.getElementById('token' + id)?.focus()
      } else {
        setHasError(true)
      }
      setIsLoading(false)
    }
  }

  return (
    <div className="mx-auto sm:w-full max-w-[380px]">
      <div className="relative mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200 dark:border-gray-600" />
        </div>
      </div>
      <div className="py-6 sm:rounded-lg">
        {hasError == true ? (
          <div className="relative flex flex-col p-4 mb-4 text-sm leading-6 border border-red-200 dark:border-red-600 rounded-md dark:bg-red-600/30 bg-red-100">
            <div className="flex items-center space-x-2">
              <i className="far fa-triangle-exclamation text-lg text-red-600 dark:text-red-300 -mt-2"></i>
              <p className="text-gray-600 dark:text-gray-300 text-sm font-bold">Ooops, something went wrong!</p>
            </div>
            <span className="text-gray-600 dark:text-gray-300 text-sm text-left pt-0 mt-0">
              Please reload the page and try again in 60 seconds. If this doesn&apos;t work, reach out to hello@noteplan.co
            </span>
          </div>
        ) : (
          <div className="relative flex flex-col p-4 mb-4 text-sm leading-6 border border-gray-200 dark:border-gray-600 rounded-md dark:bg-gray-600/30 bg-gray-100">
            <div className="flex items-center space-x-2">
              <i className="far fa-info-circle text-lg text-gray-600 dark:text-gray-300 -mt-2"></i>
              <p className="text-gray-600 dark:text-gray-300 text-sm font-bold">Data won&apos;t be stored on iCloud (CloudKit)</p>
            </div>
            <span className="text-gray-600 dark:text-gray-300 text-sm text-left pt-0 mt-0">
              {isTeamspace == true ? (
                <span>Notes in a teamspace are stored on a non-CloudKit database (still encrypted) and therefore needs a separate login. This makes sharing more flexible.</span>
              ) : (
                <span>To sync iCloud data, use &apos;Sign in with Apple ID&apos; above. The email sign-in won&apos;t access iCloud.</span>
              )}
            </span>
          </div>
        )}

        <div className="space-y-3">
          <div>
            {isTeamspace && (
              <div className="text-left">
                <span className="text-xs uppercase text-gray-600 dark:text-gray-300 font-bold">Teamspace Login:</span>
              </div>
            )}

            <div className="mt-2">
              <input
                id={`email` + id}
                name="email"
                type="email"
                onChange={handleEmailChange}
                onKeyDown={handleKeyDown}
                autoComplete="email"
                placeholder="Enter your email address"
                required
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 dark:text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
              />
            </div>
            {/* <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                onChange={handlePasswordChange}
                autoComplete="current-password"
                placeholder="Enter your password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div> */}
            <div id={`sign-up-token-container` + id} className="hidden">
              <p className="text-sm text-center py-2 pb-6 text-gray-400">We just sent you a temporary sign up code. Please check your inbox and paste the sign up code below.</p>
              <div className="mt-2">
                <input
                  id={`token` + id}
                  name="token"
                  type="text"
                  onChange={handleTokenChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Enter your sign up code"
                  required
                  className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 dark:text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-amber-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div>
            <button
              disabled={isLoading}
              ref={buttonRef}
              type="submit"
              className="flex relative w-full justify-center rounded-md disabled:bg-amber-600/30 bg-amber-600 disabled:dark:bg-amber-600/30 dark:bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 disabled:text-transparent text-white shadow-sm hover:bg-amber-400 dark:hover:bg-amber-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
              onClick={handleSignIn}
            >
              Continue
              <div className="inline-loader absolute" style={{ left: 'calc(50% - 7.5px)', top: 'calc(50% - 7.5px)', display: isLoading ? 'inline' : 'none' }}></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const EmailOTPSignOut = () => {
  const supabase = useSupabaseClient()

  const handleSignOut = () => {
    supabase.signOut()
  }

  return (
    <div id="supabase-signout" className="sm:mx-auto sm:w-full sm:max-w-[480px] hidden">
      <div className="px-6 pt-6 pb-10 sm:rounded-lg sm:px-12">
        <button
          type="submit"
          className="flex w-full justify-center rounded-md bg-amber-600 dark:bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-400 dark:hover:bg-amber-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
          onClick={handleSignOut}
        >
          Sign out
        </button>
      </div>
    </div>
  )
}

const TeamspaceSignOut = () => {
  const supabase = useSupabaseClient()

  useEffect(() => {
    async function fetchMetaData() {
      try {
        const meta = await supabase.getUserMetaData()
        if (meta.cloudKitUserId) {
          document.getElementById('teamspace-signout')?.classList.remove('hidden')
        }
        // Do something with the meta data
      } catch {
        console.log('Not signed into a teamspace')
      }
    }
    fetchMetaData()
  }, [supabase])

  const handleSignOut = async () => {
    await supabase.signOut()
    // reload the page
    window.location.reload()
  }

  return (
    <div id="teamspace-signout" className="mx-auto max-w-[300px] pb-2 hidden">
      <div className=" p-4 mb-4 text-sm border border-yellow-200 dark:border-yellow-600 rounded-md dark:bg-yellow-600/30 bg-yellow-100">
        <div className="flex items-center space-x-2">
          <i className="far fa-triangle-exclamation text-lg text-yellow-600 dark:text-yellow-300 -mt-2"></i>
          <p className="text-gray-600 dark:text-gray-300 text-sm font-bold">Signed into a Teamspace</p>
        </div>
        <span className="text-gray-600 dark:text-gray-300 text-sm text-left pt-0 mt-0">
          You are still signed into a Teamspace, but were auto-signed out from iCloud. You can sign out of the teamspace now.
        </span>

        <div className="mt-4 mx-auto max-w-[217.3px]">
          <div className="rounded-lg">
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-amber-600 dark:bg-amber-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-amber-400 dark:hover:bg-amber-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
              onClick={handleSignOut}
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const SignIn = () => {
  const handleSignOutClick = () => {
    document.getElementById('sign-out-container')?.classList.add('hidden')
    document.getElementById('authContainer')?.classList.add('hidden')
  }

  const handleTeamspaceClick = (e) => {
    // Check if the background was clicked "teamspace-sign-in-background"
    if (e.target.id !== 'teamspace-sign-in-background') return

    document.getElementById('teamspace-sign-in-container')?.classList.add('hidden')
    document.getElementById('authContainer')?.classList.add('hidden')
  }

  const handleContinueWithEmailClick = () => {
    const emailSignIn = document.getElementById('email-sign-in')
    if (emailSignIn) {
      document.getElementById('continue-with-email')?.classList.add('hidden')
      emailSignIn.classList.remove('hidden')
      emailSignIn.classList.add('opacity-0')
      setTimeout(() => {
        emailSignIn.classList.remove('opacity-0')
      }, 10)
    }
  }

  return (
    <div className="fullscreen flex-center fixed z-40 hidden" id="authContainer">
      {/* Teamspace Sign in (Supabase) */}
      <div
        className="fullscreen backdrop-brightness-75 dark:backdrop-brightness-150 backdrop-blur-md backdrop-grayscale z-60 hidden"
        id="teamspace-sign-in-container"
        onClick={handleTeamspaceClick}
      >
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8" id="teamspace-sign-in-background">
          <div className="pt-4 sm:mx-auto sm:w-full bg-white dark:bg-[#1f1f1f] drop-shadow-2xl rounded-lg text-center sm:max-w-[440px]">
            <NotePlanIcon />
            <h2 className="mt-2 text-center text-3xl font-medium leading-9 tracking-tight text-gray-700 dark:text-gray-200">NotePlan</h2>
            <EmailOTPSignIn isTeamspace={true} id="1" />
          </div>
        </div>
      </div>

      {/* Regular Sign in */}
      <div className="fullscreen hidden bg-white z-50 dark:bg-[#1f1f1f]" id="sign-in-container">
        <div className="overflow-auto max-h-[50vh] flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <NotePlanIcon />
          <p className="text-center mt-8">
            Welcome to the web version of NotePlan!<br></br>Existing or new, sign in to get started.
          </p>

          <div className="mt-0 sm:mx-auto sm:w-full sm:max-w-[480px]">
            {/* CloudKit */}
            <div className="px-6 pt-10 sm:rounded-lg sm:px-12" id="cloudkit-sign-in">
              {/* Teamspace sign out if user is still signed in, but auto-signed out with CloudKit */}
              <TeamspaceSignOut />

              <div className="mx-auto relative w-[217.3px] h-[39.3px] border border-dashed rounded-md p-0 border-slate-400">
                <button id="apple-sign-in-button" className="bg-transparent absolute -left-0.5 -top-0.5 p-0 m-0"></button>
                <div className="inline-loader absolute" style={{ left: 'calc(50% - 7.5px)', top: 'calc(50% - 7.5px)', zIndex: -1 }}></div>
              </div>
            </div>

            {/* Supabase */}
            <div id="continue-with-email" className="flex justify-center items-center mt-3">
              <button
                disabled={false}
                onClick={handleContinueWithEmailClick}
                className="disabled:opacity-30 flex font-medium items-center text-[1.1rem] justify-center rounded-md w-[218px] h-[40px] dark:bg-gray-600 bg-gray-200 enabled:dark:hover:bg-gray-700 enabled:hover:bg-gray-300 transition"
              >
                Continue with Email
              </button>
            </div>

            <div id="email-sign-in" className="hidden transition-opacity duration-500">
              <EmailOTPSignIn isTeamspace={false} id="2" />
            </div>
            {/* Show a collapsible FAQ section here */}
            <hr className="mt-24 "></hr>
            <div className=" dark:text-gray-200">
              <details className="group">
                <summary className="cursor-pointer text-md font-medium opacity-70 dark:opacity-80">Problems signing in?</summary>
                <div className="mt-6 text-sm text-left">
                  <p>Apple ID:</p>
                  <ul className="list-disc pl-4">
                    <li>Turn off Advanced Data Protection in your macOS/iOS System Settings... &gt; Apple ID &gt; iCloud (scroll down and turn off).</li>
                    <li>Turn off browser extensions (or try in a private window first).</li>
                    <li>Try a different browser (Safari or Chrome).</li>
                  </ul>
                </div>
                <div className="mt-6 mb-8 text-sm text-left">
                  <p>Email:</p>
                  <ul className="list-disc pl-4">
                    <li>Refresh your email client and check the Spam folder to see if you have received the one time password.</li>
                    <li>Wait up to a minute to get the one time password.</li>
                    <li>Try again in 1-2 minutes, if you have signed in too often.</li>
                  </ul>
                  <p className="mt-4">If you still have issues write us (hello@noteplan.co).</p>
                </div>
              </details>
              <details className="group">
                <summary className="cursor-pointer text-md font-medium opacity-70 dark:opacity-80">Is my data encrypted?</summary>
                <div className="mt-6 text-sm text-left">
                  <p>Apple ID:</p>
                  Your data is stored on CloudKit (iCloud). Apple&apos;s iCloud servers{' '}
                  <a
                    href="https://developer.apple.com/documentation/cloudkit/encrypting_user_data"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-blue-600 dark:text-blue-400 hover:underline"
                  >
                    use encryption
                  </a>{' '}
                  to ensure only authorized users can access data. We are not using an additional layer of encryption. The data is encrypted in transit by TLS (HTTPS).
                </div>
                <div className="mt-6 text-sm text-left">
                  <p>Email:</p>
                  Your data is stored with a database provider (Supabase) and is{' '}
                  <a href="https://supabase.com/security" target="_blank" rel="noreferrer noopener" className="text-blue-600 dark:text-blue-400 hover:underline">
                    encrypted at rest
                  </a>{' '}
                  by the provider, we are not using an additional layer of encryption. It is encrypted in transit by TLS (HTTPS).
                </div>

                <div className="mt-6 text-sm text-left">
                  <p>Will you add E2EE?</p>
                  We are planning to add local encryption, so it will be full E2EE instead of using the database provided encryption (which slows down downloads).
                </div>
              </details>
            </div>
          </div>
        </div>
      </div>

      {/* Sign Out */}
      <div
        className="fullscreen dark:backdrop-brightness-150 backdrop-brightness-75 backdrop-blur-md backdrop-grayscale z-60 hidden"
        id="sign-out-container"
        onClick={handleSignOutClick}
      >
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="pt-4 sm:mx-auto sm:w-full text-center bg-white dark:bg-[#1f1f1f] drop-shadow-2xl rounded-lg sm:max-w-[340px]">
            <NotePlanIcon />
            <h2 className="mt-2 text-center text-3xl font-medium leading-9 tracking-tight text-gray-700 dark:text-gray-200">NotePlan</h2>

            {/* Supabase */}
            <EmailOTPSignOut />

            {/* CloudKit */}
            <div className="px-6 py-12 sm:px-12" id="apple-sign-out-button-container">
              <button id="apple-sign-out-button" className="bg-transparent"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn
