/* eslint-disable no-console */
import { useQueryClient } from '@tanstack/react-query'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { useUserState } from '../providers/UserProvider'
import { useCachedNotesQueryClient } from '../providers/CachedNotesProvider'
import { Note, NoteType, isCalendarNote } from '../utils/syncUtils'
import { isTeamspaceNote } from '../utils/syncUtils'
import { cacheKeys, projectNoteQueryKey } from '../utils/queryKeyFactory'
import { useSafeMutation } from './useSafeMutation'
import { deleteReferenceCache } from './useNoteReferences'
import { mapDeletes } from '../utils/mapAsState'

type DeleteOptions = { recordName: string; noteType: NoteType; descendenceIds?: string[] }

export default function useDeleteNote() {
  const ck = useCloudKitClient()
  const sb = useSupabaseClient()
  const user = useUserState()
  const privateUserId = user?.cloudKitUserId || user?.supabaseUserId
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()

  return useSafeMutation({
    mutationFn: async ({ recordName, noteType, descendenceIds }: DeleteOptions) => {
      console.debug('[useDeleteNote] deleting', recordName)

      // Teamspace notes are managed by supabase
      if (user.cloudKitUserId && !isTeamspaceNote(noteType)) {
        const ids = [recordName, ...(descendenceIds || [])].reverse()
        return ck.deleteNotes(ids)
      }

      if (user.supabaseUserId) {
        return sb.deleteNote(recordName)
      }

      throw new Error('Not signed in')
    },
    onMutate: ({ recordName, noteType, descendenceIds }: DeleteOptions) => {
      console.debug('[useDeleteNote] onMutate', recordName, noteType, descendenceIds)
      let previousNotes: Map<string, Note> = new Map()

      if (isTeamspaceNote(noteType)) {
        // cancel any outgoing refetches (so they don't overwrite our optimistic update)
        cachedNotesQueryClient.cancelQueries(cacheKeys.team(user.supabaseUserId))
        // snapshot the previous value
        previousNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.team(user.supabaseUserId))
      } else {
        // cancel any outgoing refetches (so they don't overwrite our optimistic update)
        cachedNotesQueryClient.cancelQueries(cacheKeys.private(privateUserId))
        // snapshot the previous value
        previousNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.private(privateUserId))
      }

      // optimistically remove the note
      try {
        cachedNotesQueryClient.setQueriesData<Map<string, Note>>(cacheKeys.notes, (oldData: Map<string, Note>) => {
          if (!oldData) {
            return new Map()
          }
          return mapDeletes(oldData, [recordName, ...(descendenceIds || [])])
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in setQueriesData:', error)
      }

      queryClient.removeQueries(projectNoteQueryKey({ recordName, noteType }))

      // return a context object with the snapshotted value
      return previousNotes
    },
    onSuccess: (_data, { recordName, noteType, descendenceIds }: DeleteOptions) => {
      console.log('[useDeleteNote] onSuccess', recordName, noteType, descendenceIds)

      // Just update the regular notes references, you can't normally delete calendar notes
      if (!isCalendarNote(noteType)) {
        for (const id of [recordName, ...(descendenceIds || [])]) {
          deleteReferenceCache(queryClient, id, user)
        }
      }
    },
    onError: (_err, variables: DeleteOptions, context: Map<string, Note>) => {
      console.error('[useDeleteNote] onError', _err, variables, context)
      if (isTeamspaceNote(variables.noteType)) {
        cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(user.supabaseUserId), context)
      } else {
        cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), context)
      }
    },
  })
}
