/* eslint-disable no-console */

import { SupabaseClient } from '@supabase/supabase-js'
import { UserMetaData } from '../../providers/UserProvider'

export class UserManager {
  private supabase: SupabaseClient

  constructor(supabaseClient: SupabaseClient) {
    this.supabase = supabaseClient
  }

  public async setUserMetaData(meta: UserMetaData) {
    // Get the user id
    const { data: userData, error: userError } = await this.supabase.auth.getUser()

    if (userError) {
      console.log(userError)
      throw userError
    }

    // Update the meta column in the user_profiles table
    const { error } = await this.supabase.from('user_profiles').update({ meta: meta }).eq('id', userData.user.id)

    if (error) {
      console.log(error)
      throw error
    }

    return true
  }

  public async getUserMetaData(): Promise<UserMetaData> {
    // Get the user id
    const { data: userData, error: userError } = await this.supabase.auth.getUser()

    if (userError) {
      console.log(userError)
      throw userError
    }

    console.log('userData', userData)

    // Update the meta column in the user_profiles table
    const { data, error } = await this.supabase.from('user_profiles').select('meta').eq('id', userData.user.id)

    if (error) {
      console.log(error)
      throw error
    }

    return data?.[0]?.meta
  }
}
