import { useQueryClient } from '@tanstack/react-query'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import { Note, Change, isTeamspaceNote } from '../utils/syncUtils'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { cacheKeys, noteQueryKey } from '../utils/queryKeyFactory'
import { useUserState } from '../providers/UserProvider'
import { useCachedNotesQueryClient } from '../providers/CachedNotesProvider'
import { useSafeMutation } from './useSafeMutation'
import { updateReferencesCache } from './useNoteReferences'
import { mapSet } from '../utils/mapAsState'

export default function useSaveNote(successCallback?: (_data: Note | undefined) => void) {
  const ck = useCloudKitClient()
  const sb = useSupabaseClient()
  const user = useUserState()
  const privateUserId = user?.cloudKitUserId ?? user?.supabaseUserId

  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()

  return useSafeMutation<Note, Error, Change>({
    mutationFn: (change: Change) => {
      if (!change.recordName && !change.filename) {
        return Promise.resolve(undefined)
      }
      // always get the latest version of the RecordChangeTag
      let note = queryClient.getQueryData<Note>(noteQueryKey(change))

      // Used by starter note creator, because we don't create the note by opening the calendar date
      if (change.forceCreate) {
        note = {
          recordName: change.recordName,
          noteType: change.noteType,
          parent: change.parent, // Assign ID of parent in case it's supabase, ignored by CloudKit
          content: change.content,
          filename: change.filename,
        }
      }

      // eslint-disable-next-line no-console
      console.debug('[useSaveNote] saving', note)

      // Teamspace notes are managed by supabase
      if (user.cloudKitUserId && !isTeamspaceNote(note?.noteType)) {
        return ck.saveNote(note, change.content, change.attachments ?? [], change.modificationDate)
      }

      if (user.supabaseUserId) {
        return sb.saveNote(user.supabaseUserId, note, change.content, change.attachments ?? [])
      }

      throw new Error('Not signed in')
    },
    onSuccess: (note: Note, change: Change) => {
      // eslint-disable-next-line no-console
      console.log('[useSaveNote] onSuccess')
      if (note && change.noteType !== undefined) {
        updateReferencesCache(queryClient, cachedNotesQueryClient, note, user)
        queryClient.setQueryData(noteQueryKey(change), note)
        if (isTeamspaceNote(note.noteType)) {
          cachedNotesQueryClient.setQueriesData(cacheKeys.team(user.supabaseUserId), (oldData: Map<string, Note>) => {
            return mapSet(oldData, note.recordName, note)
          })
        } else {
          cachedNotesQueryClient.setQueriesData(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
            return mapSet(oldData, note.recordName, note)
          })
        }
      }
      successCallback?.(note)
    },
    onError: (error, change) => {
      // eslint-disable-next-line no-console
      console.error('[useSaveNote] onError ' + error)
      if (('' + error).includes('oplock error')) {
        // eslint-disable-next-line no-console
        console.error('[useSaveNote] oplock error, refetching the note (we need the new recordChangeTag)')

        // Refetch the note upon error, so the next time it works. This would be merged once we receive it in TipTapEditor
        queryClient.invalidateQueries(noteQueryKey(change))
      }
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}
