import { range } from 'lodash'
import CurrentTimeLine from '../../../../components/CurrentTimeLine'
import { throttleSetTemporaryEvent } from '../utils/throttleSetTemporaryEvent'
import { createEventOnDrop } from '../utils/createEventOnDrop'
import { cleanUpDraggedEvent } from '../utils/cleanUpDraggedEvent'
import { useState } from 'react'

function VerticalLines({
  timelineDays,
  currentEvents,
  calendarEventDrag,
  selectedDay,
  currentTime,
  scrollToCurrentLine,
  setCalendarEventDrag,
  setPlaceholderEvent,
  onUpdateCurrentEvents,
  currentLineRef,
}) {
  const [isDraggingInside, setIsDraggingInside] = useState<boolean>(false)
  const [startingDayOfDragging, setStartingDayOfDragging] = useState(null)

  return (
    <div id="week-columns" className={`col-start-1 col-end-2 row-start-1 hidden grid-cols-${timelineDays} grid-rows-1 divide-x !grid`}>
      {range(timelineDays).map((day) => {
        const startDate = selectedDay.clone().add(day, 'day').startOf('day').toDate()
        const endDate = selectedDay.clone().add(day, 'day').endOf('day').toDate()
        const isCurrentDay = startDate <= currentTime && currentTime <= endDate
        const totalMinutes = 24 * 60 // Total minutes in 24 hours
        const currentMinutes = currentTime.getHours() * 60 + currentTime.getMinutes()
        const currentLinePosition = (currentMinutes / totalMinutes) * 100
        return (
          <div
            id={`week-column-${day + 1}`}
            key={`week-column-${day + 1}`}
            className={`week-column col-start-${day + 1} row-span-full relative z-0`}
            data-date={selectedDay.add(day, 'day').startOf('day').format('YYYY-MM-DD')}
            onDrop={(e) => {
              setStartingDayOfDragging(null)
              createEventOnDrop(e, currentEvents, calendarEventDrag, setCalendarEventDrag, setPlaceholderEvent)
            }}
            onDragOver={(e) => {
              e.preventDefault()
              if (isDraggingInside) {
                throttleSetTemporaryEvent(e, currentEvents, onUpdateCurrentEvents, setPlaceholderEvent, calendarEventDrag, startingDayOfDragging, setStartingDayOfDragging)
              } else {
                setPlaceholderEvent(null)
              }
            }}
            onDragLeave={(e) => {
              e.preventDefault()
              // The temporary event is created with a throttle, so setting it here to null will not work
              throttleSetTemporaryEvent.cancel()

              setPlaceholderEvent(null)
              setIsDraggingInside(false)
              setStartingDayOfDragging(null)

              // TODO: This is not ideal if the user starts dragging an event, moves it outside the timeline and then back in
              cleanUpDraggedEvent(setCalendarEventDrag)
            }}
            onDragEnter={(e) => {
              e.preventDefault()
              setIsDraggingInside(true)
            }}
            onDragEnd={(e) => {
              e.preventDefault()
              setPlaceholderEvent(null)
            }}
          >
            {/* TODO: If we drag over the current time line, it causes to trigger "onDragLeave" */}
            {isCurrentDay && <CurrentTimeLine currentLineRef={currentLineRef} currentLinePosition={currentLinePosition} onScrollToLine={scrollToCurrentLine} />}
          </div>
        )
      })}
    </div>
  )
}

export default VerticalLines
