import getGroupedEvents from '../utils/getGroupedEvents'
import calculateOffsetAndHeight from '../utils/calculateOffsetAndHeight'
import MultipleEvents from './MultipleEvents'
import SingleEvent from './SingleEvent'
import { CalendarEventsProps } from '../types'
import { useGoogleCalendarEvents } from '../../../../hooks/useGoogleCalendarEvents'

export function CalendarEvents({ setCalendarEventDrag, selectedDay, currentEvents, placeholderEvent }: CalendarEventsProps): JSX.Element {
  const parent = document.getElementById('week-columns')
  let segmentHeight = 28
  if (parent) {
    segmentHeight = parent.getBoundingClientRect().height / 97
  }

  const currentEventsToday = currentEvents.filter((e) => e.startDate.isSame(selectedDay, 'day'))
  const { data: googleEvents } = useGoogleCalendarEvents(selectedDay)
  const combinedEvents = googleEvents ? [...currentEventsToday, ...googleEvents.filter(({ allDay }) => !allDay)] : currentEventsToday

  // Filter out events that have a description that starts with "NPTB:", these are timeblocks created by NotePlan
  const groupedEvents = getGroupedEvents(
    combinedEvents.filter((event) => {
      return !event.description?.startsWith('NPTB:')
    })
  )

  const eventElements = groupedEvents
    .filter((group) => group.length > 0)
    .map((group) => {
      if (group.length === 1) {
        return <SingleEvent key={`event-${group[0].id}`} event={group[0]} segmentHeight={segmentHeight} setCalendarEventDrag={setCalendarEventDrag} />
      } else {
        return <MultipleEvents key={`event-group-${group[0].startDate.toISOString()}`} events={group} segmentHeight={segmentHeight} setCalendarEventDrag={setCalendarEventDrag} />
      }
    })

  if (placeholderEvent) {
    const { eventHeight, offsetPxTop } = calculateOffsetAndHeight(placeholderEvent, segmentHeight)

    if (!isNaN(eventHeight)) {
      eventElements.push(
        <li
          key={`event-placeholder`}
          id={`event-placeholder`}
          className="border-2 border-orange-500 rounded-lg flex col-start-1"
          style={{ gridRow: placeholderEvent.position, transform: `translateY(${offsetPxTop}px)`, height: eventHeight - 2 }}
        />
      )
    }
  }

  return <>{eventElements}</>
}
