import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useGapiIsReady } from '../providers/GapiProvider'
import { accessTokenContext, useAccessToken } from '../providers/AccessTokenProvider'
import { CalendarEvent, calculateEventPosition } from '../modules/calendar/CalendarEvent'
import React from 'react'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const gapiEventsToCalendarEvents = (data: gapi.client.calendar.Event[]): CalendarEvent[] => {
  return data.map((event) => {
    const start = event.start.dateTime || event.start.date
    const end = event.end.dateTime || event.end.date
    return {
      id: event.id,
      content: event.summary,
      position: calculateEventPosition(dayjs(start), dayjs(end)),
      startDate: dayjs(start),
      endDate: dayjs(end),
      contentType: 'event',
      allDay: event.start.date ? true : false,
      description: event.description,
    }
  })
}

export function useGoogleCalendarEvents(selectedDay: dayjs.Dayjs) {
  const key: string = selectedDay.format('YYYYMMDD')
  const gapiIsReady = useGapiIsReady()
  const { data: accessToken, isStale } = useAccessToken()
  const accessTokenQueryClient = React.useContext(accessTokenContext)
  if (accessTokenQueryClient === undefined) {
    throw new Error('useGoogleCalendarEvents must be used within a accessTokenContext')
  }
  const hasInvalidatedQueries = React.useRef(false) // to avoid infinite rendering loops

  const queryResult = useQuery<gapi.client.calendar.Event[], gapi.client.HttpRequestRejected, CalendarEvent[], string[]>({
    enabled: !!accessToken && !isStale && gapiIsReady,
    refetchInterval: 1000 * 60, // every minute
    retry: false, // no need because we refetch every minute anyway and we need it to fail fast for 401 and 403 errors
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['googleCalendarEvents', key],
    queryFn: async () => {
      const request = {
        calendarId: 'primary',
        // select only events from selectedDay
        timeMin: selectedDay.startOf('day').toISOString(),
        timeMax: selectedDay.endOf('day').toISOString(),
        showDeleted: false,
        singleEvents: true,
        orderBy: 'startTime' as gapi.client.calendar.EventsOrder,
      }
      const response = gapi.client.calendar && (await gapi.client.calendar.events.list(request))
      hasInvalidatedQueries.current = false
      return response.result.items
    },
    select: gapiEventsToCalendarEvents, // use stable function to avoid running every render
  })
  // handle 401 and 403 error
  const { error } = queryResult
  if (!isStale && (error?.status == 401 || error?.status == 403) && !hasInvalidatedQueries.current) {
    hasInvalidatedQueries.current = true
    accessTokenQueryClient.invalidateQueries()
  }
  return queryResult
}
