import dayjs, { Dayjs } from 'dayjs'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { SourceDatabase } from '../utils/syncUtils'

type active = 'day' | 'week'
export type SelectedDate = { active: active; date: Dayjs; week: number; year: number; teamspace?: string; source?: SourceDatabase }
export type SelectedDateAction =
  | { type: 'today'; forceDay?: boolean }
  | { type: 'add' | 'subtract'; amount: number; unit: dayjs.ManipulateType }
  | { type: 'shift'; direction: number }
  | { type: 'transform'; to: active; teamspace?: string }
  | { type: 'setWeek'; week: number; year: number }
  | { type: 'setDay'; date: Dayjs }

export const SelectedDateContext = createContext<SelectedDate>(null)
export const SelectedDateDispatchContext = createContext<React.Dispatch<SelectedDateAction>>(null)

export function useSelectedDate() {
  const context = useContext(SelectedDateContext)
  if (context === undefined) {
    throw new Error('useSelectedDate must be used within a SelectedDateProvider')
  }
  return context
}

export function useSelectedDateDispatch() {
  const context = useContext(SelectedDateDispatchContext)
  if (context === undefined) {
    throw new Error('useSelectedDateDispatch must be used within a SelectedDateProvider')
  }
  return context
}

export function dateToWeek(date: Dayjs): { week: number; year: number } {
  return { week: date.week(), year: date.year() }
}

export function weekToDate({ date, week, year }: { date?: Dayjs; week: number; year: number }): Dayjs {
  if (date && date.week() === week && date.year() === year) {
    return date
  }
  return dayjs().year(year).week(week)
}

export function selectedDateToDay(date: SelectedDate): Dayjs {
  if (date?.active === 'week') {
    return weekToDate(date)
  }
  return date?.date
}

export function selectedDateToWeek(date: SelectedDate): { week: number; year: number } {
  if (date.active === 'week') {
    return { week: date.week, year: date.year }
  }
  return dateToWeek(date.date)
}

export function selectedDateToKey(date: SelectedDate): string {
  if (date.active === 'week') {
    return `${date.year}-W${date.week.toString().padStart(2, '0')}`
  }
  return date.date.format('YYYYMMDD')
}

export function isSameWeek(a: SelectedDate, b: { week: number; year: number }): boolean {
  if (a.active === 'day') {
    return a.date.week() === b.week && a.date.year() === b.year
  }
  return a.week === b.week && a.year === b.year
}

export function isSameDay(a: SelectedDate, b: Dayjs): boolean {
  if (a.active === 'day') {
    return a.date.isSame(b, 'day')
  }
  return false
}

function selectedDateReducer(state: SelectedDate, action: SelectedDateAction) {
  switch (action.type) {
    case 'today': {
      if (state === null || action.forceDay || state.active === 'day') {
        return { active: 'day' as active, date: dayjs(), week: dayjs().week(), year: dayjs().year() }
      }
      return { active: 'week' as active, ...dateToWeek(dayjs()), date: dayjs() }
    }
    case 'add': {
      if (state.active === 'week') {
        const newDate = weekToDate(state).add(action.amount, action.unit)
        return { ...state, week: newDate.week(), year: newDate.year() }
      }
      const newDate = state.date.add(action.amount, action.unit)
      return { ...state, date: newDate, week: newDate.week(), year: newDate.year() }
    }
    case 'subtract': {
      if (state.active === 'week') {
        const newDate = weekToDate(state).subtract(action.amount, action.unit)
        return { ...state, week: newDate.week(), year: newDate.year() }
      }
      const newDate = state.date.subtract(action.amount, action.unit)
      return { ...state, date: newDate, week: newDate.week(), year: newDate.year() }
    }
    case 'shift': {
      if (state.active === 'week') {
        const newDate = weekToDate(state).add(action.direction, 'week')
        return { ...state, week: newDate.week(), year: newDate.year() }
      }
      const newDate = state.date.add(action.direction, 'day')
      return { ...state, date: newDate, week: newDate.week(), year: newDate.year() }
    }
    case 'transform': {
      if (action.to === 'day' && (state.active === 'week' || state.teamspace !== action.teamspace)) {
        return { ...state, active: 'day' as active, teamspace: action.teamspace }
      }
      if (action.to === 'week' && (state.active === 'day' || state.teamspace !== action.teamspace)) {
        return { ...state, active: 'week' as active, teamspace: action.teamspace }
      }
      return state
    }
    case 'setWeek': {
      return { ...state, active: 'week' as active, week: action.week, year: action.year, date: weekToDate(action) }
    }
    case 'setDay': {
      return { ...state, active: 'day' as active, date: action.date, week: action.date.week(), year: action.date.year() }
    }
  }
}

export default function SelectedDateProvider({ children }: { children: React.ReactNode }) {
  const [selectedDate, dispatch] = useReducer(selectedDateReducer, { active: 'day' as active, date: dayjs(), week: dayjs().week(), year: dayjs().year() })

  // keyboard shortcuts
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      let direction = null

      if (e.metaKey && e.ctrlKey) {
        // handle Ctrl+CMD+Left/Right to switch days
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          direction = e.key === 'ArrowLeft' ? -1 : 1
        }

        // handle Ctrl+CMD+Up/Down to switch weeks
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          direction = e.key === 'ArrowUp' ? -7 : 7
        }

        if (direction !== null) {
          e.preventDefault()
          dispatch({ type: 'shift', direction: direction })
          e.stopPropagation()
        }
      }

      // handle Ctrl+T to switch to today
      if (e.key === 't' && e.ctrlKey) {
        e.preventDefault()
        dispatch({ type: 'today', forceDay: true })
        e.stopPropagation()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [dispatch])

  return (
    <SelectedDateContext.Provider value={selectedDate}>
      <SelectedDateDispatchContext.Provider value={dispatch}>{children}</SelectedDateDispatchContext.Provider>
    </SelectedDateContext.Provider>
  )
}
