import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import { useUserState } from '../providers/UserProvider'

export function useFetchUserDefault(key1: string, key2?: string): UseQueryResult<string | undefined> {
  const ck = useCloudKitClient()
  const user = useUserState()

  return useQuery({
    enabled: !!user?.cloudKitUserId,
    queryKey: ['userDefault', key1, key2],
    queryFn: async () => {
      const value1 = await ck.fetchUserDefault(key1)
      if (value1) {
        return value1
      }
      return key2 ? ck.fetchUserDefault(key2) : null
    },
  }) // No separate dependency array needed
}
