import { QueryKey, useQueryClient } from '@tanstack/react-query'
import { Note, NoteType, isPrivateNote, isTeamspaceNote } from '../utils/syncUtils'
import { useUserState } from '../providers/UserProvider'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { updateNote, useCachedNotesQueryClient, useNotesExtension } from '../providers/CachedNotesProvider'
import { cacheKeys, noteQueryKey } from '../utils/queryKeyFactory'
import { useCloudKitClient } from '../providers/CloudKitClientProvider'
import { SidebarEntry } from '../modules/sidebar/SidebarBuilder'
import { useSafeMutation } from './useSafeMutation'
import { mapDelete, mapSet } from '../utils/mapAsState'

type MoveNoteOption = {
  recordName: string
  noteType: NoteType
  parentRecordName: string | null
  parentNoteType: NoteType | null
  children: SidebarEntry[]
}

// TODO: This is a bit of a mess, but it works. Refactor this to be more readable
export default function useMoveNote() {
  const user = useUserState()
  const privateUserId = user?.cloudKitUserId || user?.supabaseUserId
  const teamUserId = user?.supabaseUserId
  const sb = useSupabaseClient()
  const ck = useCloudKitClient()
  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()
  const { data: ext } = useNotesExtension(user)

  return useSafeMutation({
    mutationFn: async ({ recordName, parentRecordName, noteType, parentNoteType, children }: MoveNoteOption) => {
      if (user.cloudKitUserId) {
        const privateNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.private(privateUserId))
        const teamNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.team(teamUserId))
        if (isTeamspaceNote(noteType)) {
          if (isPrivateNote(parentNoteType) || parentRecordName === null) {
            // eslint-disable-next-line no-console
            console.debug('[useMoveNote] Moving teamspace note', recordName, 'to private notes', parentRecordName)
            const changedNotes = compileTeamNotesToPrivate(privateNotes, teamNotes, ext, recordName, parentRecordName, children)
            const newNotes: Map<string, Note> = new Map()
            for (const note of changedNotes) {
              mapSet(newNotes, note.recordName, await ck.createNote(user.cloudKitUserId, note))
              await sb.deleteNote(note.recordName)
            }
            return newNotes
          }
          // Do nothing if moving within teamspaces because this will be handled further down
        } else {
          if (isTeamspaceNote(parentNoteType)) {
            // eslint-disable-next-line no-console
            console.debug('[useMoveNote] Moving private note', recordName, 'to teamspace', parentRecordName)
            if (children?.length > 0) {
              // Case: moving a folder
              const [changedNotes, order] = compilePrivateNotesToTeamspace(privateNotes, recordName, parentRecordName, children)
              for (const recordName of order) {
                if (changedNotes.has(recordName)) {
                  await sb.createNote(user.supabaseUserId, changedNotes.get(recordName))
                }
              }
              await ck.deleteNotes(order)
              return changedNotes
            } else {
              // Case: moving a note
              const note: Note = privateNotes.get(recordName)
              if (note) {
                const newNote: Note = { ...note, parent: parentRecordName, noteType: NoteType.TEAM_SPACE_NOTE }
                await sb.createNote(user.supabaseUserId, newNote)
                await ck.deleteNotes([recordName])
                return new Map([[recordName, newNote]])
              }
            }
          } else {
            // eslint-disable-next-line no-console
            console.debug('[useMoveNote] Moving private note', recordName, 'to', parentRecordName)
            const changedNotes = compileChangedNotesForCloudKit(privateNotes, recordName, parentRecordName, children)
            if (changedNotes) {
              return await ck.saveNoteMeta(changedNotes)
            } else {
              throw new Error('Couldnt find the note')
            }
          }
        }
      }

      if (user.supabaseUserId) {
        return sb.moveNote(privateUserId, recordName, parentRecordName, noteType, parentNoteType)
      }

      throw new Error('Not signed in')
    },
    onMutate: async ({ recordName, parentRecordName, noteType, parentNoteType, children }) => {
      // eslint-disable-next-line no-console
      console.debug('[useMoveNote] onMutate', recordName, parentRecordName)

      // Snapshot the previous values
      const previousPrivateNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.private(privateUserId))
      const previousTeamNotes = cachedNotesQueryClient.getQueryData<Map<string, Note>>(cacheKeys.team(teamUserId))

      let isDone = false

      if (user.cloudKitUserId) {
        // cancel any outgoing refetches (so they don't overwrite our optimistic update)
        cachedNotesQueryClient.cancelQueries(cacheKeys.notes)

        // Optimistically update to the new value
        if (isTeamspaceNote(noteType)) {
          // Case: move a teamspace note
          if (isPrivateNote(parentNoteType) || parentRecordName === null) {
            // Sub case: to private notes
            const changedNotes = compileTeamNotesToPrivate(previousPrivateNotes, previousTeamNotes, ext, recordName, parentRecordName, children)
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData].concat(changedNotes.map((note) => [note.recordName, note])))
            })
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData].filter(([recordName]) => !changedNotes.map((note) => note.recordName).includes(recordName)))
            })
            isDone = true
          }
          // Sub case: within teamspace
          // Do nothing because this will be handled further down
        } else {
          // Case: move a private note
          if (isTeamspaceNote(parentNoteType)) {
            // Sub case: to teamspace
            if (children?.length > 0) {
              // Sub sub case: moving a folder
              const [changedNotes] = compilePrivateNotesToTeamspace(previousPrivateNotes, recordName, parentRecordName, children)
              cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), (oldData: Map<string, Note>) => {
                return new Map([...oldData, ...changedNotes])
              })
              cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
                return new Map([...oldData].filter(([recordName]) => !changedNotes.has(recordName)))
              })
            } else {
              // Sub sub case: moving a single note
              const note = previousPrivateNotes.get(recordName)
              if (note) {
                cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), (oldData: Map<string, Note>) => {
                  return mapSet(oldData, note.recordName, { ...note, parent: parentRecordName, noteType: NoteType.TEAM_SPACE_NOTE })
                })
                cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
                  return mapDelete(oldData, recordName)
                })
              }
            }
            isDone = true
          } else {
            // Sub case: to root or within private notes
            const changedNotes: Array<Note> = compileChangedNotesForCloudKit(previousPrivateNotes, recordName, parentRecordName, children)
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData].concat(changedNotes.map((note) => [note.recordName, note])))
            })
            isDone = true
          }
        }
      }

      if (user.supabaseUserId && !isDone) {
        const note = cachedNotesQueryClient
          .getQueriesData<Map<string, Note>>(cacheKeys.notes)
          .reduce((acc: Map<string, Note>, [, map]: [QueryKey, Map<string, Note>]) => new Map([...acc, ...map]), new Map<string, Note>())
          .get(recordName)

        if (noteType === parentNoteType) {
          updateNote(cachedNotesQueryClient, privateUserId, teamUserId, { ...note, parent: parentRecordName })
        } else {
          // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
          cachedNotesQueryClient.cancelQueries(cacheKeys.notes)
          // Optimistically update to the new value
          if (isPrivateNote(noteType) && isTeamspaceNote(parentNoteType)) {
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData].filter(([recordName]) => recordName !== note.recordName))
            })
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), (oldData: Map<string, Note>) => {
              return mapSet(oldData, note.recordName, { ...note, parent: parentRecordName, noteType: NoteType.TEAM_SPACE_NOTE })
            })
          }
          if (isTeamspaceNote(noteType) && isPrivateNote(parentNoteType)) {
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData].filter(([recordName]) => recordName !== note.recordName))
            })
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
              return mapSet(oldData, note.recordName, { ...note, parent: parentRecordName, noteType: NoteType.PROJECT_NOTE })
            })
          }
        }
      }

      return { previousPrivateNotes, previousTeamNotes }
    },
    onError: (_error, _variables, context) => {
      cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), context.previousPrivateNotes)
      cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), context.previousTeamNotes)
    },
    onSuccess: (updatedNotes: Map<string, Note>, { noteType, parentNoteType }) => {
      // eslint-disable-next-line no-console
      console.debug('[useMoveNote] onSuccess', updatedNotes)

      if (user.cloudKitUserId) {
        if (isTeamspaceNote(noteType)) {
          if (isPrivateNote(parentNoteType) || parentNoteType === null) {
            // Case: moved a teamspace notes to private notes
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData, ...updatedNotes])
            })
            for (const [, note] of updatedNotes) {
              queryClient.setQueriesData(noteQueryKey(note), (oldData: Note) => {
                return { ...oldData, recordChangeTag: note.recordChangeTag }
              })
            }
          }
          // Case: moved a teamspace note within teamspace
        } else {
          if (isTeamspaceNote(parentNoteType)) {
            // Case: moved a private notes to teamspace
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData].filter(([recordName]) => !updatedNotes.has(recordName)))
            })
          } else {
            // Case: moved a private note within private notes
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData, ...updatedNotes])
            })
            for (const [, note] of updatedNotes) {
              queryClient.setQueriesData(noteQueryKey(note), (oldData: Note) => {
                return { ...oldData, recordChangeTag: note.recordChangeTag }
              })
            }
          }
        }
      }

      if (user.supabaseUserId) {
        if (isTeamspaceNote(noteType)) {
          if (isTeamspaceNote(parentNoteType)) {
            // case: moved a teamspace note within teamspace
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData, ...updatedNotes])
            })
          } else {
            // case: moved a teamspace note to private notes
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData].filter(([recordName]) => !updatedNotes.has(recordName)))
            })
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData, ...updatedNotes])
            })
          }
        } else {
          if (isTeamspaceNote(parentNoteType)) {
            // case: moved a private note to teamspace
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData].filter(([recordName]) => !updatedNotes.has(recordName)))
            })
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(teamUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData, ...updatedNotes])
            })
          } else {
            // case: moved a private note within private notes
            cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.private(privateUserId), (oldData: Map<string, Note>) => {
              return new Map([...oldData, ...updatedNotes])
            })
          }
        }
      }
    },
  })
}

function compileChangedNotesForCloudKit(notes: Map<string, Note>, recordName: string, parentRecordName: string, children: SidebarEntry[]): Array<Note> {
  const note = notes.get(recordName)
  if (!note) return undefined

  const newParentNote = notes.get(parentRecordName)
  const filename = note.filename.split('/').pop()
  const changedNotes: Array<Note> = []
  if (!!newParentNote && newParentNote.isFolder) {
    // The new parent is a folder
    const newPath = newParentNote.filename + '/' + filename
    changedNotes.push({ ...note, filename: newPath })
    if (note.isFolder) {
      // recursively traverse the children and add to the recordsToSave
      changedNotes.push(...sidebarEntriesToNotes(notes, note.filename, newPath, children))
    }
  } else {
    // The new parent is root or invalid
    changedNotes.push({ ...note, filename })
    if (note.isFolder) {
      // recursively traverse the children and add to the recordsToSave
      changedNotes.push(...sidebarEntriesToNotes(notes, note.filename, filename, children))
    }
  }
  return changedNotes
}

function sidebarEntriesToNotes(notes: Map<string, Note>, oldPath: string, newPath: string, sidebarEntries: SidebarEntry[] = []): Array<Note> {
  const resultNotes: Array<Note> = []
  for (const entry of sidebarEntries) {
    const changedNote: Note = notes.get(entry.recordName)
    if (!changedNote) return

    // Prevent mutating the original object
    const filename = changedNote.filename
    resultNotes.push({ ...changedNote, filename: filename.replace(oldPath, newPath) })

    if (changedNote.isFolder && entry.children) {
      resultNotes.push(...sidebarEntriesToNotes(notes, oldPath, newPath, entry.children))
    }
  }
  return resultNotes
}

function compilePrivateNotesToTeamspace(notes: Map<string, Note>, recordName: string, parentRecordName: string, children: SidebarEntry[]): [Map<string, Note>, Array<string>] {
  const note: Note = notes.get(recordName)
  if (!note) return [new Map<string, Note>(), []]

  let changedNotes: Map<string, Note> = new Map([
    [note.recordName, { ...note, parent: parentRecordName, noteType: NoteType.TEAM_SPACE_NOTE, filename: note.filename.split('/').pop() }],
  ])
  const order: Array<string> = [note.recordName]
  if (note.isFolder && children.length > 0) {
    // recursively traverse the children and add to the changed notes
    children.forEach((entry: SidebarEntry) => {
      const [changedChildrenNotes, childrenOrder] = compilePrivateNotesToTeamspace(notes, entry.recordName, note.recordName, entry.children)
      changedNotes = new Map([...changedChildrenNotes, ...changedNotes])
      order.push(...childrenOrder)
    })
  }
  return [changedNotes, order]
}

function compileTeamNotesToPrivate(
  privateNotes: Map<string, Note>,
  teamNotes: Map<string, Note>,
  ext: string,
  recordName: string,
  parentRecordName: string,
  children: SidebarEntry[],
  parentPath?: string
): Array<Note> {
  const note: Note = teamNotes.get(recordName)
  if (!note) return []
  const changedNotes: Array<Note> = []
  let filename = note.title ? note.title : note.filename
  if (parentRecordName) {
    // Case: moving to a folder
    if (parentPath) {
      filename = note.isFolder ? parentPath + '/' + filename : parentPath + '/' + filename + '.' + ext
    } else {
      const parentNote: Note = privateNotes.get(parentRecordName)
      filename = note.isFolder ? parentNote.filename + '/' + note.title : parentNote.filename + '/' + note.title + '.' + ext
    }
    const newNote: Note = {
      ...note,
      filename,
      noteType: NoteType.PROJECT_NOTE,
    }
    changedNotes.push(newNote)
    if (note.isFolder && children?.length > 0) {
      // recursively traverse the children and add to the changed notes
      children.forEach((entry: SidebarEntry) => {
        const result: Array<Note> = compileTeamNotesToPrivate(privateNotes, teamNotes, ext, entry.recordName, note.recordName, entry.children, filename)
        changedNotes.push(...result)
      })
    }
  } else {
    // Case: moving to root
    const newNote: Note = {
      ...note,
      filename: note.isFolder ? filename : filename + '.' + ext,
      noteType: NoteType.PROJECT_NOTE,
    }
    changedNotes.push(newNote)
    if (note.isFolder && children?.length > 0) {
      // recursively traverse the children and add to the changed notes
      children.forEach((entry: SidebarEntry) => {
        const result: Array<Note> = compileTeamNotesToPrivate(privateNotes, teamNotes, ext, entry.recordName, note.recordName, entry.children, filename)
        changedNotes.push(...result)
      })
    }
  }
  return changedNotes
}
