import { Block, BlockNoteEditor, DefaultBlockSchema, PartialBlock, PartialInlineContent, findAttachmentURLs } from '@blocknote/core'
import { Note, SourceDatabase } from '../../utils/syncUtils'
import { SelectedDate } from '../../providers/SelectedDateProvider'
import styles from '../../../../../packages/core/src/extensions/Blocks/nodes/Block.module.css'
import classNames from 'classnames'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { DragEvent, useCallback, useEffect, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import useSaveNote from '../../hooks/useSaveNote'
import { useBlockNote } from '@blocknote/react'
import { WindowWithEditor } from './TipTapEditor'
import { InlineContent } from './NoteReference'

const LOCAL_STORAGE_PREFIX = 'REFERENCES_ITEM_OPEN_STATE_'

function SkeletonLoader() {
  const lineLengths = [80, 75, 65]
  return (
    <div>
      {lineLengths.map((length, index) => {
        return <div key={index} className="skeleton rounded-md h-4 my-3" style={{ width: `${length}%` }}></div>
      })}
    </div>
  )
}

// Converts a string into an integer
function hashValue(value: string) {
  if (typeof value !== 'string' || value.length === 0) return '0'
  let hash = 0,
    i,
    chr
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash.toString()
}

function ReferenceBlock({
  block,
  isSaving,
  onCheck,
  onBlockDrop,
}: {
  block: PartialBlock<DefaultBlockSchema>
  isSaving: boolean
  onCheck: (_id: string) => void
  onBlockDrop: (_e: DragEvent<HTMLDivElement>) => void
}) {
  const contentItem = block.content[0]
  const contentText = typeof contentItem === 'string' ? contentItem : 'text' in contentItem ? contentItem.text : ''

  // Create a hashvalue so we just store integers in localStorage, not the actual content
  const localStorageKey = `${LOCAL_STORAGE_PREFIX}-${hashValue(contentText)}`

  // Initialize isOpen state from localStorage or default to false
  const [isOpen, setIsOpen] = useState<boolean>(JSON.parse(localStorage.getItem(localStorageKey) || 'false'))
  // const [isOpen, setIsOpen] = useState(false);

  // Update localStorage whenever isOpen changes
  useEffect(() => {
    if (isOpen) {
      localStorage.setItem(localStorageKey, JSON.stringify(isOpen))
    } else {
      // Remove it from localstorage to save space, because if it doesn't exists it's false by default
      localStorage.removeItem(localStorageKey)
    }
  }, [isOpen, localStorageKey])

  return (
    <div className={styles.blockGroup} data-node-type="blockGroup">
      <div
        data-id={block.id}
        className={styles.blockOuter}
        data-node-type="block-outer"
        onDrop={(e: DragEvent<HTMLDivElement>) => {
          e.preventDefault()
          e.stopPropagation()
          e.currentTarget.dataset.dragOver = 'false'
          onBlockDrop(e)
        }}
        onDragOver={(e: DragEvent<HTMLDivElement>) => {
          e.preventDefault()
          e.stopPropagation()
          if (block.id !== 'top') {
            e.currentTarget.dataset.dragOver = 'true'
          }
        }}
        onDragLeave={(e: DragEvent<HTMLDivElement>) => {
          e.preventDefault()
          e.stopPropagation()
          e.currentTarget.dataset.dragOver = 'false'
        }}
        onDragEnd={(e: DragEvent<HTMLDivElement>) => {
          e.preventDefault()
          e.stopPropagation()
          e.currentTarget.dataset.dragOver = 'false'
        }}
        draggable={block.id !== 'top' && !isSaving}
        onDragStart={(e: DragEvent<HTMLDivElement>) => {
          e.stopPropagation()
          e.currentTarget.dataset.dragOver = 'false'
          const text = BlockNoteEditor.blocksToNotePlan([block as Block<DefaultBlockSchema>], false)
          const payload = JSON.stringify({ id: block.id, text })
          e.dataTransfer.clearData()
          e.dataTransfer.setData('text/plain', payload)
          e.dataTransfer.effectAllowed = 'move'
        }}
      >
        <Disclosure as="div" defaultOpen={isOpen} className={styles.block} data-node-type="blockContainer">
          {({ open }) => (
            <>
              <div
                className={classNames({
                  'flex items-center relative': true,
                  '-ml-5': block.children?.length > 0 && block.type !== 'heading',
                  '-ml-2': block.children?.length > 0 && block.type === 'heading',
                  'ml-2': block.children?.length === 0,
                })}
              >
                {block.children?.length > 0 && (
                  <Disclosure.Button onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)} className="text-left absolute left-0 -top-0.5">
                    <ChevronRightIcon
                      className={classNames({
                        'rotate-90': open,
                        'text-gray-500 dark:text-gray-200 h-4 w-4 shrink-0 -mr-1': true,
                      })}
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                )}
                <div
                  className={classNames({
                    [styles.blockContent]: true,
                    'text-zinc-500 dark:text-zinc-400 font-bold text-[13px]': block.type === 'heading',
                    'text-[15px] dark:opacity-80': block.type !== 'heading',
                    'ml-7': block.children?.length > 0,
                    'ml-0': block.children?.length === 0,
                  })}
                  data-content-type={block.type}
                  data-checked={block.props?.['checked'] ?? false}
                  data-cancelled={block.props?.['cancelled'] ?? false}
                  data-scheduled={block.props?.['scheduled'] ?? false}
                  data-flagged={block.props?.flagged ?? 0}
                  data-folded={block.props?.folded ?? false}
                  data-visible={block.props?.visible ?? true}
                >
                  {['taskListItem', 'checkListItem'].includes(block.type) && (
                    <label
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onCheck(block.id)
                      }}
                      className="absolute left-0 top-0"
                    >
                      <input type="checkbox" />
                    </label>
                  )}
                  <BlockContent content={block.content} isSaving={isSaving} />
                </div>
              </div>
              <Disclosure.Panel className={classNames({ '-ml-3': block.type !== 'heading' })}>
                {block.children.map((child) => (
                  <ReferenceBlock key={child.id} block={child} onCheck={onCheck} onBlockDrop={onBlockDrop} isSaving={isSaving} />
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}

function BlockContent({ content, isSaving }: { content: string | PartialInlineContent[]; isSaving: boolean }) {
  if (typeof content === 'string') {
    return <span className={isSaving ? 'cursor-default' : 'cursor-grab'}>{content}</span>
  } else {
    return <InlineContent content={content} className={isSaving ? 'cursor-default' : 'cursor-grab'} />
  }
}

function nestBlocks(blocks: PartialBlock<DefaultBlockSchema>[]) {
  const nestedBlocks = []
  let currentBlock = null
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i]
    if (block.type === 'heading') {
      if (currentBlock) {
        nestedBlocks.push(currentBlock)
      }
      currentBlock = block
    } else if (block.type === 'taskListItem' || block.type === 'checkListItem' || block.type === 'bulletListItem') {
      if (!currentBlock) {
        currentBlock = {
          id: 'top',
          type: 'heading',
          content: 'Top Items',
          children: [],
          props: {},
        }
      }
      currentBlock.children.push(block)
    }
  }
  if (currentBlock) {
    nestedBlocks.push(currentBlock)
  }
  return nestedBlocks
}

function isValidBlock(block: PartialBlock<DefaultBlockSchema>) {
  return ['taskListItem', 'checkListItem', 'bulletListItem', 'heading'].includes(block.type) && block.props['checked'] !== true
}

function recursiveBlockFilter(blocks: PartialBlock<DefaultBlockSchema>[]): PartialBlock<DefaultBlockSchema>[] {
  return blocks.filter(isValidBlock).map((block) => {
    if (block.children) {
      block.children = recursiveBlockFilter(block.children)
    }
    return block
  })
}

export function WeekNoteReference({
  isLoading,
  weekNote,
  selectedDate,
  jumpToWeek,
}: {
  isLoading: boolean
  weekNote: Note
  selectedDate: SelectedDate
  jumpToWeek: (_selectedDate: SelectedDate) => void
}) {
  const localStorageKey = `${LOCAL_STORAGE_PREFIX}-${weekNote?.filename ?? ''}`
  const [isOpen, setIsOpen] = useState<boolean>(JSON.parse(localStorage.getItem(localStorageKey) || 'false'))

  const [blocks, setBlocks] = useState<PartialBlock<DefaultBlockSchema>[]>([])
  const initialContent = BlockNoteEditor.notePlanToBlocks(weekNote?.content ?? '', '')
  const [isSaving, setIsSaving] = useState(false)
  const saveNote = useSaveNote((_note) => setIsSaving(false))

  const editor: BlockNoteEditor<DefaultBlockSchema> = useBlockNote(
    {
      initialContent: initialContent,
      onEditorReady: (editor: BlockNoteEditor<DefaultBlockSchema>) => {
        updateBlocks(editor.topLevelBlocks)
      },
      onEditorContentChange(editor: BlockNoteEditor<DefaultBlockSchema>) {
        const blocks = editor.topLevelBlocks
        updateBlocks(blocks)
        const content = BlockNoteEditor.blocksToNotePlan(blocks, weekNote.source === SourceDatabase.CLOUDKIT)
        setIsSaving(true)
        saveNote.mutate({
          content: content,
          attachments: findAttachmentURLs(editor?._tiptapEditor),
          filename: weekNote.filename,
          recordName: weekNote.recordName,
          parent: weekNote.parent,
          noteType: weekNote.noteType,
          modificationDate: new Date(),
        })
      },
    },
    [weekNote?.recordChangeTag, weekNote?.filename]
  )

  function updateBlocks(blocks: PartialBlock<DefaultBlockSchema>[]) {
    // it's important to make a copy of the blocks here, otherwise the editor will be mutated
    const blocksCopy = recursiveBlockFilter(structuredClone(blocks))
    // if there are headings, nest the blocks
    if (blocksCopy.some((block) => block.type === 'heading')) {
      setBlocks(nestBlocks(blocksCopy))
    } else {
      setBlocks(blocksCopy)
    }
  }

  function handleOnCheck(id: string) {
    // find the block in the editor and toggle the checked prop
    const block = editor.getBlock(id)
    if (block) {
      editor.updateBlock(id, { props: { checked: !block.props['checked'] } })
    }
  }

  const handleBlockDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      const target = event.currentTarget as HTMLElement
      if (target.dataset.id && target.dataset.id !== 'top') {
        const html = event.dataTransfer.getData('text/html')
        if (html) {
          const element = document.createElement('div')
          element.innerHTML = html
          const blocksToAppend: PartialBlock<DefaultBlockSchema>[] = []
          for (const node of element.childNodes) {
            const id = (node as HTMLElement).dataset.id
            if (id) {
              const block: PartialBlock<DefaultBlockSchema> | undefined = (window as WindowWithEditor).editor?.getBlock(id)
              if (block) {
                blocksToAppend.push(block)
              }
            }
          }
          // add the block to the new position
          editor.insertBlocks(blocksToAppend, target.dataset.id, 'after')
          // remove the block from the current position
          ;(window as WindowWithEditor).editor?.removeBlocks(blocksToAppend.map((block) => block.id))
        }
      }
    },
    // editor is needed here to have always the latest block ids
    [editor]
  )

  useEffect(
    () => {
      function handleWindowMessage(event: MessageEvent) {
        if (event.data.type === 'droppedWeekReferenceBlock') {
          editor.removeBlocks([event.data.id])
        }
      }

      window.addEventListener('message', handleWindowMessage)

      return () => {
        window.removeEventListener('message', handleWindowMessage)
      }
    }, // editor is needed here to have always the latest block ids
    [editor]
  )

  return (
    <>
      <Disclosure>
        <Disclosure.Button
          as="h1"
          onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
          className="mx-auto max-w-3xl w-full mt-3 cursor-default flex items-center justify-between opacity-60"
          style={{ paddingInline: '54px' }}
        >
          <div className="flex justify-between w-full">
            <div className="flex items-center">
              <ChevronRightIcon className={classNames({ 'rotate-90': isOpen, 'text-gray-500 dark:text-gray-200 h-4 w-4 shrink-0 mr-1': true })} aria-hidden="true" />
              <span className="text-xs font-bold uppercase opacity-60">Week {selectedDate.week}</span>
            </div>
            <i className="fas fa-arrow-right cursor-pointer text-xs" onClick={() => jumpToWeek(selectedDate)}></i>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel>
          <div className="mx-auto max-w-3xl w-full cursor-default" style={{ paddingInline: '54px' }}>
            <div className="my-3 bg-zinc-100/70 dark:bg-zinc-800 rounded-md p-2 pt-0 ">
              {isLoading ? (
                <SkeletonLoader />
              ) : (
                <div className={classNames(['relative', isSaving ? 'opacity-70' : 'opacity-100'])}>
                  {isSaving && <i className="fas fa-spinner-third fa-spin absolute top-0 right-0 mt-2 mr-2"></i>}
                  {blocks.map((block) => (
                    <ReferenceBlock key={block.id} block={block} onCheck={handleOnCheck} onBlockDrop={handleBlockDrop} isSaving={isSaving} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </>
  )
}
