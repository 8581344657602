import { useQueryClient } from '@tanstack/react-query'
import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { useUserState } from '../providers/UserProvider'
import { useSafeMutation } from './useSafeMutation'
import { teamKeys } from '../utils/queryKeyFactory'

// eslint-disable-next-line no-unused-vars
export default function useUpdateTeamspaceUserRole() {
  const sb = useSupabaseClient()
  const user = useUserState()

  const queryClient = useQueryClient()

  return useSafeMutation({
    mutationFn: ({ teamspaceID, email, role }: { teamspaceID: string; email: string; role: string }) => {
      if (!teamspaceID || !email || !role || !['admin', 'member'].includes(role)) {
        return Promise.resolve(undefined)
      }

      // eslint-disable-next-line no-console
      console.debug('[useSetTeamspaceUserRole] setting user role for teamspace', email, teamspaceID, role)

      if (user.supabaseUserId) {
        return sb.updateTeamspaceUserRole(teamspaceID, email, role)
      }

      throw new Error('Not signed in')
    },
    onSuccess: (data, { teamspaceID }) => {
      // eslint-disable-next-line no-console
      console.log('[useSetTeamspaceUserRole] onSuccess')

      // Invalidate the query so it reloads
      queryClient.invalidateQueries(teamKeys.members(teamspaceID))
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('[useSetTeamspaceUserRole] onError ' + error)
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}
