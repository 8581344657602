import { useSupabaseClient } from '../providers/SupabaseClientProvider'
import { useUserState } from '../providers/UserProvider'
import { useCachedNotesQueryClient } from '../providers/CachedNotesProvider'
import { Note } from '../utils/syncUtils'
import { cacheKeys, teamKeys } from '../utils/queryKeyFactory'
import { useSafeMutation } from './useSafeMutation'
import { useQueryClient } from '@tanstack/react-query'

// eslint-disable-next-line no-unused-vars
export default function useLeaveTeamspace() {
  const sb = useSupabaseClient()
  const user = useUserState()

  const queryClient = useQueryClient()
  const cachedNotesQueryClient = useCachedNotesQueryClient()

  return useSafeMutation({
    mutationFn: ({ teamspaceID }: { teamspaceID: string }) => {
      if (!teamspaceID) {
        return Promise.resolve(undefined)
      }

      // eslint-disable-next-line no-console
      console.debug('[useLeaveTeamspace] leaving from teamspace', teamspaceID)

      if (user.supabaseUserId) {
        return sb.leaveTeamspace(teamspaceID)
      }

      throw new Error('Not signed in')
    },
    onSuccess: (data, { teamspaceID }) => {
      // eslint-disable-next-line no-console
      console.log('[useLeaveTeamspace] onSuccess')

      // Remove the teamspace from the shared teamspace notes
      cachedNotesQueryClient.setQueryData<Map<string, Note>>(cacheKeys.team(user.supabaseUserId), (oldData: Map<string, Note>) => {
        oldData.delete(teamspaceID)
        return oldData
      })

      // Remove working copies of notes in the teamspace
      queryClient.removeQueries(teamKeys[10](teamspaceID))
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('[useLeaveTeamspace] onError ' + error)
    },
    retry: 3, // Retry a few times, in case we resolve the oplock error
  })
}
