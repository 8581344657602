// Define an interface for the component's props
interface ToggleProps {
  isEnabled: boolean
  onToggle: (_value: boolean) => void
  label: string
  description?: string
}

// Use the interface with your component
const Toggle = ({ isEnabled, onToggle, label, description }: ToggleProps) => {
  // Utility function for conditional class names
  //   function classNames(...classes: string[]) {
  //     return classes.filter(Boolean).join(' ')
  //   }

  //   return (
  //     <Switch.Group as="div" className="flex items-center justify-center">
  //       <Switch
  //         checked={isEnabled}
  //         onChange={onToggle}
  //         className={classNames(
  //           isEnabled ? 'bg-indigo-600' : 'bg-gray-200',
  //           'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
  //         )}
  //       >
  //         <span
  //           aria-hidden="true"
  //           className={classNames(
  //             isEnabled ? 'translate-x-5' : 'translate-x-0',
  //             'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
  //           )}
  //         />
  //       </Switch>
  //       <Switch.Label as="span" className="ml-3 text-sm">
  //         <span className="font-medium text-gray-900">{label}</span> {description && <span className="text-gray-500">({description})</span>}
  //       </Switch.Label>
  //     </Switch.Group>
  //   )
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" checked={isEnabled} onChange={(e) => onToggle(e.target.checked)} className="sr-only peer" />
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-amber-600"></div>
      <span className="ms-3 text-sm font-medium text-gray-900">{label}</span>
      <span className="ms-1 text-sm text-gray-600">{description && `(${description})`}</span>
    </label>
  )
}

export default Toggle
