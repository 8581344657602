import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useCachedNotesQueryClient, usePrivateNotes, useTeamNotes } from './CachedNotesProvider'
import { Note, NoteType, filenameToDate, isCalendarNote, isFolder, isTeamspaceNote } from '../utils/syncUtils'
import { useUserState } from './UserProvider'
import { useSelectedDate, useSelectedDateDispatch } from './SelectedDateProvider'
import useCreateNote, { CreateOptions } from '../hooks/useCreateNote'
import { SidebarEntry, createSidebarEntries, exportNoteChildren, findNoteKey } from '../modules/sidebar/SidebarBuilder'
import { useNoteConfig } from '../hooks/useNote'
import { useSupabaseClient } from './SupabaseClientProvider'
import { cacheKeys } from '../utils/queryKeyFactory'
import { UseMutateFunction } from '@tanstack/react-query'
import { useSelectedRecordName, useSelectedRecordNameDispatch } from './SelectedRecordNameProvider'
import useStarterNotes from '../hooks/useStarterNotes'
import dayjs from 'dayjs'
import useManageSubscription from '../hooks/useManageSubscription'

type NotificationType = {
  title: string
  subtitle: string
  visible: boolean
  icon: string
}

type SidebarProviderContextType = {
  noteKey: useNoteConfig | null
  notification: NotificationType
  setNotification: React.Dispatch<React.SetStateAction<NotificationType>>
  handleExportSupabaseTeamspace: (_recordName: string) => void
  handleSelectRecordName: (_recordName: string) => void
  handleRevealNote: (_recordName: string, _range?: Range) => void
  privateNotesMap: Map<string, Note>
  teamNotesMap: Map<string, Note>
  sidebarEntries: SidebarEntry[]
  createNote: UseMutateFunction<Note, Error, CreateOptions, Map<string, Note>>
  breadcrumb: string[]
  setBreadcrumb: React.Dispatch<React.SetStateAction<string[]>>
  shouldForceUpdateEditor: number
}

export const SidebarContext = createContext<SidebarProviderContextType | null>(null)

export function useSidebarProvider() {
  const context = useContext(SidebarContext)
  if (context === undefined) {
    throw new Error('useSidebarProvider must be used within a SidebarProvider')
  }
  return context
}

type Props = {
  onOpenCommandBar: (_visible: boolean, _search: string) => void
  children: ReactNode
}

export const SidebarProvider = ({ onOpenCommandBar, children }: Props): JSX.Element => {
  const selectedRecordName = useSelectedRecordName()
  const setSelectedRecordName = useSelectedRecordNameDispatch()
  const { createTeamspaceStarterNotes } = useStarterNotes()
  const user = useUserState()
  const { isLoading: isLoadingPrivate, data: privateNotes } = usePrivateNotes(user)
  const privateNotesMap = privateNotes as Map<string, Note>
  const { isLoading: isLoadingTeam, data: teamNotes } = useTeamNotes(user)
  const teamNotesMap = teamNotes as Map<string, Note>
  const [needsAttention, setNeedsAttention] = useState(false)
  const [shouldForceUpdateEditor, setShouldForceUpdateEditor] = useState(0)
  const selectedDate = useSelectedDate()
  const selectedDateDispatch = useSelectedDateDispatch()
  const [breadcrumb, setBreadcrumb] = useState<string[]>([])

  const [notification, setNotification] = useState({
    title: '',
    subtitle: '',
    visible: false,
    icon: 'fa-check-circle text-green-400',
  })

  const handleRevealNote = useCallback(
    (recordName: string, _range?: Range) => {
      const notes = new Map<string, Note>([...(privateNotesMap ?? new Map<string, Note>()), ...(teamNotesMap ?? new Map<string, Note>())])
      const note = notes.get(recordName)
      setNeedsAttention(true)
      if (note) {
        if (isCalendarNote(note.noteType)) {
          const { date, timeframe } = filenameToDate(note.filename)
          if (timeframe === 'day') {
            if (note.parent) {
              setSelectedRecordName('daily_' + note.parent)
            }
            selectedDateDispatch({ type: 'setDay', date: date })
          }
          if (timeframe === 'week') {
            if (note.parent) {
              setSelectedRecordName('weekly_' + note.parent)
            }
            selectedDateDispatch({ type: 'setWeek', week: date.week(), year: date.year() })
          }
        } else {
          setSelectedRecordName(recordName)
        }

        const buildBreadcrumb = (recordName: string): string[] => {
          const crumbs: string[] = []
          const note = notes.get(recordName)
          if (note) {
            const parent = notes.get(note.parent)
            if (parent) {
              crumbs.push(...buildBreadcrumb(parent.recordName))
              crumbs.push(parent.recordName)
            }
          }

          // Add the root as well ('teamspaces' or 'private notes')
          if (note && note.noteType) {
            crumbs.push(isTeamspaceNote(note.noteType) ? 'teamspaces' : 'notes')
          }

          return crumbs
        }

        setBreadcrumb([note.filename, ...buildBreadcrumb(selectedRecordName)])

        // EDIT: The selection is always off, comment it out for now, until we figured out a better way of highlighting the selected reference, maybe some attribute
        // setTextSelection({ from: range?.from ?? 0, to: range?.to ?? 0 })
      }
    },
    [privateNotesMap, teamNotesMap, selectedRecordName, selectedDateDispatch, setSelectedRecordName]
  )

  const { createPrivateStarterNotes: createStarterNotes } = useStarterNotes()
  const [isCreatingStarterNotes, setIsCreatingStarterNotes] = useState(false)

  useEffect(() => {
    if (!isCreatingStarterNotes && user) {
      setIsCreatingStarterNotes(true)

      createStarterNotes(
        () => {
          // Started
          setNotification({ title: 'Welcome to NotePlan', subtitle: 'Setting you up for a productive start...', visible: true, icon: 'fa-sun text-orange-400' })
        },
        () => {
          // Ready for open daily note
          setSelectedRecordName('daily')
          selectedDateDispatch({ type: 'setDay', date: dayjs() })

          // This is necessary so that we reload the cached notes inside the TipTap editor object, otherwise clicking on "Start Here" will create a new note instead of opening the existing
          setShouldForceUpdateEditor((prev) => prev + 1)
        }
      ).finally(() => {
        setIsCreatingStarterNotes(false)
        setTimeout(() => {
          setNotification({ title: '', subtitle: '', visible: false, icon: 'fa-screen-users text-green-400' })
        }, 2000)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const createNoteMutation = useCreateNote((note: Note) => {
    // Select the created note
    if (!isFolder(note)) {
      handleRevealNote(note.recordName)
    }

    // We just created a new teamspace, so add the starter notes
    if (note.noteType === NoteType.TEAM_SPACE) {
      setNotification({ title: 'Teamspace', subtitle: 'Setting up your teamspace...', visible: true, icon: 'fa-screen-users text-green-400' })
      createTeamspaceStarterNotes(note.recordName, () => {
        // Open today's note of the teamspace, now with the updated caches
        setNeedsAttention(true)
        setSelectedRecordName('daily_' + note.recordName)
        selectedDateDispatch({ type: 'setDay', date: dayjs() })
        setBreadcrumb([note.recordName])
        setTimeout(() => {
          setNotification({ title: '', subtitle: '', visible: false, icon: 'fa-screen-users text-green-400' })
        }, 3000)
      })
    }
  })

  const { openManageSubscription } = useManageSubscription()

  const sidebarEntries: SidebarEntry[] = useMemo(
    () => createSidebarEntries(user, isLoadingPrivate, privateNotesMap, isLoadingTeam, teamNotesMap, createNoteMutation.mutate, onOpenCommandBar, openManageSubscription),
    [user, isLoadingPrivate, privateNotesMap, isLoadingTeam, teamNotesMap, createNoteMutation.mutate, onOpenCommandBar, openManageSubscription]
  )
  const noteKey: useNoteConfig | null = useMemo(() => findNoteKey(sidebarEntries, selectedRecordName, selectedDate), [sidebarEntries, selectedRecordName, selectedDate])
  const supabase = useSupabaseClient()

  // function wrapped in useCallback to initiate the export of a note and it's children
  const handleExportSupabaseTeamspace = useCallback(
    (recordName: string) => {
      const notes = new Map([...privateNotesMap, ...teamNotesMap]) // Get all the notes at this point, the function will find the actual children
      const entry = notes.get(recordName) // Find the note object for the folder we want to export (i.e. teamspace)

      // TODO: If the entry is not found, export all private notes
      if (entry) {
        setNotification({ title: 'Exporting attachments...', subtitle: 'Downloading, this can take a while.', visible: true, icon: 'fa-download text-green-400' })
        exportNoteChildren(entry, notes, supabase).then(() => {
          setNotification({ title: '', subtitle: '', visible: false, icon: undefined })
        })
      }
    },
    [privateNotesMap, teamNotesMap, supabase]
  )

  const handleSelectRecordName = useCallback(
    (recordName: string) => {
      setSelectedRecordName(recordName)

      const teamspace = recordName.split('_').length > 0 ? recordName.split('_')[1] : undefined
      if (recordName.startsWith('weekly') && (selectedDate.active === 'day' || selectedDate.teamspace !== teamspace)) {
        selectedDateDispatch({ type: 'transform', to: 'week', teamspace: teamspace })
      }
      if (recordName.startsWith('daily') && (selectedDate.active === 'week' || selectedDate.teamspace !== teamspace)) {
        selectedDateDispatch({ type: 'transform', to: 'day', teamspace: teamspace })
      }
    },
    [selectedDate.active, selectedDate.teamspace, selectedDateDispatch, setSelectedRecordName]
  )

  const cachedNotesQueryClient = useCachedNotesQueryClient()
  useEffect(() => {
    // check to make sure we use the new map data structure
    if (privateNotes && Array.isArray(privateNotes)) {
      cachedNotesQueryClient.invalidateQueries({ queryKey: cacheKeys.private() })
    }
    if (teamNotes && Array.isArray(teamNotes)) {
      cachedNotesQueryClient.invalidateQueries({ queryKey: cacheKeys.team() })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // scroll to the selected record name
  useEffect(() => {
    if (needsAttention) {
      const element = document.getElementById(selectedRecordName)
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      setBreadcrumb([])
      setNeedsAttention(false)
    }
  }, [selectedRecordName, needsAttention])

  // performance optimization
  const context = useMemo(
    () => ({
      noteKey,
      notification,
      setNotification,
      handleExportSupabaseTeamspace,
      handleSelectRecordName,
      handleRevealNote,
      privateNotesMap,
      teamNotesMap,
      sidebarEntries,
      createNote: createNoteMutation.mutate,
      breadcrumb,
      setBreadcrumb,
      shouldForceUpdateEditor,
    }),
    [
      noteKey,
      notification,
      handleExportSupabaseTeamspace,
      handleSelectRecordName,
      handleRevealNote,
      privateNotesMap,
      teamNotesMap,
      sidebarEntries,
      createNoteMutation.mutate,
      breadcrumb,
      setBreadcrumb,
      shouldForceUpdateEditor,
    ]
  )
  return <SidebarContext.Provider value={context}>{children}</SidebarContext.Provider>
}
