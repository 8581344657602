import React from 'react'
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { PersistQueryClientProvider, persistQueryClientSave } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { mainId, useUserState } from './UserProvider'
import axios from 'axios'
import { googleLogout } from '@react-oauth/google'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export const accessTokenContext = React.createContext<QueryClient | undefined>(undefined)
const accessTokenMaxAge = 1000 * 60 * 59 // 59 minutes
const accessTokenQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: accessTokenMaxAge,
      staleTime: accessTokenMaxAge,
      // disable too much refetching
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 3,
    },
  },
})

// set the base URL depending on the hostname
// const hostname = window.location.hostname;
const baseURL = 'https://app.noteplan.co/backend/google' // TODO set correct backend URL here
// if (hostname === 'localhost') {
//   baseURL = 'http://localhost:8000/';
// }
const backendClient = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded', // important for php
  },
})

// https://tanstack.com/query/v4/docs/react/plugins/createSyncStoragePersister
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  key: 'accessToken',
})

export type AccessToken = {
  token: string
  expires: number
}
type TokenError = {
  error: string
}

// query for using the access token, will be refetched automatically if it expires
export function useAccessToken() {
  const user = useUserState()

  const queryResult = useQuery<AccessToken | null, Error>({
    context: accessTokenContext,
    refetchInterval: accessTokenMaxAge,
    refetchIntervalInBackground: true,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['accessToken'],
    queryFn: async () => {
      const response = await backendClient.post('auth.php', { user: mainId(user) })
      if (response.data.error) {
        // unsuccessful so we reset the token and logout
        googleLogout()
        return null
      } else {
        return response.data
      }
    },
  })

  return queryResult
}

// helper to get the required contexes to set and persist the token
export function usePersistToken(): (_token: AccessToken | null) => void {
  const persistToken = (token: AccessToken | null) => {
    accessTokenQueryClient.setQueryData(['accessToken'], token)
    persistQueryClientSave({ queryClient: accessTokenQueryClient, persister: localStoragePersister })
  }
  return persistToken
}

// mutation to forward the auth code to the backend and get the access token in return
export function useSignin() {
  const user = useUserState()

  return useMutation({
    context: accessTokenContext,
    retry: 3, // retry in case our backend is not reachable
    mutationFn: (code: string) => {
      return backendClient.post('signin.php', {
        code,
        user: mainId(user),
      })
    },
    onMutate: async () => {
      // Cancel any outgoing refetches
      await accessTokenQueryClient.cancelQueries(['accessToken'])
    },
    onError: () => {
      accessTokenQueryClient.setQueryData(['accessToken'], null)
    },
    onSuccess: ({ data }: { data: AccessToken | TokenError }) => {
      if ('error' in data) {
        throw new Error(data.error)
      }
      accessTokenQueryClient.setQueryData(['accessToken'], data)
    },
  })
}

export default function AccessTokenProvider({ children }: { children: React.ReactNode }) {
  function validateToken() {
    const accessToken: AccessToken = accessTokenQueryClient.getQueryData(['accessToken'])
    // in case we got an old token, initiate a refetch
    if (accessToken?.expires < Date.now()) {
      accessTokenQueryClient.invalidateQueries(['accessToken'])
    }
  }

  return (
    <PersistQueryClientProvider
      context={accessTokenContext}
      client={accessTokenQueryClient}
      persistOptions={{ persister: localStoragePersister, maxAge: accessTokenMaxAge }}
      onSuccess={validateToken}
    >
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} context={accessTokenContext} position="bottom-right" /> */}
    </PersistQueryClientProvider>
  )
}
